<template>
  <div class="list-view bg-primary-bg text-text-primary">
    <div class="mb-6 flex flex-wrap justify-between items-center gap-4">
      <div class="flex space-x-2">
        <button
          @click="showColumnCustomization = !showColumnCustomization"
          class="btn-primary"
        >
          <i class="fas fa-columns mr-2"></i>Columns
        </button>
        <button @click="showFilters = !showFilters" class="btn-primary">
          <i class="fas fa-filter mr-2"></i>Filters
        </button>
      </div>
      <div class="flex-1 min-w-[200px] max-w-xl">
        <input
          v-model="search"
          placeholder="Search..."
          class="input w-full bg-primary-card text-text-primary placeholder-text-primary"
        />
      </div>
      <button @click="$emit('add')" class="btn btn-primary">
        <i class="fas fa-plus mr-2"></i>Add {{ entityName }}
      </button>
    </div>

    <div
      v-if="showColumnCustomization"
      class="mb-6 p-4 bg-primary-card rounded-lg shadow-md"
    >
      <h3 class="font-semibold mb-2 text-text-primary text-gradient">
        Customize Columns
      </h3>
      <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        <div
          v-for="column in allColumns"
          :key="column.key"
          class="flex items-center"
        >
          <input
            type="checkbox"
            :id="column.key"
            v-model="column.visible"
            class="mr-2 form-checkbox text-accent-primary focus:ring-accent-primary focus:ring-offset-primary-bg"
          />
          <label :for="column.key" class="text-sm text-text-secondary">{{
            column.label
          }}</label>
        </div>
      </div>
    </div>

    <div
      v-if="showFilters"
      class="mb-6 p-4 bg-primary-card rounded-lg shadow-md"
    >
      <h3 class="font-semibold mb-2 text-text-primary text-gradient">
        Filters
      </h3>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div v-for="column in filterableColumns" :key="column.key" class="mb-2">
          <label
            :for="column.key"
            class="block text-sm font-medium mb-1 text-text-secondary"
            >{{ column.label }}</label
          >
          <input
            :id="column.key"
            v-model="filters[column.key]"
            class="input w-full bg-primary-card text-text-primary placeholder-text-secondary"
            :placeholder="`Filter ${column.label.toLowerCase()}`"
          />
        </div>
      </div>
    </div>

    <div class="space-y-4">
      <div
        v-for="item in sortedAndFilteredItems"
        :key="item.id"
        @click="showDetails(item)"
        class="bg-primary-card rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer group transform hover:scale-105"
      >
        <div class="p-4">
          <div class="grid-layout">
            <div
              v-for="column in visibleColumns"
              :key="column.key"
              class="overflow-hidden"
            >
              <h3 class="text-sm font-medium text-text-secondary mb-1">
                {{ column.label }}
              </h3>
              <div class="text-sm text-text-primary truncate">
                <slot :name="column.key" :item="item" :column="column">
                  {{ getNestedValue(item, column.key) }}
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ref="infiniteScrollTrigger" class="h-10 mt-4">
      <div v-if="hasMore" class="text-center text-text-secondary text-sm">
        <i class="fas fa-spinner fa-spin mr-2"></i>Loading more...
      </div>
    </div>
  </div>
</template>

<style scoped>
.list-view {
  @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
}

.grid-layout {
  @apply grid gap-4;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.btn {
  @apply px-4 py-2 rounded-md font-medium transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-bg;
}

.btn-primary {
  @apply bg-accent-primary text-white hover:bg-opacity-90 focus:ring-accent-primary;
}

.btn-secondary {
  @apply bg-primary-card text-text-primary hover:bg-primary-lighter focus:ring-accent-secondary;
}

.input {
  @apply px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-accent-primary transition-colors duration-200 ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.group:hover .hidden {
  animation: fadeIn 0.3s ease-out forwards;
}

.text-gradient {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-accent-primary to-accent-secondary;
}
</style>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";

export default {
  name: "ListView",
  props: {
    items: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["add", "edit", "delete", "show-details", "load-more"],
  setup(props, { emit }) {
    const search = ref("");
    const showColumnCustomization = ref(false);
    const showFilters = ref(false);
    const filters = ref({});
    const sortColumn = ref("");
    const sortDirection = ref("asc");
    const observer = ref(null);
    const allColumns = ref(
      props.columns.map((column) => ({ ...column, visible: true }))
    );
    const infiniteScrollTrigger = ref(null);

    const visibleColumns = computed(() =>
      allColumns.value.filter((column) => column.visible)
    );
    const filterableColumns = computed(() =>
      allColumns.value.filter((column) => column.filterable)
    );

    const filteredItems = computed(() => {
      let result = props.items;

      if (search.value) {
        const searchLower = search.value.toLowerCase();
        result = result.filter((item) =>
          Object.values(item).some((val) =>
            String(val).toLowerCase().includes(searchLower)
          )
        );
      }

      Object.entries(filters.value).forEach(([key, value]) => {
        if (value) {
          result = result.filter((item) =>
            String(item[key]).toLowerCase().includes(value.toLowerCase())
          );
        }
      });

      return result;
    });

    const sortedAndFilteredItems = computed(() => {
      let result = filteredItems.value;

      if (sortColumn.value) {
        result = result.sort((a, b) => {
          if (a[sortColumn.value] < b[sortColumn.value])
            return sortDirection.value === "asc" ? -1 : 1;
          if (a[sortColumn.value] > b[sortColumn.value])
            return sortDirection.value === "asc" ? 1 : -1;
          return 0;
        });
      }

      return result;
    });

    const sortBy = (column) => {
      if (sortColumn.value === column) {
        sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
      } else {
        sortColumn.value = column;
        sortDirection.value = "asc";
      }
    };

    const showDetails = (item) => {
      emit("show-details", item);
    };

    const getNestedValue = (obj, path) => {
      return path.split(".").reduce((prev, curr) => {
        return prev ? prev[curr] : null;
      }, obj);
    };

    const loadMoreItems = () => {
      if (props.hasMore) {
        emit("load-more");
      }
    };

    const handleIntersect = (entries) => {
      if (entries[0].isIntersecting) {
        loadMoreItems();
      }
    };

    onMounted(() => {
      observer.value = new IntersectionObserver(handleIntersect, {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      });

      if (infiniteScrollTrigger.value) {
        observer.value.observe(infiniteScrollTrigger.value);
      }
    });

    onUnmounted(() => {
      if (observer.value) {
        observer.value.disconnect();
      }
    });

    return {
      search,
      showColumnCustomization,
      showFilters,
      filters,
      sortColumn,
      sortDirection,
      allColumns,
      visibleColumns,
      filterableColumns,
      sortedAndFilteredItems,
      infiniteScrollTrigger,
      sortBy,
      showDetails,
      getNestedValue,
    };
  },
};
</script>
