<template>
  <div class="p-6 bg-primary-bg min-h-screen">
    <h1
      class="text-3xl font-semibold text-text-primary mb-6 text-gradient bg-gradient-to-r from-accent-tertiary to-accent-quaternary"
    >
      Releases
    </h1>
    <ListView
      :key="releases.length"
      :items="releases"
      :columns="columns"
      :form-fields="formFields"
      :has-more="hasMore"
      entity-name="Release"
      @add="showAddReleaseModal = true"
      @update="openEditModal"
      @delete="confirmDelete"
      @show-details="showReleaseDetails"
      @load-more="loadMoreReleases"
    >
      <template #custom-item="slotProps">
        <div class="flex items-center space-x-4">
          <img
            :src="getArtistImageUrl(slotProps.item.artist)"
            :alt="slotProps.item.artist.name"
            class="w-12 h-12 rounded-full mr-3 object-cover border-2 border-accent-secondary transition-transform duration-300 hover:scale-110"
          />
          <div>
            <div class="font-medium text-text-primary">
              {{ slotProps.item.name }}
            </div>
            <div class="text-sm text-text-secondary">
              {{ slotProps.item.artist.name }}
            </div>
          </div>
        </div>
      </template>
      <template #release_date="slotProps">
        <span class="text-text-primary">
          {{ new Date(slotProps.item.release_date).toLocaleDateString() }}
        </span>
      </template>
      <template #contract="slotProps">
        <span
          :class="[
            'px-2 py-1 text-xs font-medium rounded-full transition-colors duration-300',
            slotProps.item.contract
              ? 'bg-success bg-opacity-20 text-text-primary hover:bg-opacity-30'
              : 'bg-error bg-opacity-20 text-text-primary hover:bg-opacity-30',
          ]"
        >
          {{ slotProps.item.contract ? "Yes" : "No" }}
        </span>
      </template>
      <template #organization="slotProps">
        <span class="text-text-primary">
          {{ slotProps.item.organization.name }}
        </span>
      </template>
    </ListView>

    <!-- Add Release Modal -->
    <BaseModal
      :is-open="showAddReleaseModal"
      @close="showAddReleaseModal = false"
      size="lg"
    >
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-tertiary to-accent-quaternary"
        >
          Add Release
        </h2>
      </template>
      <template #body>
        <DynamicForm
          :fields="formFields"
          @submit="addRelease"
          @cancel="showAddReleaseModal = false"
        />
      </template>
    </BaseModal>

    <!-- Release Details Modal -->
    <BaseModal
      :is-open="!!selectedRelease"
      @close="selectedRelease = null"
      size="lg"
    >
      <template #header>
        <h3
          class="text-2xl font-semibold text-center text-text-primary text-gradient bg-gradient-to-r from-accent-tertiary to-accent-quaternary"
        >
          Release Details
        </h3>
      </template>
      <template #body>
        <div v-if="selectedRelease" class="space-y-8">
          <!-- Center-aligned release name and artist name -->
          <div class="text-center">
            <h4 class="text-2xl font-bold text-text-primary mb-2">
              {{ selectedRelease.name }}
            </h4>
            <p class="text-xl text-accent-tertiary">
              {{ selectedRelease.artist.name }}
            </p>
          </div>

          <!-- Two-column grid for details -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-3xl mx-auto">
            <!-- Left column -->
            <div class="space-y-6">
              <div
                v-for="(value, key) in leftColumnDetails"
                :key="key"
                class="text-center"
              >
                <h5
                  class="text-sm font-medium text-text-secondary uppercase tracking-wide mb-1"
                >
                  {{ formatLabel(key) }}
                </h5>
                <div class="text-lg font-semibold text-text-primary">
                  {{ formatValue(key, value) }}
                </div>
              </div>
            </div>

            <!-- Right column -->
            <div class="space-y-6">
              <div
                v-for="(value, key) in rightColumnDetails"
                :key="key"
                class="text-center"
              >
                <h5
                  class="text-sm font-medium text-text-secondary uppercase tracking-wide mb-1"
                >
                  {{ formatLabel(key) }}
                </h5>
                <div class="text-lg font-semibold text-text-primary">
                  {{ formatValue(key, value) }}
                </div>
              </div>
            </div>
          </div>

          <div class="mt-8 flex justify-center space-x-4">
            <button
              @click="openEditModal(selectedRelease)"
              class="btn btn-primary"
            >
              <i class="fas fa-edit mr-2"></i>Edit
            </button>
            <button @click="confirmDelete" class="btn btn-danger">
              <i class="fas fa-trash-alt mr-2"></i>Delete
            </button>
          </div>
        </div>
      </template>
    </BaseModal>

    <!-- Edit Release Modal -->
    <BaseModal :is-open="showEditModal" @close="closeEditModal" size="lg">
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-tertiary to-accent-quaternary"
        >
          Edit Release
        </h2>
      </template>
      <template #body>
        <DynamicForm
          v-if="editingRelease"
          :fields="formFields"
          :initial-data="editingRelease"
          @submit="updateRelease"
          @cancel="closeEditModal"
        />
      </template>
    </BaseModal>

    <!-- Delete Confirmation Modal -->
    <BaseModal
      :is-open="showDeleteConfirmModal"
      @close="showDeleteConfirmModal = false"
      size="sm"
    >
      <template #header>
        <h3
          class="text-xl font-semibold text-text-primary text-gradient bg-gradient-to-r from-error to-error-light"
        >
          Confirm Delete
        </h3>
      </template>
      <template #body>
        <p class="text-text-secondary mb-4">
          Are you sure you want to delete this release?
        </p>
        <div class="mt-4 flex justify-end space-x-2">
          <button
            @click="showDeleteConfirmModal = false"
            class="btn btn-secondary"
          >
            Cancel
          </button>
          <button @click="deleteRelease" class="btn btn-danger">Delete</button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.btn {
  @apply px-4 py-2 rounded-md font-medium transition-colors duration-normal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-bg;
}
.btn-primary {
  @apply bg-accent-tertiary text-white hover:bg-accent-tertiary-dark focus:ring-accent-tertiary;
}

.btn-secondary {
  @apply bg-primary-lighter text-text-primary hover:bg-primary-card focus:ring-accent-quaternary;
}

.btn-danger {
  @apply bg-error text-white hover:bg-error-light focus:ring-error;
}
</style>

<script>
import { ref, computed, onMounted } from "vue";
import ListView from "@/components/ListView.vue";
import BaseModal from "@/components/BaseModal.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import api from "@/services/api";
import { useStore } from "vuex";

export default {
  name: "ReleasesView",
  components: {
    ListView,
    BaseModal,
    DynamicForm,
  },
  setup() {
    const store = useStore();
    const releases = ref([]);
    const selectedRelease = ref(null);
    const editingRelease = ref(null);
    const showAddReleaseModal = ref(false);
    const showEditModal = ref(false);
    const showDeleteConfirmModal = ref(false);
    const page = ref(1);
    const hasMore = ref(true);
    const isLoading = ref(false);
    const columns = ref([
      {
        key: "custom-item",
        label: "Release",
        visible: true,
        filterable: false,
      },
      {
        key: "release_date",
        label: "Release Date",
        visible: true,
        filterable: true,
      },
      {
        key: "catalog_number",
        label: "Catalog Number",
        visible: true,
        filterable: true,
      },
      {
        key: "upc_number",
        label: "UPC Number",
        visible: true,
        filterable: true,
      },
      {
        key: "contract",
        label: "Contract Status",
        visible: true,
        filterable: true,
      },
      {
        key: "organization.name",
        label: "Organization",
        visible: true,
        filterable: true,
      },
    ]);

    const formFields = ref([
      { key: "name", label: "Release Name", type: "text", required: true },
      {
        key: "artist_id",
        label: "Artist",
        type: "select",
        options: computed(() =>
          store.getters["artists/getArtistsList"].map((a) => ({
            value: a.id,
            label: a.name,
          }))
        ),
        required: true,
      },
      {
        key: "organization_id",
        label: "Organization",
        type: "select",
        options: computed(() =>
          store.getters["organizations/getOrganizationsList"].map((o) => ({
            value: o.id,
            label: o.name,
          }))
        ),
        required: true,
      },
      {
        key: "release_date",
        label: "Release Date",
        type: "date",
        required: true,
      },
      {
        key: "catalog_number",
        label: "Catalog Number",
        type: "text",
        required: true,
      },
      {
        key: "upc_number",
        label: "UPC Number",
        type: "text",
        required: true,
        maxlength: 12,
      },
      {
        key: "release_type",
        label: "Release Type",
        type: "select",
        options: [
          { value: "Single", label: "Single" },
          { value: "EP", label: "EP" },
          { value: "Album", label: "Album" },
        ],
        required: true,
      },
      {
        key: "status",
        label: "Status",
        type: "select",
        options: [
          { value: "Planned", label: "Planned" },
          { value: "In Production", label: "In Production" },
          { value: "Released", label: "Released" },
        ],
        required: true,
      },
      { key: "contract", label: "Contract", type: "checkbox" },
      { key: "artwork", label: "Artwork", type: "checkbox" },
      { key: "master", label: "Master", type: "checkbox" },
      // { key: "labelworx", label: "Labelworx Date", type: "date" },
      // { key: "labelworx_check", label: "Labelworx Check", type: "checkbox" },
      // {
      //   key: "fatdrop_print_media",
      //   label: "Fatdrop Print Media Date",
      //   type: "date",
      // },
      // { key: "fatdrop_dj", label: "Fatdrop DJ Date", type: "date" },
      // { key: "fatdrop_media", label: "Fatdrop Media Date", type: "date" },
      // { key: "spotify_pitch", label: "Spotify Pitch Date", type: "date" },
      // { key: "pitch_labelworx", label: "Pitch Labelworx Date", type: "date" },
      // { key: "pfl_check", label: "PFL Check", type: "checkbox" },
      // { key: "url", label: "URL", type: "url" },
      // { key: "promo", label: "Promo", type: "text" },
      // { key: "notes", label: "Notes", type: "textarea" },
    ]);

    const releaseDetails = computed(() => {
      if (!selectedRelease.value) return {};
      const {
        name,
        artist,
        release_date,
        catalog_number,
        upc_number,
        contract,
        organization,
      } = selectedRelease.value;
      return {
        name,
        artist: artist.name,
        release_date,
        catalog_number,
        upc_number,
        contract: contract ? "Yes" : "No",
        organization: organization.name,
      };
    });

    const fetchReleases = async () => {
      if (isLoading.value || !hasMore.value) return;

      isLoading.value = true;
      try {
        const response = await api.getReleases({
          page: page.value,
          per_page: 20,
        });
        releases.value = [...releases.value, ...response.data.releases];
        hasMore.value = response.data.has_more;
        page.value++;
      } catch (error) {
        console.error("Error fetching releases:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const loadMoreReleases = () => {
      if (hasMore.value && !isLoading.value) {
        fetchReleases();
      }
    };

    const fetchArtistsAndOrganizations = async () => {
      await Promise.all([
        store.dispatch("artists/fetchArtistsList"),
        store.dispatch("organizations/fetchOrganizationsList"),
      ]);
    };

    const addRelease = async (formData) => {
      try {
        const response = await api.createRelease(formData);
        releases.value.push(response.data);
        showAddReleaseModal.value = false;
      } catch (error) {
        console.error("Error creating release:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          console.log(error.response.data.error);
        } else {
          console.log(
            "An error occurred while creating the release. Please try again."
          );
        }
      }
    };

    const updateRelease = async (formData) => {
      try {
        const plainData = { ...formData };

        // Ensure artist_id and organization_id are numbers
        if (plainData.artist_id) {
          plainData.artist_id = Number(plainData.artist_id);
        }
        if (plainData.organization_id) {
          plainData.organization_id = Number(plainData.organization_id);
        }

        const response = await api.updateRelease(
          editingRelease.value.id,
          plainData
        );
        const index = releases.value.findIndex(
          (r) => r.id === editingRelease.value.id
        );
        if (index !== -1) {
          releases.value[index] = response.data;
        }
        selectedRelease.value = response.data;
        closeEditModal();
      } catch (error) {
        console.error("Error updating release:", error);
      }
    };

    const deleteRelease = async () => {
      try {
        await api.deleteRelease(selectedRelease.value.id);
        releases.value = releases.value.filter(
          (r) => r.id !== selectedRelease.value.id
        );
        selectedRelease.value = null;
        showDeleteConfirmModal.value = false;
      } catch (error) {
        console.error("Error deleting release:", error);
      }
    };

    const showReleaseDetails = (release) => {
      selectedRelease.value = release;
    };

    const openEditModal = (release) => {
      editingRelease.value = {
        ...release,
        artist_id: release.artist.id,
        organization_id: release.organization.id,
        // Ensure boolean fields are correctly set
        contract: Boolean(release.contract),
        artwork: Boolean(release.artwork),
        master: Boolean(release.master),
        // Format date fields
        release_date: release.release_date
          ? new Date(release.release_date).toISOString().split("T")[0]
          : null,
      };
      showEditModal.value = true;
    };

    const closeEditModal = () => {
      showEditModal.value = false;
      editingRelease.value = null;
    };

    const confirmDelete = () => {
      showDeleteConfirmModal.value = true;
    };

    const getArtistImageUrl = (artist) => {
      if (!artist || !artist.image_url)
        return "https://via.placeholder.com/150";
      if (artist.image_url.startsWith("http")) return artist.image_url;
      return `${process.env.VUE_APP_API_URL}${artist.image_url}`;
    };

    const leftColumnDetails = computed(() => {
      if (!selectedRelease.value) return {};
      const { release_date, catalog_number, upc_number, status } =
        selectedRelease.value;
      return { release_date, catalog_number, upc_number, status };
    });

    const rightColumnDetails = computed(() => {
      if (!selectedRelease.value) return {};
      const { contract, organization, release_type } = selectedRelease.value;
      return { contract, organization: organization.name, release_type };
    });

    const formatLabel = (key) => {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const formatValue = (key, value) => {
      if (key === "release_date") {
        return new Date(value).toLocaleDateString();
      }
      if (key === "contract") {
        return value ? "Yes" : "No";
      }
      return value;
    };

    onMounted(() => {
      fetchReleases();
      fetchArtistsAndOrganizations();
    });

    return {
      releases,
      columns,
      formFields,
      selectedRelease,
      editingRelease,
      showAddReleaseModal,
      showEditModal,
      showDeleteConfirmModal,
      releaseDetails,
      addRelease,
      updateRelease,
      deleteRelease,
      showReleaseDetails,
      confirmDelete,
      closeEditModal,
      getArtistImageUrl,
      formatLabel,
      openEditModal,
      leftColumnDetails,
      rightColumnDetails,
      formatValue,
      hasMore,
      loadMoreReleases,
    };
  },
};
</script>
