<template>
  <div
    class="min-h-screen flex items-center justify-center bg-primary-bg py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8">
      <div
        class="bg-primary-card p-8 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl"
      >
        <div class="text-center">
          <h2
            class="mt-6 text-3xl font-extrabold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
          >
            Welcome Back
          </h2>
          <p class="mt-2 text-sm text-text-secondary">
            Sign in to your account
          </p>
        </div>
        <form class="mt-8 space-y-6" @submit.prevent="handleSubmit">
          <div class="space-y-4">
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-text-secondary"
                >Email address</label
              >
              <input
                id="email"
                name="email"
                type="email"
                v-model="email"
                :class="{ 'border-error': v$.email.$error }"
                class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary focus:z-10 sm:text-sm transition-all duration-300"
                placeholder="Email address"
                @blur="v$.email.$touch()"
                @keydown.enter.prevent="focusNextInput('password')"
                ref="emailInput"
              />
              <div v-if="v$.email.$error" class="text-error text-xs mt-1">
                {{ v$.email.$errors[0].$message }}
              </div>
            </div>
            <div class="relative">
              <label
                for="password"
                class="block text-sm font-medium text-text-secondary"
                >Password</label
              >
              <div class="relative">
                <input
                  id="password"
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  :class="{ 'border-error': v$.password.$error }"
                  class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary sm:text-sm transition-all duration-300"
                  placeholder="Password"
                  @blur="v$.password.$touch()"
                  @keydown.enter.prevent="handleSubmit"
                  ref="passwordInput"
                />
                <button
                  type="button"
                  @click="togglePassword"
                  class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  tabindex="-1"
                >
                  <i
                    :class="[
                      'fas',
                      showPassword ? 'fa-eye-slash' : 'fa-eye',
                      'text-text-secondary hover:text-text-primary transition-colors duration-200',
                    ]"
                  ></i>
                </button>
              </div>
              <div v-if="v$.password.$error" class="text-error text-xs mt-1">
                {{ v$.password.$errors[0].$message }}
              </div>
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                v-model="rememberMe"
                class="h-4 w-4 text-accent-primary focus:ring-accent-primary border-gray-700 rounded bg-primary-lighter"
              />
              <label
                for="remember-me"
                class="ml-2 block text-sm text-text-secondary"
                >Remember me</label
              >
            </div>

            <div class="text-sm">
              <a
                href="#"
                class="font-medium text-accent-primary hover:text-accent-secondary transition-colors duration-200"
                >Forgot your password?</a
              >
            </div>
          </div>
          <div v-if="errorMessage" class="text-error text-sm mt-2">
            {{ errorMessage }}
          </div>
          <div>
            <button
              type="submit"
              :disabled="isLoading || v$.$invalid"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-accent-primary hover:bg-accent-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-primary transition-all duration-300"
              :class="{
                'opacity-50 cursor-not-allowed': isLoading || v$.$invalid,
              }"
            >
              {{ isLoading ? "Signing in..." : "Sign in" }}
            </button>
          </div>
        </form>
      </div>

      <div class="text-center mt-4">
        <p class="text-sm text-text-secondary">
          Register a new company?
          <router-link
            to="/company-signup"
            class="font-medium text-accent-primary hover:text-accent-secondary transition-colors duration-200"
            >Company Signup</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "LoginView",
  setup() {
    const router = useRouter();
    const store = useStore();

    const state = reactive({
      email: "",
      password: "",
      rememberMe: false,
      showPassword: false,
      isLoading: false,
      errorMessage: "",
    });

    const emailInput = ref(null);
    const passwordInput = ref(null);

    const rules = {
      email: { required, email },
      password: { required, minLength: minLength(8) },
    };

    const v$ = useVuelidate(rules, state);

    const handleSubmit = async () => {
      const result = await v$.value.$validate();
      if (result) {
        state.isLoading = true;
        state.errorMessage = "";
        try {
          await store.dispatch("auth/login", {
            email: state.email,
            password: state.password,
            rememberMe: state.rememberMe,
          });
          router.push("/dashboard");
        } catch (error) {
          console.error("Login failed:", error);
          state.errorMessage =
            error.response?.data?.message ||
            "Login failed. Please check your credentials and try again.";
        } finally {
          state.isLoading = false;
        }
      }
    };

    const togglePassword = () => {
      state.showPassword = !state.showPassword;
    };

    const focusNextInput = (nextInput) => {
      if (nextInput === "password") {
        passwordInput.value.focus();
      }
    };

    return {
      ...toRefs(state),
      v$,
      handleSubmit,
      togglePassword,
      focusNextInput,
      emailInput,
      passwordInput,
    };
  },
};
</script>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.bg-primary-bg {
  background-color: var(--color-primary-bg);
}

.bg-primary-card {
  background-color: var(--color-primary-card);
}

.bg-primary-lighter {
  background-color: var(--color-primary-lighter);
}

.text-text-primary {
  color: var(--color-text-primary);
}

.text-text-secondary {
  color: var(--color-text-secondary);
}

.text-accent-primary {
  color: var(--color-accent-primary);
}

.text-accent-secondary {
  color: var(--color-accent-secondary);
}

.bg-accent-primary {
  background-color: var(--color-accent-primary);
}

.hover\:bg-accent-secondary:hover {
  background-color: var(--color-accent-secondary);
}

.focus\:ring-accent-primary:focus {
  --tw-ring-color: var(--color-accent-primary);
}

.text-error {
  color: var(--color-error);
}

.border-error {
  border-color: var(--color-error);
}

/* Dark mode styles */
.dark .bg-primary-bg {
  background-color: var(--color-primary-darkest);
}

.dark .bg-primary-card {
  background-color: var(--color-primary-darker);
}

.dark .bg-primary-lighter {
  background-color: var(--color-primary-card);
}
</style>
