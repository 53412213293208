import axios from "axios";

const API_URL = `${process.env.VUE_APP_API_URL}/api`;

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false, // This is important for sending cookies if you're using session-based auth
});

// Add a request interceptor to log requests
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to log responses
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Clear local storage and redirect to login page
      localStorage.removeItem("token");
    }
    console.error(
      "Response Error:",
      error.response ? error.response.data : error.message
    );
    return Promise.reject(error);
  }
);

export default {
  // User authentication
  login(credentials) {
    return apiClient.post("/auth/login", credentials);
  },

  signup(userData) {
    return apiClient.post("/auth/signup", userData);
  },
  checkEmailAvailability(email) {
    return apiClient.post("/auth/check-email", { email });
  },
  async getCurrentUser() {
    return apiClient.get("/auth/user").catch((error) => {
      if (error.response && error.response.status === 401) {
        return null; // Return null for unauthenticated users
      }
      throw error;
    });
  },
  // Artists
  getArtists(params = {}) {
    return apiClient.get("/artists", { params });
  },
  getArtist(id) {
    return apiClient.get(`/artists/${id}`);
  },
  createArtist(formData) {
    return apiClient.post("/artists", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getArtistsList() {
    return apiClient.get("/artists/list");
  },
  updateArtist(id, formData) {
    return apiClient.put(`/artists/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteArtist(id) {
    return apiClient.delete(`/artists/${id}`);
  },

  // Releases
  getReleases(params = {}) {
    return apiClient.get("/releases", { params });
  },
  getReleaseFields() {
    return apiClient.get("/releases/fields");
  },

  getRelease(id) {
    return apiClient.get(`/releases/${id}`);
  },
  createRelease(releaseData) {
    return apiClient.post("/releases", releaseData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateRelease(id, releaseData) {
    return apiClient.put(`/releases/${id}`, releaseData);
  },
  deleteRelease(id) {
    return apiClient.delete(`/releases/${id}`);
  },
  getAllReleases() {
    return this.getReleases();
  },
  uploadReleaseImage(id, imageData) {
    return apiClient.post(`/releases/${id}/upload-image`, imageData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  // Contacts
  getContacts(params = {}) {
    return apiClient.get("/contacts", { params });
  },
  getContact(id) {
    return apiClient.get(`/contacts/${id}`);
  },
  createContact(contact) {
    return apiClient.post("/contacts", contact);
  },
  updateContact(id, contact) {
    return apiClient.put(`/contacts/${id}`, contact);
  },
  deleteContact(id) {
    return apiClient.delete(`/contacts/${id}`);
  },

  // Organizations
  getOrganizations(params = {}) {
    return apiClient.get("/organizations", { params });
  },
  getOrganization(id) {
    return apiClient.get(`/organizations/${id}`);
  },
  createOrganization(formData) {
    return apiClient.post("/organizations", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateOrganization(id, formData) {
    return apiClient.put(`/organizations/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteOrganization(id) {
    return apiClient.delete(`/organizations/${id}`);
  },
  searchOrganizations(query) {
    return apiClient.get(`/organizations/search?q=${query}`);
  },
  getOrganizationsByType(type) {
    return apiClient.get(`/organizations/by-type/${type}`);
  },
  getOrganizationsList() {
    return apiClient.get("/organizations/list");
  },

  // Nodes
  getNodes() {
    return apiClient.get("/nodes");
  },
  getNode(id) {
    return apiClient.get(`/nodes/${id}`);
  },
  createNode(node) {
    return apiClient.post("/nodes", node);
  },
  updateNode(id, node) {
    return apiClient.put(`/nodes/${id}`, node);
  },
  deleteNode(id) {
    return apiClient.delete(`/nodes/${id}`);
  },

  // Contracts
  getContracts() {
    return apiClient.get("/contracts");
  },
  getContract(id) {
    return apiClient.get(`/contracts/${id}`);
  },
  createContract(contractData) {
    return apiClient.post("/contracts", contractData);
  },
  updateContract(id, contractData) {
    return apiClient.put(`/contracts/${id}`, contractData);
  },
  deleteContract(id) {
    return apiClient.delete(`/contracts/${id}`);
  },
  generateContractDocx(contractId) {
    return apiClient.get(`/contracts/${contractId}/generate-pdf`, {
      responseType: "arraybuffer",
    });
  },
  getArtistTracks(artistId) {
    return apiClient.get(`/artists/${artistId}/tracks`);
  },
  getContractStats() {
    return apiClient.get("/contracts/stats");
  },

  // Company related endpoints
  getCurrentCompany() {
    return apiClient.get("/companies/current");
  },
  registerCompany(companyData) {
    return apiClient.post("/auth/register-company", companyData);
  },
  getCompanyUsers() {
    return apiClient.get("/users");
  },

  inviteUser(invitationData) {
    return apiClient.post("/users/invite", invitationData);
  },

  removeUser(userId) {
    return apiClient.delete(`/users/${userId}`);
  },

  toggleUserStatus(userId) {
    return apiClient.post(`/users/${userId}/toggle-status`);
  },

  validateInvitationToken(token) {
    return apiClient.get(`/users/validate-invitation-token?token=${token}`);
  },
  // Accept invitation
  acceptInvitation(acceptanceData) {
    return apiClient.post("/users/accept-invitation", acceptanceData);
  },

  resendInvitation(userId) {
    return apiClient.post(`/users/resend-invitation/${userId}`);
  },
  getPendingInvitations() {
    return apiClient.get("/users/pending-invitations");
  },
  cancelInvitation(userId) {
    return apiClient.delete(`/users/cancel-invitation/${userId}`);
  },

  // Profile
  getProfile() {
    return apiClient.get("/profile");
  },

  updateProfile(profileData) {
    return apiClient.put("/profile", profileData);
  },

  updateProfileImage(formData) {
    return apiClient.post("/profile/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  changePassword({ current_password, new_password }) {
    return apiClient.post("/profile/change-password", {
      current_password,
      new_password,
    });
  },

  logout(token) {
    return apiClient.post(
      "/auth/logout",
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  //dashboard
  getDashboardStats() {
    return apiClient.get("/dashboard/stats");
  },

  getRecentActivities() {
    return apiClient.get("/dashboard/recent-activities");
  },

  getArtistPerformance() {
    return apiClient.get("/dashboard/artist-performance");
  },

  getUpcomingReleases() {
    return apiClient.get("/dashboard/upcoming-releases");
  },

  getTasks() {
    return apiClient.get("/tasks");
  },

  createTask(task) {
    return apiClient.post("/tasks", task);
  },

  updateTask(id, task) {
    return apiClient.put(`/tasks/${id}`, task);
  },

  deleteTask(id) {
    return apiClient.delete(`/tasks/${id}`);
  },
  createSubtask(taskId, subtaskData) {
    return apiClient.post(`/tasks/${taskId}/subtasks`, subtaskData);
  },

  updateSubtask(taskId, subtaskId, subtaskData) {
    return apiClient.put(`/tasks/${taskId}/subtasks/${subtaskId}`, subtaskData);
  },

  deleteSubtask(taskId, subtaskId) {
    return apiClient.delete(`/tasks/${taskId}/subtasks/${subtaskId}`);
  },


  getNotifications() {
    return apiClient.get("/dashboard/notifications");
  },

  getRevenueOverTime() {
    return apiClient.get("/reports/revenue-over-time");
  },

  getEvents() {
    return apiClient.get("/events");
  },

  createEvent(eventData) {
    return apiClient.post("/events", eventData);
  },

  getUserActivities() {
    return apiClient.get("/user-activity");
  },

  generateReport(reportType) {
    return apiClient.get(`/reports/${reportType}`);
  },

  // Calendar
  getCalendarReleases(params = {}) {
    return apiClient.get("/calendar/releases", { params });
  },

  setAuthHeader(token) {
    apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  removeAuthHeader() {
    delete apiClient.defaults.headers.common["Authorization"];
  },
};
