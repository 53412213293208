<template>
  <div
    class="min-h-screen flex items-center justify-center bg-primary-bg py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8">
      <div
        class="bg-primary-card p-8 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl"
      >
        <div class="text-center">
          <h2
            class="mt-6 text-3xl font-extrabold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
          >
            Create Your Account
          </h2>
          <p class="mt-2 text-sm text-text-secondary">
            Join us and start managing your music career
          </p>
        </div>
        <form class="mt-8 space-y-6" @submit.prevent="handleSubmit">
          <div class="space-y-4">
            <div>
              <label
                for="name"
                class="block text-sm font-medium text-text-secondary"
                >Full Name</label
              >
              <input
                id="name"
                name="name"
                type="text"
                v-model="name"
                :class="{ 'border-error': v$.name.$error }"
                class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary focus:z-10 sm:text-sm transition-all duration-300"
                placeholder="Full Name"
                @blur="v$.name.$touch()"
                @keydown.enter.prevent="focusNextInput('email')"
                ref="nameInput"
              />
              <div v-if="v$.name.$error" class="text-error text-xs mt-1">
                {{ v$.name.$errors[0].$message }}
              </div>
            </div>
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-text-secondary"
                >Email address</label
              >
              <div class="relative">
                <input
                  id="email"
                  name="email"
                  type="email"
                  v-model="email"
                  :class="{
                    'border-error':
                      (v$.email.$error || !isEmailAvailable) && v$.email.$dirty,
                    'border-success':
                      !v$.email.$error &&
                      isEmailAvailable &&
                      email &&
                      v$.email.$dirty,
                  }"
                  class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary focus:z-10 sm:text-sm transition-all duration-300"
                  placeholder="Email address"
                  @blur="
                    v$.email.$touch();
                    checkEmail();
                  "
                  @input="resetEmailAvailability"
                  @keydown.enter.prevent="focusNextInput('password')"
                  ref="emailInput"
                />
              </div>
              <div
                v-if="v$.email.$error && v$.email.$dirty"
                class="text-error text-xs mt-1"
              >
                {{ v$.email.$errors[0].$message }}
              </div>
              <div
                v-else-if="!isEmailAvailable && v$.email.$dirty"
                class="text-error text-xs mt-1"
              >
                This email is already registered.
              </div>
              <div
                v-else-if="
                  isEmailAvailable &&
                  email &&
                  !v$.email.$invalid &&
                  v$.email.$dirty
                "
                class="text-success text-xs mt-1"
              >
                Email is available.
              </div>
            </div>
            <div class="relative">
              <label
                for="password"
                class="block text-sm font-medium text-text-secondary"
                >Password</label
              >
              <div class="relative">
                <input
                  id="password"
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  :class="{ 'border-error': v$.password.$error }"
                  class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 pr-10 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary sm:text-sm transition-all duration-300"
                  placeholder="Password"
                  @blur="v$.password.$touch()"
                  @keydown.enter.prevent="focusNextInput('confirmPassword')"
                  ref="passwordInput"
                />
                <button
                  type="button"
                  @click="togglePassword('password')"
                  class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  tabindex="-1"
                >
                  <i
                    :class="[
                      'fas',
                      showPassword ? 'fa-eye-slash' : 'fa-eye',
                      'text-text-secondary hover:text-text-primary transition-colors duration-200',
                    ]"
                  ></i>
                </button>
              </div>
              <div v-if="v$.password.$error" class="text-error text-xs mt-1">
                {{ v$.password.$errors[0].$message }}
              </div>
            </div>

            <div class="relative">
              <label
                for="confirmPassword"
                class="block text-sm font-medium text-text-secondary"
                >Confirm Password</label
              >
              <div class="relative">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  v-model="confirmPassword"
                  :class="{ 'border-error': v$.confirmPassword.$error }"
                  class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 pr-10 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary sm:text-sm transition-all duration-300"
                  placeholder="Confirm Password"
                  @blur="v$.confirmPassword.$touch()"
                  @input="v$.confirmPassword.$touch()"
                  @keydown.enter.prevent="focusNextInput('organization')"
                  ref="confirmPasswordInput"
                />
                <button
                  type="button"
                  @click="togglePassword('confirmPassword')"
                  class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  tabindex="-1"
                >
                  <i
                    :class="[
                      'fas',
                      showConfirmPassword ? 'fa-eye-slash' : 'fa-eye',
                      'text-text-secondary hover:text-text-primary transition-colors duration-200',
                    ]"
                  ></i>
                </button>
              </div>
              <div
                v-if="v$.confirmPassword.$error"
                class="text-error text-xs mt-1"
              >
                {{ v$.confirmPassword.$errors[0].$message }}
              </div>
            </div>
            <div>
              <label
                for="organization"
                class="block text-sm font-medium text-text-secondary"
                >Organization</label
              >
              <input
                id="organization"
                name="organization"
                type="text"
                v-model="organization"
                :class="{ 'border-error': v$.organization.$error }"
                class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary focus:z-10 sm:text-sm transition-all duration-300"
                placeholder="Organization"
                @blur="v$.organization.$touch()"
                @keydown.enter.prevent="handleSubmit"
                ref="organizationInput"
              />
              <div
                v-if="v$.organization.$error"
                class="text-error text-xs mt-1"
              >
                {{ v$.organization.$errors[0].$message }}
              </div>
            </div>
          </div>

          <div class="flex items-center">
            <input
              id="terms"
              name="terms"
              type="checkbox"
              v-model="agreeToTerms"
              :class="{ 'border-error': v$.agreeToTerms.$error }"
              class="h-4 w-4 text-accent-primary focus:ring-accent-primary border-gray-700 rounded bg-primary-lighter"
              @blur="v$.agreeToTerms.$touch()"
            />
            <label for="terms" class="ml-2 block text-sm text-text-secondary">
              I agree to the
              <a
                href="#"
                class="font-medium text-accent-primary hover:text-accent-secondary transition-colors duration-200"
                >Terms and Conditions</a
              >
            </label>
          </div>
          <div v-if="v$.agreeToTerms.$error" class="text-error text-xs mt-1">
            {{ v$.agreeToTerms.$errors[0].$message }}
          </div>

          <div v-if="error" class="text-error text-sm mt-2">
            {{ error }}
          </div>
          <button
            type="submit"
            :disabled="isLoading || v$.$invalid || !isEmailAvailable"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-accent-primary hover:bg-accent-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-primary transition-all duration-300"
            :class="{
              'opacity-50 cursor-not-allowed':
                isLoading || v$.$invalid || !isEmailAvailable,
            }"
          >
            {{ isLoading ? "Signing up..." : "Sign up" }}
          </button>
        </form>
      </div>
      <div class="text-center mt-4">
        <p class="text-sm text-text-secondary">
          Already have an account?
          <router-link
            to="/login"
            class="font-medium text-accent-primary hover:text-accent-secondary transition-colors duration-200"
            >Sign in</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "SignupView",
  setup() {
    const router = useRouter();
    const store = useStore();

    const state = reactive({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      organization: "",
      showPassword: false,
      showConfirmPassword: false,
      isLoading: false,
      agreeToTerms: false,
      isEmailAvailable: true,
    });

    const nameInput = ref(null);
    const emailInput = ref(null);
    const passwordInput = ref(null);
    const confirmPasswordInput = ref(null);
    const organizationInput = ref(null);
    const error = computed(() => store.getters["auth/error"]);

    const rules = computed(() => ({
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(8) },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(state.password),
      },
      organization: { required },
      agreeToTerms: { required: (v) => v === true },
    }));

    const v$ = useVuelidate(rules, state);

    const checkEmail = async () => {
      if (state.email && !v$.value.email.$error) {
        try {
          state.isEmailAvailable = await store.dispatch(
            "auth/checkEmailAvailability",
            state.email
          );
        } catch (error) {
          console.error("Error checking email availability:", error);
          state.isEmailAvailable = false;
        }
      }
    };
    const resetEmailAvailability = () => {
      state.isEmailAvailable = true;
      store.dispatch("auth/clearError");
    };

    const handleSubmit = async () => {
      const result = await v$.value.$validate();
      if (result && state.isEmailAvailable) {
        state.isLoading = true;
        try {
          await store.dispatch("auth/register", {
            name: state.name,
            email: state.email,
            password: state.password,
            organization: state.organization,
          });
          router.push("/dashboard");
        } catch (error) {
          console.error("Signup failed:", error);
          // Error is now handled in the Vuex store
        } finally {
          state.isLoading = false;
        }
      }
    };

    const togglePassword = (field) => {
      if (field === "password") {
        state.showPassword = !state.showPassword;
      } else if (field === "confirmPassword") {
        state.showConfirmPassword = !state.showConfirmPassword;
      }
    };

    const focusNextInput = (nextInput) => {
      switch (nextInput) {
        case "email":
          emailInput.value.focus();
          break;
        case "password":
          passwordInput.value.focus();
          break;
        case "confirmPassword":
          confirmPasswordInput.value.focus();
          break;
        case "organization":
          organizationInput.value.focus();
          break;
      }
    };

    return {
      ...toRefs(state),
      v$,
      handleSubmit,
      togglePassword,
      focusNextInput,
      nameInput,
      emailInput,
      passwordInput,
      confirmPasswordInput,
      organizationInput,
      checkEmail,
      resetEmailAvailability,
      error,
    };
  },
};
</script>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.bg-primary-bg {
  background-color: var(--color-primary-bg);
}

.bg-primary-card {
  background-color: var(--color-primary-card);
}

.bg-primary-lighter {
  background-color: var(--color-primary-lighter);
}

.text-text-primary {
  color: var(--color-text-primary);
}

.text-text-secondary {
  color: var(--color-text-secondary);
}

.text-accent-primary {
  color: var(--color-accent-primary);
}

.text-accent-secondary {
  color: var(--color-accent-secondary);
}

.bg-accent-primary {
  background-color: var(--color-accent-primary);
}

.hover\:bg-accent-secondary:hover {
  background-color: var(--color-accent-secondary);
}

.focus\:ring-accent-primary:focus {
  --tw-ring-color: var(--color-accent-primary);
}

.text-error {
  color: var(--color-error);
}

.border-error {
  border-color: var(--color-error);
}

.text-success {
  color: var(--color-success);
}

.border-success {
  border-color: var(--color-success);
}

/* Dark mode styles */
.dark .bg-primary-bg {
  background-color: var(--color-primary-darkest);
}

.dark .bg-primary-card {
  background-color: var(--color-primary-darker);
}

.dark .bg-primary-lighter {
  background-color: var(--color-primary-card);
}
</style>
