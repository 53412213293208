const state = {
  isDarkMode: localStorage.getItem("colorScheme") === "dark",
};

const getters = {
  isDarkMode: (state) => state.isDarkMode,
};

const actions = {
  toggleColorScheme({ commit }) {
    commit("TOGGLE_COLOR_SCHEME");
  },
  initColorScheme({ commit }) {
    const savedScheme = localStorage.getItem("colorScheme");
    if (savedScheme) {
      commit("SET_COLOR_SCHEME", savedScheme === "dark");
    } else {
      // Check for system preference
      const prefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      commit("SET_COLOR_SCHEME", prefersDark);
    }
  },
};

const mutations = {
  TOGGLE_COLOR_SCHEME(state) {
    state.isDarkMode = !state.isDarkMode;
    localStorage.setItem("colorScheme", state.isDarkMode ? "dark" : "light");
    applyColorScheme(state.isDarkMode);
  },
  SET_COLOR_SCHEME(state, isDark) {
    state.isDarkMode = isDark;
    localStorage.setItem("colorScheme", isDark ? "dark" : "light");
    applyColorScheme(isDark);
  },
};

function applyColorScheme(isDark) {
  if (isDark) {
    document.documentElement.classList.remove("light");
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
    document.documentElement.classList.add("light");
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
