<template>
  <div class="p-6 bg-primary-bg min-h-screen">
    <h1
      class="text-3xl font-semibold text-text-primary mb-6 text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
    >
      Artists
    </h1>
    
    <div class="mb-4 flex justify-between items-center">
      <div class="flex items-center space-x-4">
        <button
          v-if="isCheckboxVisible"
          @click="toggleSelectAll"
          class="btn btn-secondary"
        >
          {{ isAllSelected ? 'Deselect All' : 'Select All' }}
        </button>
        <button
          v-if="isCheckboxVisible"
          @click="confirmDelete()"
          class="btn btn-danger"
          :disabled="selectedArtistsForBulkDelete.length === 0"
        >
          <i class="fas fa-trash-alt mr-2"></i>Delete Selected ({{ selectedArtistsForBulkDelete.length }})
        </button>
        <button 
          v-if="isCheckboxVisible" 
          @click="exitCheckboxMode" 
          class="btn btn-secondary"
        >
          Exit Selection Mode
        </button>
      </div>
    </div>

    <ListView
      :items="artists"
      :columns="visibleColumns"
      :form-fields="formFields"
      :has-more="hasMore"
      entity-name="Artist"
      :key-field="'id'"
      @add="showAddArtistModal = true"
      @update="openEditModal"
      @delete="confirmDelete"
      @show-details="showArtistDetails"
      @load-more="loadMoreArtists"
      @contextmenu.prevent="showCheckboxes"
    >
      <template #checkbox="{ item }" v-if="isCheckboxVisible">
        <div @click.stop>
          <input
            type="checkbox"
            :checked="selectedArtistsForBulkDelete.includes(item.id)"
            @change="toggleArtistSelection(item.id)"
            class="form-checkbox h-5 w-5 text-accent-primary"
          />
        </div>
      </template>
      <template #image_url="{ item }">
        <img
          :src="getImageUrl(item.image_url)"
          :alt="item.name"
          class="h-12 w-12 rounded-full object-cover border-2 border-accent-primary transition-transform duration-300 hover:scale-110"
        />
      </template>
      <template #genres="{ item }">
        <div class="flex flex-wrap gap-1">
          <span
            v-for="genre in Array.isArray(item.genres)
              ? item.genres
              : item.genres.split(',')"
            :key="genre"
            class="px-2 py-1 text-xs bg-accent-secondary bg-opacity-20 text-text-primary rounded-full transition-colors duration-300 hover:bg-opacity-30"
          >
            {{ genre.trim() }}
          </span>
        </div>
      </template>
    </ListView>

    <!-- Add Artist Modal -->
    <BaseModal
      :is-open="showAddArtistModal"
      @close="showAddArtistModal = false"
      size="lg"
    >
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          Add Artist
        </h2>
      </template>
      <template #body>
        <div class="mb-6 flex justify-center">
          <div class="w-32 h-32 relative">
            <img
              :src="newArtistImagePreview || 'https://via.placeholder.com/128'"
              alt="New Artist"
              class="w-full h-full rounded-full object-cover border-4 border-accent-primary transition-transform duration-300 hover:scale-105"
            />
            <label
              for="newArtistImageUpload"
              class="absolute bottom-0 right-0 bg-accent-primary text-white p-2 rounded-full cursor-pointer hover:bg-accent-primary-dark transition-colors duration-200 w-10 h-10 flex items-center justify-center"
            >
              <i class="fas fa-camera"></i>
            </label>
            <input
              id="newArtistImageUpload"
              type="file"
              accept="image/*"
              class="hidden"
              @change="handleNewArtistImageUpload"
            />
          </div>
        </div>
        <DynamicForm
          :fields="addArtistFormFields"
          @submit="addArtist"
          @cancel="showAddArtistModal = false"
        />
      </template>
    </BaseModal>

    <!-- Artist Details Modal -->
    <BaseModal
      :is-open="!!selectedArtist"
      @close="selectedArtist = null"
      size="lg"
    >
      <template #header>
        <h3
          class="text-2xl font-semibold text-center text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          Artist Details
        </h3>
      </template>
      <template #body>
        <div v-if="selectedArtist" class="flex flex-col items-center space-y-8">
          <!-- Centered Artist Image -->
          <div class="flex justify-center">
            <img
              :src="getImageUrl(selectedArtist.image_url)"
              :alt="selectedArtist.name"
              class="h-28 w-28 rounded-full object-cover shadow-lg border-4 border-accent-primary transition-transform duration-300 hover:scale-110"
            />
          </div>

          <!-- Two-column layout for details -->
          <div
            class="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6 max-w-3xl mx-auto w-full"
          >
            <!-- Left column -->
            <div class="space-y-4">
              <div class="text-center">
                <h4 class="text-sm font-medium text-text-secondary uppercase">
                  Name
                </h4>
                <p class="mt-1 text-xl text-text-primary">
                  {{ selectedArtist.name }}
                </p>
              </div>

              <div class="text-center">
                <h4 class="text-sm font-medium text-text-secondary uppercase">
                  Website
                </h4>
                <a
                  :href="selectedArtist.website"
                  target="_blank"
                  class="mt-1 text-lg text-accent-secondary hover:text-accent-secondary-light transition-colors duration-200"
                >
                  {{ selectedArtist.website || "N/A" }}
                </a>
              </div>
            </div>

            <!-- Right column -->
            <div class="space-y-4">
              <div class="text-center">
                <h4 class="text-sm font-medium text-text-secondary uppercase">
                  Status
                </h4>
                <p class="mt-1 text-lg text-text-primary">
                  {{ selectedArtist.status }}
                </p>
              </div>
              <div class="text-center">
                <h4 class="text-sm font-medium text-text-secondary uppercase">
                  Genres
                </h4>
                <p class="mt-1 text-lg text-text-primary">
                  {{ formatGenres(selectedArtist.genres) }}
                </p>
              </div>
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-center space-x-4 mt-8">
            <button
              @click="openEditModal(selectedArtist)"
              class="btn btn-primary"
            >
              <i class="fas fa-edit mr-2"></i>Edit
            </button>
            <button
              @click="confirmDelete(selectedArtist.id)"
              class="btn btn-danger"
            >
              <i class="fas fa-trash-alt mr-2"></i>Delete
            </button>
          </div>
        </div>
      </template>
    </BaseModal>

    <!-- Edit Artist Modal -->
    <BaseModal :is-open="showEditModal" @close="closeEditModal" size="lg">
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          Edit Artist
        </h2>
      </template>
      <template #body>
        <div class="mb-6 flex flex-col items-center">
          <div class="relative">
            <img
              :src="getImagePreview(editingArtist?.image_url)"
              alt="Artist Image"
              class="h-32 w-32 rounded-full object-cover border-4 border-accent-primary transition-transform duration-300 hover:scale-110"
            />
            <label
              for="imageUpload"
              class="absolute bottom-0 right-0 bg-accent-primary text-white p-2 rounded-full cursor-pointer hover:bg-accent-primary-dark transition-colors duration-200 w-10 h-10 flex items-center justify-center"
            >
              <i class="fas fa-camera"></i>
            </label>
            <input
              id="imageUpload"
              type="file"
              accept="image/*"
              class="hidden"
              @change="handleImageUpload"
            />
          </div>
        </div>
        <DynamicForm
          v-if="editingArtist"
          :fields="editFormFields"
          :initial-data="editingArtist"
          @submit="updateArtist"
          @cancel="closeEditModal"
        />
      </template>
    </BaseModal>

    <!-- Delete Confirmation Modal -->
    <BaseModal
      :is-open="showDeleteConfirmModal"
      @close="showDeleteConfirmModal = false"
      size="sm"
    >
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-error to-error-light"
        >
          Confirm Delete
        </h2>
      </template>
      <template #body>
        <p class="text-text-primary mb-4">
          Are you sure you want to delete {{ selectedArtistsForBulkDelete.length > 1 ? 'these artists' : 'this artist' }}?
        </p>
        <div class="mt-4 flex justify-end space-x-2">
          <button
            @click="showDeleteConfirmModal = false"
            class="btn btn-secondary"
          >
            Cancel
          </button>
          <button @click="bulkDeleteArtists" class="btn btn-danger">Delete</button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.btn {
  @apply px-4 py-2 rounded-md font-medium transition-colors duration-normal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-bg;
}
.btn-primary {
  @apply bg-accent-primary text-white hover:bg-accent-primary-dark focus:ring-accent-primary;
}

.btn-secondary {
  @apply bg-primary-lighter text-text-primary hover:bg-primary-card focus:ring-accent-secondary;
}

.btn-danger {
  @apply bg-error text-white hover:bg-error-light focus:ring-error;
}

:deep(*) {
  -webkit-context-menu: none;
  -moz-context-menu: none;
  -ms-context-menu: none;
  context-menu: none;
}
</style>

<script>
import { ref, computed, onMounted, watch } from "vue";
import ListView from "@/components/ListView.vue";
import BaseModal from "@/components/BaseModal.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import api from "@/services/api";

export default {
  name: "ArtistsView",
  components: {
    ListView,
    BaseModal,
    DynamicForm,
  },
  setup() {
    const artists = ref([]);
    const selectedArtist = ref(null);
    const showAddArtistModal = ref(false);
    const editingArtist = ref(null);
    const showEditModal = ref(false);
    const showDeleteConfirmModal = ref(false);
    const newArtistImagePreview = ref(null);
    const page = ref(1);
    const hasMore = ref(true);
    const isLoading = ref(false);
    const selectedArtistsForBulkDelete = ref([]);
    const isCheckboxVisible = ref(false);

    const columns = ref([
      { key: "checkbox", label: "", visible: true, filterable: false },
      { key: "image_url", label: "Image", visible: true, filterable: false },
      { key: "name", label: "Name", visible: true, filterable: true },
      { key: "status", label: "Status", visible: true, filterable: true },
      { key: "genres", label: "Genres", visible: true, filterable: true },
      { key: "website", label: "Website", visible: true, filterable: true },
    ]);

    const visibleColumns = computed(() => {
      return columns.value.filter(column => 
        column.key !== 'checkbox' || isCheckboxVisible.value
      );
    });

    const formFields = ref([
      { key: "name", label: "Name", type: "text", required: true },
      {
        key: "status",
        label: "Status",
        type: "select",
        options: [
          { value: "Active", label: "Active" },
          { value: "Inactive", label: "Inactive" },
          { value: "On Hiatus", label: "On Hiatus" },
        ],
        required: true,
      },
      {
        key: "genres",
        label: "Genres",
        type: "text",
        placeholder: "Enter genres separated by commas",
      },
      { key: "website", label: "Website", type: "url" },
      {
        key: "social_media",
        label: "Social Media",
        type: "textarea",
        placeholder: 'Enter as JSON: {"platform": "url"}',
      },
      { key: "notes", label: "Notes", type: "textarea" },
    ]);

    const addArtistFormFields = computed(() => [
      { key: "name", label: "Name", type: "text", required: true },
      {
        key: "status",
        label: "Status",
        type: "select",
        options: [
          { value: "Active", label: "Active" },
          { value: "Inactive", label: "Inactive" },
          { value: "On Hiatus", label: "On Hiatus" },
        ],
        required: true,
      },
      {
        key: "genres",
        label: "Genres",
        type: "text",
        placeholder: "Enter genres separated by commas",
      },
      { key: "website", label: "Website", type: "url" },
      {
        key: "social_media",
        label: "Social Media",
        type: "textarea",
        placeholder: 'Enter as JSON: {"platform": "url"}',
      },
      { key: "notes", label: "Notes", type: "textarea" },
    ]);

    const artistDetails = computed(() => {
      if (!selectedArtist.value) return {};
      const {
        id,
        name,
        status,
        biography,
        genres,
        social_media,
        website,
        notes,
        image_url,
      } = selectedArtist.value;
      return {
        id,
        name,
        status,
        biography,
        genres,
        social_media,
        website,
        notes,
        image_url,
      };
    });

    const fetchArtists = async () => {
      if (isLoading.value || !hasMore.value) return;

      isLoading.value = true;
      try {
        const response = await api.getArtists({
          page: page.value,
          per_page: 20,
        });
        artists.value = [...artists.value, ...response.data.artists];
        hasMore.value = response.data.has_more;
        page.value++;
      } catch (error) {
        console.error("Error fetching artists:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const loadMoreArtists = () => {
      if (hasMore.value && !isLoading.value) {
        fetchArtists();
      }
    };

    const addArtist = async (formData) => {
      try {
        const form = new FormData();
        for (const [key, value] of Object.entries(formData)) {
          form.append(key, value);
        }

        // Append the image file if it was uploaded
        const imageInput = document.getElementById("newArtistImageUpload");
        if (imageInput.files[0]) {
          form.append("image", imageInput.files[0]);
        }

        const response = await api.createArtist(form);
        artists.value.push(response.data);
        showAddArtistModal.value = false;
        newArtistImagePreview.value = null;
      } catch (error) {
        console.error("Error creating artist:", error);
      }
    };

    const confirmDelete = (id) => {
      if (id) {
        selectedArtistsForBulkDelete.value = [id];
      }
      showDeleteConfirmModal.value = true;
    };

    const bulkDeleteArtists = async () => {
      try {
        await Promise.all(
          selectedArtistsForBulkDelete.value.map((id) => api.deleteArtist(id))
        );
        artists.value = artists.value.filter(
          (artist) => !selectedArtistsForBulkDelete.value.includes(artist.id)
        );
        selectedArtistsForBulkDelete.value = [];
        showDeleteConfirmModal.value = false;
      } catch (error) {
        console.error("Error deleting artists:", error);
      }
    };

    const handleNewArtistImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        newArtistImagePreview.value = URL.createObjectURL(file);
      }
    };

    const editFormFields = computed(() => [
      {
        key: "name",
        label: "Name",
        type: "text",
        required: true,
        fullWidth: false,
      },
      { key: "website", label: "Website", type: "url", fullWidth: false },
      {
        key: "status",
        label: "Status",
        type: "select",
        options: [
          { value: "Active", label: "Active" },
          { value: "Inactive", label: "Inactive" },
          { value: "On Hiatus", label: "On Hiatus" },
        ],
        required: true,
        fullWidth: false,
      },
      {
        key: "genres",
        label: "Genres",
        type: "text",
        placeholder: "Enter genres separated by commas",
        fullWidth: false,
      },
      { key: "image", type: "hidden" },
    ]);

    const filteredArtistDetails = computed(() => {
      if (!selectedArtist.value) return {};
      const { name, status, genres, notes, website, social_media } =
        selectedArtist.value;
      return { name, status, genres, notes, website, social_media };
    });

    const formatGenres = (genres) => {
      if (Array.isArray(genres)) {
        return genres.join(", ");
      }
      return genres || "";
    };

    const formatSocialMedia = (socialMedia) => {
      if (typeof socialMedia === "object") {
        return Object.entries(socialMedia)
          .map(([platform, url]) => `${platform}: ${url}`)
          .join(", ");
      }
      return socialMedia || "";
    };

    const getImageUrl = (imageUrl) => {
      if (!imageUrl) return "https://via.placeholder.com/150";
      if (imageUrl.startsWith("http")) return imageUrl;
      return `${process.env.VUE_APP_API_URL}${imageUrl}`;
    };

    const formatLabel = (key) => {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const initializeEditingArtist = () => {
      editingArtist.value = {
        id: null,
        name: "",
        status: "Active",
        genres: [],
        website: "",
        notes: "",
        social_media: {},
        image_url: "",
      };
    };

    const showArtistDetails = (artist) => {
      selectedArtist.value = artist;
    };

    const openEditModal = () => {
      if (selectedArtist.value) {
        editingArtist.value = JSON.parse(JSON.stringify(selectedArtist.value));
      } else {
        initializeEditingArtist();
      }
      showEditModal.value = true;
    };

    const closeEditModal = () => {
      showEditModal.value = false;
      editingArtist.value = null;
    };

    const updateArtist = async (formData) => {
      try {
        const response = await api.updateArtist(
          editingArtist.value.id,
          formData
        );
        const index = artists.value.findIndex(
          (a) => a.id === editingArtist.value.id
        );
        if (index !== -1) {
          artists.value[index] = response.data;
        }
        selectedArtist.value = response.data;
        closeEditModal();
      } catch (error) {
        console.error("Error updating artist:", error);
      }
    };

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        editingArtist.value.image = file;
        editingArtist.value.image_url = URL.createObjectURL(file);
      }
    };

    const getImagePreview = (imageUrl) => {
      if (imageUrl instanceof File) {
        return URL.createObjectURL(imageUrl);
      } else if (typeof imageUrl === "string" && imageUrl.startsWith("blob:")) {
        return imageUrl;
      }
      return getImageUrl(imageUrl);
    };

    const toggleArtistSelection = (artistId) => {
      const index = selectedArtistsForBulkDelete.value.indexOf(artistId);
      if (index === -1) {
        selectedArtistsForBulkDelete.value.push(artistId);
      } else {
        selectedArtistsForBulkDelete.value.splice(index, 1);
      }
    };

    const isAllSelected = computed(() => {
      return artists.value.length > 0 && selectedArtistsForBulkDelete.value.length === artists.value.length;
    });

    const toggleSelectAll = () => {
      if (isAllSelected.value) {
        selectedArtistsForBulkDelete.value = [];
      } else {
        selectedArtistsForBulkDelete.value = artists.value.map(artist => artist.id);
      }
    };

    const showCheckboxes = (event) => {
      event.preventDefault();
      isCheckboxVisible.value = true;
    };

    const exitCheckboxMode = () => {
      isCheckboxVisible.value = false;
      selectedArtistsForBulkDelete.value = [];
    };

    watch(selectedArtist, (newValue) => {
      if (newValue) {
        editingArtist.value = { ...newValue };
      } else {
        initializeEditingArtist();
      }
    });

    onMounted(() => {
      fetchArtists();
      initializeEditingArtist();
    });

    return {
      artists,
      visibleColumns,
      formFields,
      showAddArtistModal,
      editingArtist,
      showEditModal,
      showDeleteConfirmModal,
      selectedArtist,
      artistDetails,
      addArtist,
      updateArtist,
      bulkDeleteArtists,
      showArtistDetails,
      getImageUrl,
      confirmDelete,
      formatLabel,
      formatSocialMedia,
      formatGenres,
      filteredArtistDetails,
      handleImageUpload,
      editFormFields,
      openEditModal,
      closeEditModal,
      getImagePreview,
      newArtistImagePreview,
      handleNewArtistImageUpload,
      addArtistFormFields,
      hasMore,
      loadMoreArtists,
      selectedArtistsForBulkDelete,
      toggleArtistSelection,
      isAllSelected,
      toggleSelectAll,
      isCheckboxVisible,
      showCheckboxes,
      exitCheckboxMode,
    };
  },
};
</script>
