<template>
  <div class="flex flex-col h-screen bg-primary-bg p-4">
    <div class="flex-none bg-primary-card shadow-md rounded-lg mb-4">
      <div class="p-4">
        <div class="flex flex-wrap items-center gap-2">
          <select
            v-for="(value, key) in filters"
            :key="key"
            v-model="filters[key]"
            @change="applyFilters"
            class="px-4 py-2 text-sm bg-primary-lighter border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-accent-primary text-text-primary transition-colors duration-normal"
          >
            <option value="">{{ getFilterLabel(key) }}</option>
            <option
              v-for="option in getFilterOptions(key)"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
          <button
            @click="resetFilters"
            class="px-4 py-2 text-sm bg-primary-lighter text-text-primary rounded-md hover:bg-primary-card focus:outline-none focus:ring-2 focus:ring-accent-primary transition-colors duration-normal"
          >
            <i class="fas fa-undo mr-2"></i>Reset
          </button>
        </div>
      </div>
    </div>
    <div
      class="flex-grow overflow-hidden bg-primary-card shadow-md rounded-lg p-4"
    >
      <FullCalendar
        ref="fullCalendar"
        :options="calendarOptions"
        class="h-full"
      />
    </div>

    <!-- Release Details Modal -->
    <BaseModal :is-open="!!selectedRelease" @close="selectedRelease = null">
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-center text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          {{ selectedRelease?.name }}
        </h2>
      </template>
      <template #body>
        <div
          v-if="selectedRelease"
          class="flex flex-col items-center space-y-6"
        >
          <div class="flex items-center space-x-4">
            <div
              class="bg-accent-primary bg-opacity-20 rounded-full w-12 h-12 flex items-center justify-center"
            >
              <i class="fas fa-music text-2xl"></i>
            </div>
            <span class="text-lg font-medium text-text-primary">{{
              selectedRelease.artist.name
            }}</span>
          </div>
          <div class="flex items-center space-x-4">
            <div
              class="bg-accent-secondary-400 bg-opacity-20 rounded-full w-12 h-12 flex items-center justify-center"
            >
              <i class="fas fa-building text-2xl"></i>
            </div>
            <span class="text-lg font-medium text-text-primary">{{
              selectedRelease.organization.name
            }}</span>
          </div>
          <div class="grid grid-cols-2 gap-x-14 gap-y-8 mt-4">
            <div
              v-for="field in releaseFields"
              :key="field.key"
              class="flex flex-col items-center"
            >
              <span class="text-sm font-medium text-text-secondary">{{
                field.label
              }}</span>
              <div class="mt-1">
                <template v-if="field.type === 'checkbox'">
                  <div
                    :class="[
                      'w-8 h-8 rounded-full flex items-center justify-center',
                      getFieldValue(selectedRelease, field.key)
                        ? 'bg-success bg-opacity-20 text-primary'
                        : 'bg-error bg-opacity-20 text-primary',
                    ]"
                  >
                    <i
                      :class="[
                        'fas',
                        getFieldValue(selectedRelease, field.key)
                          ? 'fa-check'
                          : 'fa-times',
                      ]"
                    ></i>
                  </div>
                </template>
                <template v-else-if="field.key === 'status'">
                  <span
                    :class="[
                      'px-2 py-1 text-xs font-medium rounded-full',
                      {
                        'bg-warning bg-opacity-20 text-secondary':
                          getFieldValue(selectedRelease, field.key) ===
                          'In Production',
                        'bg-success bg-opacity-20 text-secondary':
                          getFieldValue(selectedRelease, field.key) ===
                          'Released',
                        'bg-accent-primary bg-opacity-20 text-seondary':
                          getFieldValue(selectedRelease, field.key) ===
                          'Planned',
                      },
                    ]"
                  >
                    {{ getFieldValue(selectedRelease, field.key) }}
                  </span>
                </template>
                <template v-else>
                  <span class="text-text-primary">{{
                    getFieldValue(selectedRelease, field.key)
                  }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

:deep(.fc) {
  --fc-border-color: theme("colors.primary.darker");
  --fc-button-bg-color: theme("colors.accent.primary.DEFAULT");
  --fc-button-border-color: theme("colors.accent.primary.DEFAULT");
  --fc-button-hover-bg-color: theme("colors.accent.primary.600");
  --fc-button-hover-border-color: theme("colors.accent.primary.700");
  --fc-button-active-bg-color: theme("colors.accent.primary.700");
  --fc-button-active-border-color: theme("colors.accent.primary.800");
  --fc-event-bg-color: theme("colors.accent.secondary.DEFAULT");
  --fc-event-border-color: theme("colors.accent.secondary.600");
  --fc-event-text-color: theme("colors.white");
  --fc-today-bg-color: theme("colors.accent.secondary.100");
}

:deep(.fc-view) {
  background-color: theme("colors.primary.lighter");
}

:deep(.fc-highlight) {
  background-color: theme("colors.accent.secondary.DEFAULT");
  opacity: 0.3;
}

:deep(.fc-toolbar-title) {
  color: theme("colors.text.primary");
}

:deep(.fc-col-header-cell) {
  background-color: theme("colors.primary.lighter");
  color: theme("colors.text.secondary");
}

:deep(.fc-daygrid-day-number),
:deep(.fc-daygrid-day-top) {
  color: theme("colors.text.primary");
}

:deep(.fc-event-title),
:deep(.fc-event-time) {
  color: theme("colors.text.primary");
}

:deep(.fc-day-today) {
  background-color: theme("colors.primary.lighter") !important;
}

:deep(.fc-button) {
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-primary;
}

:deep(.fc-button-primary:not(:disabled):active),
:deep(.fc-button-primary:not(:disabled).fc-button-active) {
  @apply shadow-inner;
}

:deep(.fc-daygrid-event) {
  @apply rounded-md shadow-sm transition-transform duration-200 ease-in-out;
}

:deep(.fc-daygrid-event:hover) {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

:deep(.fc-scroller) {
  height: auto !important;
}

:deep(.fc-day-grid-container) {
  height: auto !important;
}

:deep(.fc-row) {
  min-height: 2em;
}
:deep(.fc-timegrid-slot-label) {
  color: theme("colors.text.secondary");
}

:deep(.fc-list-day-cushion) {
  background-color: theme("colors.primary.lighter");
}

:deep(.fc-list-event-time),
:deep(.fc-list-event-title) {
  color: theme("colors.text.primary");
}

:deep(.fc-list-day-side-text),
:deep(.fc-list-day-text) {
  color: theme("colors.text.secondary");
}

:deep(.fc-list-event:hover td) {
  background-color: rgba(
    theme("colors.accent.primary.DEFAULT"),
    0.1
  ) !important;
}

:deep(.fc-list-event-title a),
:deep(.fc-list-event-time) {
  color: theme("colors.text.primary") !important;
}

:deep(.fc-list-event:hover .fc-list-event-title a),
:deep(.fc-list-event:hover .fc-list-event-time) {
  color: theme("colors.text.secondary") !important;
}
</style>

<script>
import { ref, computed, onMounted } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import BaseModal from "@/components/BaseModal.vue";
import api from "@/services/api";

export default {
  name: "EventCalendar",
  components: {
    FullCalendar,
    BaseModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const fullCalendar = ref(null);
    const releases = ref([]);
    const selectedRelease = ref(null);
    const showAddReleaseModal = ref(false);
    const editingRelease = ref(null);
    const artists = ref([]);
    const organizations = ref([]);
    const filters = ref({
      status: "",
      release_type: "",
      contract: "",
      artist_id: "",
      organization_id: "",
    });

    const releaseFields = [
      { key: "release_date", label: "Release Date", type: "date" },
      { key: "status", label: "Status", type: "select" },
      { key: "catalog_number", label: "Catalog Number", type: "text" },
      { key: "upc_number", label: "UPC Number", type: "text" },
      { key: "contract", label: "Contract", type: "checkbox" },
      { key: "release_type", label: "Release Type", type: "select" },
    ];

    const fetchReleases = async () => {
      try {
        const response = await api.getCalendarReleases(filters.value);
        releases.value = response.data;
      } catch (error) {
        console.error("Error fetching releases:", error);
      }
    };

    const fetchArtistsAndOrganizations = async () => {
      try {
        const [artistsResponse, orgsResponse] = await Promise.all([
          api.getArtistsList(),
          api.getOrganizationsList(),
        ]);
        artists.value = artistsResponse.data;
        organizations.value = orgsResponse.data;
      } catch (error) {
        console.error("Error fetching artists and organizations:", error);
      }
    };

    const calendarOptions = computed(() => ({
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      },
      events: releases.value.map((release) => ({
        id: release.id,
        title: `${release.name} - ${release.artist.name}`,
        start: release.release_date,
        allDay: true,
        backgroundColor: getEventColor(release.status),
        borderColor: getEventColor(release.status),
        textColor: release.status === "Planned" ? "#000000" : "#FFFFFF", // Black text for yellow background
        extendedProps: { ...release },
      })),
      eventClick: (info) => {
        selectedRelease.value = info.event.extendedProps;
      },
      eventDidMount: (info) => {
        info.el.style.cursor = "pointer";
      },
      height: "100%",
    }));

    const applyFilters = () => {
      fetchReleases();
    };

    const resetFilters = () => {
      Object.keys(filters.value).forEach((key) => {
        filters.value[key] = "";
      });
      fetchReleases();
    };

    const editRelease = (release) => {
      editingRelease.value = release;
      showAddReleaseModal.value = true;
    };

    const saveRelease = async (releaseData) => {
      try {
        if (editingRelease.value) {
          await api.updateRelease(editingRelease.value.id, releaseData);
        } else {
          await api.createRelease(releaseData);
        }
        await fetchReleases();
        showAddReleaseModal.value = false;
        editingRelease.value = null;
      } catch (error) {
        console.error("Error saving release:", error);
      }
    };

    const getFieldValue = (release, key) => {
      if (!release[key] && release[key] !== false) {
        return null;
      }
      if (key === "release_date") {
        return release[key]
          ? new Date(release[key]).toLocaleDateString()
          : null;
      }
      return release[key];
    };

    const getFilterLabel = (key) => {
      const labels = {
        status: "All Statuses",
        release_type: "All Types",
        contract: "All Contracts",
        artist_id: "All Artists",
        organization_id: "All Organizations",
      };
      return labels[key] || `All ${key.replace("_", " ")}s`;
    };
    const getEventColor = (status) => {
      switch (status) {
        case "In Production":
          return "#3B82F6"; // Blue
        case "Planned":
          return "#F59E0B"; // Light golden
        case "Released":
          return "#10B981"; // Green
        default:
          return "#6B7280"; // Gray (for any other status)
      }
    };

    const getFilterOptions = (key) => {
      switch (key) {
        case "status":
          return [
            { value: "In Production", label: "In Production" },
            { value: "Planned", label: "Planned" },
            { value: "Released", label: "Released" },
          ];
        case "release_type":
          return [
            { value: "Single", label: "Single" },
            { value: "EP", label: "EP" },
            { value: "Album", label: "Album" },
          ];
        case "contract":
          return [
            { value: "true", label: "With Contract" },
            { value: "false", label: "Without Contract" },
          ];
        case "artist_id":
          return artists.value.map((artist) => ({
            value: artist.id,
            label: artist.name,
          }));
        case "organization_id":
          return organizations.value.map((org) => ({
            value: org.id,
            label: org.name,
          }));
        default:
          return [];
      }
    };

    onMounted(() => {
      fetchReleases();
      fetchArtistsAndOrganizations();
    });

    return {
      fullCalendar,
      releases,
      selectedRelease,
      showAddReleaseModal,
      editingRelease,
      filters,
      releaseFields,
      calendarOptions,
      artists,
      organizations,
      editRelease,
      saveRelease,
      getFieldValue,
      applyFilters,
      resetFilters,
      getFilterLabel,
      getFilterOptions,
      getEventColor,
    };
  },
};
</script>
