<template>
  <form
    @submit.prevent="handleSubmit"
    class="space-y-8 bg-primary-lighter rounded-lg shadow-md p-6"
  >
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div
        v-for="field in fields"
        :key="field.key"
        class="form-field"
        :class="{ 'md:col-span-2': field.fullWidth }"
      >
        <label
          :for="field.key"
          class="block text-sm font-medium text-text-secondary mb-1"
        >
          {{ field.label }}
          <span v-if="field.required" class="text-error">*</span>
        </label>

        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            v-if="
              ['text', 'email', 'url', 'date', 'number'].includes(field.type)
            "
            :type="field.type"
            :id="field.key"
            v-model="formData[field.key]"
            :required="field.required"
            :maxlength="field.maxlength"
            :min="field.min"
            :max="field.max"
            :step="field.step"
            :placeholder="field.placeholder"
            class="input w-full bg-primary-card text-text-primary placeholder-text-secondary border-gray-600 focus:border-accent-primary transition-colors duration-200"
          />

          <select
            v-else-if="field.type === 'select'"
            :id="field.key"
            :value="formData[field.key]"
            :required="field.required"
            class="input w-full bg-primary-card text-text-primary border-gray-600 focus:border-accent-primary transition-colors duration-200"
            @change="handleFieldChange(field, $event.target.value)"
          >
            <option value="" disabled>
              {{ field.placeholder || "Select an option" }}
            </option>
            <option
              v-for="option in field.options"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>

          <vue-multiselect
            v-else-if="field.type === 'multiselect'"
            v-model="formData[field.key]"
            :options="field.options"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="field.placeholder || 'Select option(s)'"
            label="label"
            track-by="value"
            @input="handleFieldChange(field, $event)"
            class="form-multiselect"
          >
            <template #selection="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">
                {{ values.length }} track(s) selected
              </span>
            </template>
          </vue-multiselect>

          <textarea
            v-else-if="field.type === 'textarea'"
            :id="field.key"
            v-model="formData[field.key]"
            :required="field.required"
            :rows="field.rows || 3"
            :placeholder="field.placeholder"
            class="input w-full bg-primary-card text-text-primary placeholder-text-secondary border-gray-600 focus:border-accent-primary transition-colors duration-200"
          ></textarea>
          <div v-else-if="field.type === 'file'" class="mt-1 flex items-center">
            <span
              class="inline-block h-12 w-12 rounded-full overflow-hidden bg-primary-card"
            >
              <img
                v-if="formData[field.key]"
                :src="getImagePreview(formData[field.key])"
                :alt="field.label"
                class="h-full w-full object-cover"
              />
              <span
                v-else
                class="h-full w-full flex items-center justify-center text-text-secondary"
              >
                <i class="fas fa-user-circle text-3xl"></i>
              </span>
            </span>
            <label
              :for="field.key"
              class="ml-5 btn btn-secondary cursor-pointer"
            >
              Change
            </label>
            <input
              :id="field.key"
              type="file"
              @change="handleFileUpload($event, field.key)"
              :accept="field.accept"
              class="sr-only"
            />
          </div>

          <div v-else-if="field.type === 'checkbox'" class="mt-2">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                :id="field.key"
                v-model="formData[field.key]"
                class="form-checkbox h-5 w-5 text-accent-primary rounded border-gray-600 focus:ring-accent-primary focus:ring-offset-primary-bg transition-colors duration-200"
              />
              <span class="ml-2 text-sm text-text-secondary">{{
                field.checkboxLabel || field.label
              }}</span>
            </label>
          </div>

          <span
            v-if="field.icon"
            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-text-secondary"
          >
            <i :class="['fas', field.icon]"></i>
          </span>
        </div>

        <p v-if="field.helpText" class="mt-2 text-sm text-text-secondary">
          {{ field.helpText }}
        </p>
      </div>
    </div>

    <div class="flex justify-end space-x-3 mt-8">
      <button type="button" @click="$emit('cancel')" class="btn btn-secondary">
        <i class="fas fa-times mr-2"></i>
        {{ cancelButtonText }}
      </button>
      <button type="submit" :disabled="isSubmitting" class="btn btn-primary">
        <i v-if="isSubmitting" class="fas fa-spinner fa-spin mr-2"></i>
        <i v-else class="fas fa-check mr-2"></i>
        {{ submitButtonText }}
      </button>
    </div>
  </form>
</template>

<script>
import { ref, watch } from "vue";
import VueMultiselect from "vue-multiselect";

export default {
  name: "DynamicForm",
  components: {
    VueMultiselect,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    initialData: {
      type: Object,
      default: () => ({}),
    },
    submitButtonText: {
      type: String,
      default: "Submit",
    },
    cancelButtonText: {
      type: String,
      default: "Cancel",
    },
  },
  emits: ["submit", "cancel", "field-change"],
  setup(props, { emit }) {
    const formData = ref({});
    const isSubmitting = ref(false);

    const initializeFormData = () => {
      formData.value = { ...props.initialData };
      props.fields.forEach((field) => {
        if (!(field.key in formData.value)) {
          formData.value[field.key] = field.type === "multiselect" ? [] : "";
        }
      });
    };

    watch(() => props.initialData, initializeFormData, { deep: true });
    watch(() => props.fields, initializeFormData, { deep: true });

    const handleFieldChange = (field, value) => {
      formData.value[field.key] = value;
      emit("field-change", { key: field.key, value });
    };

    // Add this watch
    watch(
      () => formData.value.artist_id,
      (newArtistId) => {
        if (newArtistId) {
          handleFieldChange({ key: "artist_id" }, newArtistId);
        }
      }
    );
    const handleSubmit = async () => {
      if (isSubmitting.value) return;

      isSubmitting.value = true;
      try {
        await emit("submit", formData.value);
      } catch (error) {
        console.error("Form submission error:", error);
      } finally {
        isSubmitting.value = false;
      }
    };

    initializeFormData();

    return {
      formData,
      isSubmitting,
      handleSubmit,
      handleFieldChange,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.form-multiselect {
  @apply w-full;
}
.form-field label {
  @apply block text-sm font-medium text-text-secondary mb-1;
}

.form-field .help-text {
  @apply mt-2 text-sm text-text-secondary;
}

.btn {
  @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-bg transition-colors duration-200 ease-in-out;
}

.btn-primary {
  @apply text-white bg-accent-primary hover:bg-accent-primary-dark focus:ring-accent-primary;
}

.btn-secondary {
  @apply text-text-primary bg-primary-card hover:bg-primary-lighter focus:ring-accent-secondary;
}

.input {
  @apply block w-full px-3 py-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-accent-primary sm:text-sm transition-colors duration-200 ease-in-out;
}

.btn:disabled {
  @apply opacity-50 cursor-not-allowed;
}

/* Dark mode styles */
.dark .bg-primary-lighter {
  @apply bg-primary-darker;
}

.dark .bg-primary-card {
  @apply bg-primary-darkest;
}

.dark .border-gray-600 {
  @apply border-gray-700;
}

.dark .text-text-secondary {
  @apply text-gray-400;
}

.dark .form-checkbox {
  @apply bg-primary-darkest;
}
</style>

<style>
/* Vue Multiselect Custom Styles */
.multiselect.multiselect.form-multiselect {
  @apply bg-primary-bg border-none;
}
.multiselect {
  @apply bg-primary-card text-text-primary border border-gray-600 rounded-md shadow-sm transition-colors duration-200;
}

.multiselect__tags {
  @apply bg-primary-card border-none min-h-[38px] py-1 px-3;
}

.multiselect__tag {
  @apply bg-accent-primary text-white;
}

.multiselect__option--highlight {
  @apply bg-accent-primary text-white;
}

.multiselect__option--selected.multiselect__option--highlight {
  @apply bg-accent-secondary;
}

.multiselect__placeholder {
  @apply text-text-secondary;
}

.multiselect__input,
.multiselect__single {
  @apply bg-primary-card text-text-primary placeholder-text-secondary;
}

.multiselect__content-wrapper {
  @apply bg-primary-card border border-gray-600;
}

/* Dark mode styles */
.dark .multiselect__tags {
  @apply bg-primary-darkest;
}

.dark .multiselect__input,
.dark .multiselect__single {
  @apply bg-primary-darkest;
}

.dark .multiselect__content-wrapper {
  @apply bg-primary-darkest border-gray-700;
}
</style>
