<template>
  <div class="min-h-screen bg-primary-bg text-text-primary">
    <main>
      <!-- Hero Section -->
      <section
        class="hero py-24 w-full bg-gradient-to-br from-primary-bg to-primary-lighter"
      >
        <div
          class="container mx-auto px-4 flex flex-col lg:flex-row justify-between items-center"
        >
          <div class="hero-content lg:w-1/2 mb-8 lg:mb-0">
            <h1
              class="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 text-gradient"
            >
              ArtHead: Your Music Management Hub
            </h1>
            <p class="text-xl mb-8 text-text-secondary">{{ welcomeMessage }}</p>
            <div v-if="!isAuthenticated" class="flex space-x-4">
              <router-link to="/login" class="btn btn-primary">
                <i class="fas fa-sign-in-alt mr-2"></i>Sign In
              </router-link>
              <router-link to="/company-signup" class="btn btn-secondary">
                <i class="fas fa-user-plus mr-2"></i>Company SignUp
              </router-link>
            </div>
            <div v-else>
              <router-link to="/dashboard" class="btn btn-primary">
                <i class="fas fa-tachometer-alt mr-2"></i>Go to Dashboard
              </router-link>
            </div>
          </div>
          <div class="hero-image lg:w-1/2">
            <img
              src="@/assets/images/music-studio.avif"
              alt="Music Studio"
              class="rounded-lg shadow-xl w-full transform hover:scale-105 transition-transform duration-300"
            />
          </div>
        </div>
      </section>

      <!-- Features Section -->
      <section id="features" class="features py-16 bg-primary-card w-full">
        <div class="container mx-auto px-4">
          <h2
            class="text-3xl md:text-4xl font-bold mb-12 text-center text-gradient"
          >
            Powerful Tools for Your Music Business
          </h2>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div
              v-for="feature in features"
              :key="feature.title"
              class="feature bg-primary-lighter rounded-lg p-6 shadow-lg transform hover:scale-105 transition-all duration-300"
            >
              <i
                :class="[feature.icon, 'text-4xl mb-4 text-accent-primary']"
              ></i>
              <h3 class="text-2xl font-bold mb-2 text-text-primary">
                {{ feature.title }}
              </h3>
              <p class="text-text-secondary">{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Quick Actions Section (Only for logged-in users) -->
      <section
        v-if="isAuthenticated"
        id="quick-actions"
        class="py-16 w-full bg-gradient-to-br from-primary-bg to-primary-lighter"
      >
        <div class="container mx-auto px-4">
          <h2
            class="text-3xl md:text-4xl font-bold mb-8 text-center text-gradient"
          >
            Quick Actions
          </h2>
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <router-link
              v-for="action in quickActions"
              :key="action.title"
              :to="action.link"
              class="bg-primary-card rounded-lg p-6 text-center hover:bg-primary-lighter transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
            >
              <i
                :class="[action.icon, 'text-3xl mb-2 text-accent-secondary']"
              ></i>
              <h3 class="text-xl font-bold text-text-primary">
                {{ action.title }}
              </h3>
            </router-link>
          </div>
        </div>
      </section>

      <!-- Recent Activity Section (Only for logged-in users) -->
      <section
        v-if="isAuthenticated"
        id="recent-activity"
        class="py-16 bg-primary-card w-full"
      >
        <div class="container mx-auto px-4">
          <h2
            class="text-3xl md:text-4xl font-bold mb-8 text-center text-gradient"
          >
            Recent Activity
          </h2>
          <ul class="space-y-4">
            <li
              v-for="activity in recentActivities"
              :key="activity.id"
              class="bg-primary-bg rounded-lg p-4 flex items-center transition-all duration-300 hover:shadow-md"
            >
              <i
                :class="[activity.icon, 'text-2xl mr-4 text-accent-primary']"
              ></i>
              <div>
                <p class="font-bold text-text-primary">{{ activity.title }}</p>
                <p class="text-sm text-text-secondary">
                  {{ activity.timestamp }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <!-- About Section -->
      <section
        id="about"
        class="about py-16 w-full bg-gradient-to-br from-primary-bg to-primary-lighter"
      >
        <div
          class="container mx-auto px-4 flex flex-col lg:flex-row justify-between items-center"
        >
          <div class="about-image lg:w-1/2 mb-8 lg:mb-0">
            <img
              src="@/assets/images/about-arthead.avif"
              alt="About ArtHead"
              class="rounded-lg shadow-xl w-full transform hover:scale-105 transition-transform duration-300"
            />
          </div>
          <div class="about-content lg:w-1/2 lg:pl-12">
            <h2 class="text-3xl md:text-4xl font-bold mb-4 text-gradient">
              About ArtHead
            </h2>
            <p class="text-xl mb-4 text-text-secondary">
              ArtHead is your comprehensive label and artist management
              platform, designed to streamline your music business operations.
            </p>
            <p class="text-xl mb-8 text-text-secondary">
              Our mission is to empower you with the tools you need to succeed
              in the ever-evolving music industry, allowing you to focus on what
              matters most - the music.
            </p>
            <a
              href="#"
              class="text-accent-secondary font-bold hover:text-accent-primary transition-colors duration-200"
              >Learn More About ArtHead</a
            >
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ArtheadView",
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters("auth", ["isAuthenticated"]),
    welcomeMessage() {
      return this.isAuthenticated
        ? `Welcome back, ${this.user ? this.user.name : ""}!`
        : "Welcome to ArtHead!";
    },
  },
  data() {
    return {
      features: [
        {
          icon: "fas fa-users",
          title: "Artist Management",
          description:
            "Manage your roster of artists, their profiles, and performances.",
        },
        {
          icon: "fas fa-calendar-alt",
          title: "Release Planning",
          description:
            "Plan and track your music releases with our intuitive calendar.",
        },
        {
          icon: "fas fa-chart-line",
          title: "Analytics & Reporting",
          description:
            "Gain insights into your label's performance with detailed analytics.",
        },
        {
          icon: "fas fa-file-contract",
          title: "Contract Management",
          description:
            "Easily manage and track artist contracts and agreements.",
        },
        {
          icon: "fas fa-money-bill-wave",
          title: "Royalty Tracking",
          description:
            "Accurately track and manage royalties for your artists and releases.",
        },
        {
          icon: "fas fa-tasks",
          title: "Task Management",
          description:
            "Stay organized with built-in task management and collaboration tools.",
        },
      ],
      quickActions: [
        { icon: "fas fa-plus", title: "Add Artist", link: "/artists" },
        { icon: "fas fa-music", title: "New Release", link: "/releases" },
        {
          icon: "fas fa-calendar-plus",
          title: "Schedule Event",
          link: "/releases-calendar",
        },
        {
          icon: "fas fa-file-alt",
          title: "Create Report",
          link: "/dashboard",
        },
      ],
      recentActivities: [
        {
          id: 1,
          icon: "fas fa-user-plus",
          title: "New artist added: John Doe",
          timestamp: "2 hours ago",
        },
        {
          id: 2,
          icon: "fas fa-compact-disc",
          title: "Release scheduled: 'Summer Hits'",
          timestamp: "Yesterday",
        },
        {
          id: 3,
          icon: "fas fa-file-signature",
          title: "Contract signed with Jane Smith",
          timestamp: "3 days ago",
        },
        {
          id: 4,
          icon: "fas fa-chart-bar",
          title: "Monthly report generated",
          timestamp: "1 week ago",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("auth/checkAuth");
  },
};
</script>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-accent-primary to-accent-secondary;
}

.feature i {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-accent-primary to-accent-secondary;
}

.btn {
  @apply font-bold py-3 px-6 rounded-lg transition-colors duration-300 inline-block;
}

.btn-primary {
  @apply bg-accent-primary text-white hover:bg-opacity-90 focus:ring-accent-primary;
}

.btn-secondary {
  @apply bg-primary-lighter text-text-primary hover:bg-primary-card focus:ring-accent-secondary;
}

.hero,
.about {
  background-image: radial-gradient(
    circle at top right,
    var(--color-primary-lighter) 0%,
    var(--color-primary-bg) 100%
  );
}

.features {
  background-image: linear-gradient(
    to bottom right,
    var(--color-primary-card) 0%,
    var(--color-primary-bg) 100%
  );
}

@media (max-width: 768px) {
  .hero-content,
  .hero-image,
  .about-image,
  .about-content {
    @apply w-full;
  }
}

/* Dark mode styles */
.dark .bg-primary-bg {
  @apply bg-gray-900;
}

.dark .bg-primary-lighter {
  @apply bg-gray-800;
}

.dark .bg-primary-card {
  @apply bg-gray-800;
}

.dark .text-text-primary {
  @apply text-gray-100;
}

.dark .text-text-secondary {
  @apply text-gray-300;
}
</style>
