import api from "@/services/api";

export default {
  namespaced: true,
  state: {
    organizationsList: [],
  },
  mutations: {
    SET_ORGANIZATIONS_LIST(state, organizations) {
      state.organizationsList = organizations;
    },
  },
  actions: {
    async fetchOrganizationsList({ commit }) {
      try {
        const response = await api.getOrganizationsList();
        commit("SET_ORGANIZATIONS_LIST", response.data);
      } catch (error) {
        console.error("Error fetching organizations list:", error);
      }
    },
  },
  getters: {
    getOrganizationsList: (state) => state.organizationsList,
  },
};
