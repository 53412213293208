import api from "@/services/api";

export default {
  namespaced: true,
  state: {
    currentCompany: null,
    companyUsers: [],
    pendingInvitations: [],
    loadingStates: {}, // Track loading states for different operations
  },
  mutations: {
    SET_CURRENT_COMPANY(state, company) {
      state.currentCompany = company;
    },
    SET_COMPANY_USERS(state, users) {
      state.companyUsers = users;
    },
    SET_PENDING_INVITATIONS(state, invitations) {
      state.pendingInvitations = invitations;
    },
    REMOVE_PENDING_INVITATION(state, userId) {
      state.pendingInvitations = state.pendingInvitations.filter(
        (inv) => inv.id !== userId
      );
    },
    SET_LOADING_STATE(state, { operation, id, isLoading }) {
      state.loadingStates = {
        ...state.loadingStates,
        [`${operation}_${id}`]: isLoading,
      };
    },
  },
  actions: {
    async fetchCurrentCompany({ commit }) {
      try {
        const response = await api.getCurrentCompany();
        commit("SET_CURRENT_COMPANY", response.data);
      } catch (error) {
        console.error("Error fetching current company:", error);
        throw error;
      }
    },
    async fetchCompanyUsers({ commit }) {
      try {
        const response = await api.getCompanyUsers();
        commit("SET_COMPANY_USERS", response.data);
      } catch (error) {
        console.error("Error fetching company users:", error);
        throw error;
      }
    },

    async inviteUser({ dispatch }, userData) {
      try {
        await api.inviteUser(userData);
        await dispatch("fetchCompanyUsers");
      } catch (error) {
        console.error("Error inviting user:", error);
        throw error;
      }
    },
    async fetchPendingInvitations({ commit }) {
      try {
        const response = await api.getPendingInvitations();
        commit("SET_PENDING_INVITATIONS", response.data);
      } catch (error) {
        console.error("Error fetching pending invitations:", error);
        throw error;
      }
    },
    async resendInvitation({ dispatch }, userId) {
      try {
        await api.resendInvitation(userId);
        await dispatch("fetchPendingInvitations");
      } catch (error) {
        console.error("Error resending invitation:", error);
        throw error;
      }
    },
    async cancelInvitation({ commit, dispatch }, userId) {
      commit("SET_LOADING_STATE", {
        operation: "cancel_invitation",
        id: userId,
        isLoading: true,
      });

      try {
        await api.cancelInvitation(userId);
        commit("REMOVE_PENDING_INVITATION", userId);
        dispatch(
          "notifications/addNotification",
          {
            type: "success",
            message: "Invitation cancelled successfully",
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          "notifications/addNotification",
          {
            type: "error",
            message:
              error.response?.data?.error || "Failed to cancel invitation",
          },
          { root: true }
        );
        throw error;
      } finally {
        commit("SET_LOADING_STATE", {
          operation: "cancel_invitation",
          id: userId,
          isLoading: false,
        });
      }
    },

    async removeUser({ dispatch }, userId) {
      try {
        await api.removeUser(userId);
        await dispatch("fetchCompanyUsers");
      } catch (error) {
        console.error("Error removing user:", error);
        throw error;
      }
    },
    async toggleUserStatus({ dispatch }, userId) {
      try {
        await api.toggleUserStatus(userId);
        await dispatch("fetchCompanyUsers");
      } catch (error) {
        console.error("Error toggling user status:", error);
        throw error;
      }
    },
  },
  getters: {
    currentCompany: (state) => state.currentCompany,
    companyUsers: (state) => state.companyUsers,
    isAdmin: (state, getters, rootState) => {
      return rootState.auth.user && rootState.auth.user.role === "admin";
    },
    isOperationLoading: (state) => (operation, id) => {
      return !!state.loadingStates[`${operation}_${id}`];
    },
  },
};
