<template>
  <transition
    enter-active-class="ease-out duration-300"
    enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
    leave-active-class="ease-in duration-200"
    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  >
    <div
      v-if="isOpen"
      class="fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          @click="closeOnBackdrop && close()"
        ></div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <div
          ref="modalContent"
          :class="[
            'inline-block align-bottom bg-primary-lighter rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full',
            'border border-gray-700',
            computedSize,
            { 'mx-auto': props.size === 'full' || props.size === '2xl' },
          ]"
        >
          <div class="flex flex-col max-h-[90vh]">
            <div
              class="flex justify-between items-center px-6 py-4 border-b border-gray-700"
            >
              <h3
                class="text-xl font-semibold text-text-primary text-gradient"
                id="modal-title"
              >
                <slot name="header"></slot>
              </h3>
              <button
                v-if="showCloseButton"
                @click="close"
                class="text-gray-400 hover:text-text-primary hover:ring-2 hover:ring-accent-primary focus:outline-none rounded-full w-10 p-2 transition-colors duration-200"
              >
                <span class="sr-only">Close</span>
                <i class="fas fa-times text-xl"></i>
              </button>
            </div>
            <div
              ref="modalBody"
              :class="[
                'px-6 py-4 overflow-y-auto',
                // {
                //   'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6':
                //     useHorizontalLayout,
                // },
              ]"
            >
              <slot name="body"></slot>
            </div>
            <div
              v-if="$slots.footer"
              class="px-6 py-4 border-t border-gray-700 flex justify-end space-x-3 bg-primary-card"
            >
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";

export default {
  name: "BaseModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    closeOnBackdrop: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "md",
      validator: (value) =>
        ["sm", "md", "lg", "xl", "2xl", "full"].includes(value),
    },
    maxHeight: {
      type: String,
      default: "90vh",
    },
    horizontalPadding: {
      type: String,
      default: "px-6",
    },
    verticalPadding: {
      type: String,
      default: "py-4",
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const modalContent = ref(null);
    const modalBody = ref(null);

    const computedSize = computed(() => {
      const sizes = {
        sm: "sm:max-w-md",
        md: "sm:max-w-lg",
        lg: "sm:max-w-xl",
        xl: "sm:max-w-2xl",
        "2xl": "sm:max-w-4xl",
        full: "sm:max-w-[calc(100%-2rem)] sm:w-[calc(100%-2rem)]",
      };
      return sizes[props.size] || sizes.md;
    });

    const close = () => {
      emit("close");
    };

    onMounted(() => {
      document.addEventListener("keydown", handleKeyDown);
    });

    watch(
      () => props.isOpen,
      (newValue) => {
        if (newValue) {
          setTimeout(() => {
            modalContent.value
              ?.querySelector(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
              )
              ?.focus();
          }, 50);
        }
      }
    );

    const handleKeyDown = (event) => {
      if (event.key === "Escape" && props.isOpen) {
        close();
      }
    };

    return {
      close,
      modalContent,
      modalBody,
      computedSize,
      props,
    };
  },
};
</script>

<style scoped>
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) rgba(18, 18, 18, 0.5);
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  @apply bg-primary-bg bg-opacity-50;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  @apply bg-primary-lighter bg-opacity-50 rounded-full;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  @apply bg-accent-primary bg-opacity-50;
}

@keyframes modalPulse {
  0%,
  100% {
    box-shadow: 0 0 0 0 rgba(var(--color-accent-primary), 0.4);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(var(--color-accent-primary), 0);
  }
}

.modal-pulse {
  animation: modalPulse 2s infinite;
}

.text-gradient {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-accent-primary to-accent-secondary;
}
.modal-content {
  max-height: v-bind("props.maxHeight");
}
</style>
