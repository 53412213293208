<template>
  <footer
    class="bg-primary-bg text-text-secondary py-6 text-sm border-t border-gray-700"
  >
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div
        class="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0"
      >
        <!-- Quick Links -->
        <div class="text-center w-full md:w-1/3">
          <h3
            class="font-semibold mb-2 text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
          >
            Quick Links
          </h3>
          <div class="flex flex-wrap justify-center gap-4">
            <template v-if="isAuthenticated">
              <router-link
                v-for="link in authenticatedLinks"
                :key="link.to"
                :to="link.to"
                class="hover:text-accent-primary transition-colors duration-200"
              >
                {{ link.text }}
              </router-link>
            </template>
            <template v-else>
              <router-link
                v-for="link in unauthenticatedLinks"
                :key="link.to"
                :to="link.to"
                class="hover:text-accent-primary transition-colors duration-200"
              >
                {{ link.text }}
              </router-link>
            </template>
          </div>
        </div>

        <!-- Application Info -->
        <div class="text-center w-full md:w-1/3">
          <h3
            class="font-semibold mb-2 text-text-primary text-gradient bg-gradient-to-r from-accent-secondary to-accent-tertiary"
          >
            Arthead
          </h3>
          <p class="leading-relaxed mx-auto max-w-xs">
            Comprehensive label and artist management system for the modern
            music industry.
          </p>
        </div>

        <!-- Contact Info -->
        <div class="text-center w-full md:w-1/3">
          <h3
            class="font-semibold mb-2 text-text-primary text-gradient bg-gradient-to-r from-accent-tertiary to-accent-quaternary"
          >
            Contact Us
          </h3>
          <a
            href="mailto:info@arthead.com"
            class="hover:text-accent-primary transition-colors duration-200"
          >
            info@arthead.com
          </a>
          <div class="flex justify-center space-x-4 mt-2">
            <a
              v-for="social in socialLinks"
              :key="social.name"
              :href="social.url"
              class="text-text-secondary hover:text-accent-primary transition-colors duration-200"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i :class="['fab', social.icon, 'text-lg']"></i>
              <span class="sr-only">{{ social.name }}</span>
            </a>
          </div>
        </div>
      </div>

      <!-- Copyright -->
      <div class="border-t border-gray-700 mt-6 pt-6 text-center">
        <p class="text-text-secondary text-xs">
          &copy; {{ new Date().getFullYear() }} Arthead. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      authenticatedLinks: [
        { to: "/", text: "Home" },
        { to: "/artists", text: "Artists" },
        { to: "/releases", text: "Releases" },
        { to: "/contacts", text: "Contacts" },
        { to: "/organizations", text: "Organizations" },
      ],
      unauthenticatedLinks: [
        { to: "/", text: "Home" },
        { to: "/login", text: "Login" },
        { to: "/company-signup", text: "Company Signup" },
      ],
      socialLinks: [
        { name: "Instagram", icon: "fa-instagram", url: "#" },
        { name: "Twitter", icon: "fa-twitter", url: "#" },
        { name: "Facebook", icon: "fa-facebook-f", url: "#" },
      ],
    };
  },
};
</script>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.bg-primary-card {
  background-color: var(--color-primary-card);
}

.text-text-primary {
  color: var(--color-text-primary);
}

.text-text-secondary {
  color: var(--color-text-secondary);
}

.hover\:text-accent-primary:hover {
  color: var(--color-accent-primary);
}

.border-gray-700 {
  border-color: var(--color-primary-lighter);
}

@media (max-width: 768px) {
  .flex-col {
    flex-direction: column;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

/* Dark mode styles */
.dark .bg-primary-card {
  background-color: var(--color-primary-darker);
}

.dark .text-text-secondary {
  color: var(--color-text-secondary);
}

.dark .border-gray-700 {
  border-color: var(--color-primary-card);
}
</style>
