<template>
  <div class="space-y-6">
    <!-- Stats Cards -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div
        v-for="stat in invitationStats"
        :key="stat.title"
        class="bg-primary-card p-6 rounded-lg shadow-lg border border-gray-700 hover:border-accent-primary transition-all duration-300"
      >
        <div class="flex items-center justify-between">
          <div>
            <p class="text-sm text-text-secondary">{{ stat.title }}</p>
            <p class="mt-2 text-3xl font-semibold text-text-primary">
              {{ stat.value }}
            </p>
          </div>
          <div
            class="w-12 h-12 rounded-full flex items-center justify-center"
            :class="stat.bgColor"
          >
            <i :class="[stat.icon, 'text-xl', stat.iconColor]"></i>
          </div>
        </div>
        <p class="mt-2 text-sm text-text-secondary">{{ stat.subtext }}</p>
      </div>
    </div>

    <!-- Pending Invitations Table -->
    <div
      class="bg-primary-card rounded-lg shadow-lg overflow-hidden border border-gray-700"
    >
      <div class="p-6 border-b border-gray-700">
        <div
          class="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4"
        >
          <h2 class="text-xl font-semibold text-text-primary">
            Invitation Details
          </h2>
          <div class="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
            <!-- Search Input -->
            <div class="relative flex-grow sm:flex-grow-0">
              <input
                type="text"
                v-model="searchQuery"
                placeholder="Search invitations..."
                class="w-full px-4 py-2 pl-10 bg-primary-lighter border border-gray-700 rounded-md text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
              />
              <i
                class="fas fa-search absolute left-3 top-1/2 -translate-y-1/2 text-text-secondary"
              ></i>
            </div>

            <!-- Filter Dropdown -->
            <select
              v-model="filterStatus"
              class="px-4 py-2 bg-primary-lighter border border-gray-700 rounded-md text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
            >
              <option value="all">All Invitations</option>
              <option value="active">Active</option>
              <option value="expired">Expired</option>
            </select>

            <!-- Refresh Button -->
            <button
              @click="refreshInvitations"
              :disabled="isRefreshing"
              class="px-4 py-2 bg-primary-lighter border border-gray-700 rounded-md text-text-primary hover:bg-primary-card focus:outline-none focus:ring-2 focus:ring-accent-primary transition-all duration-200 flex items-center gap-2"
            >
              <i
                class="fas fa-sync-alt"
                :class="{ 'animate-spin': isRefreshing }"
              ></i>
              <span class="hidden sm:inline">Refresh</span>
            </button>
          </div>
        </div>
      </div>

      <div class="overflow-x-auto">
        <table class="w-full">
          <thead class="bg-primary-darker">
            <tr>
              <th
                v-for="header in tableHeaders"
                :key="header.key"
                class="px-6 py-4 text-left text-sm font-semibold text-text-primary"
              >
                {{ header.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="invitation in filteredInvitations"
              :key="invitation.id"
              class="border-b border-primary-darker hover:bg-primary-lighter transition-colors duration-200"
            >
              <td class="px-6 py-4">
                <div class="flex items-center gap-3">
                  <div
                    class="w-8 h-8 rounded-full bg-accent-primary flex items-center justify-center text-white"
                  >
                    {{ getInitials(invitation.email) }}
                  </div>
                  <div>
                    <div class="font-medium text-text-primary">
                      {{ invitation.email }}
                    </div>
                    <div class="text-sm text-text-secondary">
                      {{ invitation.role }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="px-6 py-4 text-text-secondary">
                <div class="flex items-center gap-2">
                  <i class="fas fa-clock text-text-secondary"></i>
                  {{ formatTimeAgo(invitation.invitation_sent_at) }}
                </div>
              </td>
              <td class="px-6 py-4">
                <div class="flex items-center gap-2">
                  <span
                    :class="[
                      'px-2 py-1 rounded-full text-xs font-medium',
                      isExpired(invitation.invitation_expires_at)
                        ? 'bg-red-100 text-red-800'
                        : 'bg-green-100 text-green-800',
                    ]"
                  >
                    {{
                      isExpired(invitation.invitation_expires_at)
                        ? "Expired"
                        : "Active"
                    }}
                  </span>
                </div>
              </td>
              <td class="px-6 py-4 text-text-secondary">
                {{ invitation.invitation_attempts }}
              </td>
              <td class="px-6 py-4">
                <div class="flex items-center gap-2">
                  <button
                    @click="handleResend(invitation)"
                    :disabled="
                      !isExpired(invitation.invitation_expires_at) ||
                      isResending[invitation.id]
                    "
                    class="flex items-center gap-2 px-3 py-1.5 rounded-md text-sm font-medium transition-all duration-200"
                    :class="getActionButtonClass(invitation)"
                  >
                    <i
                      :class="[
                        'fas',
                        isResending[invitation.id]
                          ? 'fa-spinner animate-spin'
                          : 'fa-paper-plane',
                      ]"
                    ></i>
                    {{ isResending[invitation.id] ? "Sending..." : "Resend" }}
                  </button>
                  <button
                    @click="showCancelModal(invitation)"
                    class="flex items-center gap-2 px-3 py-1.5 bg-red-100 text-red-800 hover:bg-red-200 rounded-md text-sm font-medium"
                  >
                    <i class="fas fa-times"></i>
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Empty State -->
        <div
          v-if="filteredInvitations.length === 0"
          class="p-12 text-center text-text-secondary"
        >
          <i class="fas fa-inbox text-4xl mb-4"></i>
          <p class="text-lg">No pending invitations found</p>
          <p class="mt-2 text-sm">
            {{ getEmptyStateMessage() }}
          </p>
        </div>
      </div>
    </div>

    <!-- Cancel Invitation Modal -->
    <BaseModal
      :is-open="showCancelConfirmation"
      @close="closeCancelModal"
      size="sm"
    >
      <template #header>Cancel Invitation</template>
      <template #body>
        <p class="text-text-primary">
          Are you sure you want to cancel the invitation for
          <span class="font-semibold">{{ selectedInvitation?.email }}</span
          >?
        </p>
        <p class="mt-2 text-sm text-text-secondary">
          This action cannot be undone.
        </p>
      </template>
      <template #footer>
        <button
          @click="closeCancelModal"
          class="px-4 py-2 text-text-secondary hover:text-text-primary transition-colors duration-200"
        >
          Keep Invitation
        </button>
        <button
          @click="handleCancelInvitation"
          :disabled="isCancelling"
          class="px-4 py-2 bg-error text-white rounded-md hover:bg-red-600 transition-colors duration-200 disabled:opacity-50 flex items-center gap-2"
        >
          <i
            :class="[
              'fas',
              isCancelling ? 'fa-spinner animate-spin' : 'fa-times',
            ]"
          ></i>
          {{ isCancelling ? "Cancelling..." : "Cancel Invitation" }}
        </button>
      </template>
    </BaseModal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { formatDistanceToNow } from "date-fns";
import BaseModal from "@/components/BaseModal.vue";

const store = useStore();
const searchQuery = ref("");
const filterStatus = ref("all");
const isRefreshing = ref(false);
const isResending = ref({});
const isCancelling = ref(false);
const showCancelConfirmation = ref(false);
const selectedInvitation = ref(null);

const tableHeaders = [
  { key: "user", label: "User" },
  { key: "sent", label: "Sent" },
  { key: "status", label: "Status" },
  { key: "attempts", label: "Attempts" },
  { key: "actions", label: "Actions" },
];

const invitationStats = computed(() => {
  const pendingInvitations = store.state.company.pendingInvitations;
  const total = pendingInvitations.length;
  const expired = pendingInvitations.filter((inv) =>
    isExpired(inv.invitation_expires_at)
  ).length;
  const active = total - expired;
  // const isInvitationCancelling = computed(() =>
  //   store.getters['company/isOperationLoading']('cancel_invitation', selectedInvitation.value?.id)
  // );
  return [
    {
      title: "Total Invitations",
      value: total,
      icon: "fas fa-envelope",
      bgColor: "bg-blue-100",
      iconColor: "text-blue-600",
      subtext: "All pending invitations",
    },
    {
      title: "Active Invitations",
      value: active,
      icon: "fas fa-clock",
      bgColor: "bg-green-100",
      iconColor: "text-green-600",
      subtext: "Valid and awaiting acceptance",
    },
    {
      title: "Expired Invitations",
      value: expired,
      icon: "fas fa-history",
      bgColor: "bg-yellow-100",
      iconColor: "text-yellow-600",
      subtext: "Requires resending",
    },
  ];
});

const filteredInvitations = computed(() => {
  return store.state.company.pendingInvitations
    .filter((invitation) => {
      const matchesSearch = invitation.email
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase());
      const matchesFilter =
        filterStatus.value === "all" ||
        (filterStatus.value === "active" &&
          !isExpired(invitation.invitation_expires_at)) ||
        (filterStatus.value === "expired" &&
          isExpired(invitation.invitation_expires_at));
      return matchesSearch && matchesFilter;
    })
    .sort(
      (a, b) => new Date(b.invitation_sent_at) - new Date(a.invitation_sent_at)
    );
});

const getInitials = (email) => {
  return email
    .split("@")[0]
    .split(/[._-]/)
    .map((part) => part[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);
};

const formatTimeAgo = (date) => {
  if (!date) return "N/A";
  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

const isExpired = (expiryDate) => {
  if (!expiryDate) return true;
  return new Date() > new Date(expiryDate);
};

const getActionButtonClass = (invitation) => {
  if (isResending.value[invitation.id]) {
    return "bg-gray-100 text-gray-800 cursor-not-allowed";
  }
  if (!isExpired(invitation.invitation_expires_at)) {
    return "bg-gray-100 text-gray-800 cursor-not-allowed";
  }
  return "bg-accent-primary text-white hover:bg-accent-secondary";
};

const getEmptyStateMessage = () => {
  if (searchQuery.value) {
    return "No invitations match your search criteria";
  }
  if (filterStatus.value !== "all") {
    return `No ${filterStatus.value} invitations found`;
  }
  return "Invite new users to get started";
};

const refreshInvitations = async () => {
  isRefreshing.value = true;
  try {
    await store.dispatch("company/fetchPendingInvitations");
  } catch (error) {
    console.error("Error refreshing invitations:", error);
  } finally {
    isRefreshing.value = false;
  }
};

const handleResend = async (invitation) => {
  if (isResending.value[invitation.id]) return;

  isResending.value = { ...isResending.value, [invitation.id]: true };
  try {
    await store.dispatch("company/resendInvitation", invitation.id);
    await store.dispatch("company/fetchPendingInvitations");
  } catch (error) {
    console.error("Error resending invitation:", error);
  } finally {
    isResending.value = { ...isResending.value, [invitation.id]: false };
  }
};

const showCancelModal = (invitation) => {
  selectedInvitation.value = invitation;
  showCancelConfirmation.value = true;
};

const closeCancelModal = () => {
  showCancelConfirmation.value = false;
  selectedInvitation.value = null;
};

const handleCancelInvitation = async () => {
  if (!selectedInvitation.value || isCancelling.value) return;

  isCancelling.value = true;
  try {
    await store.dispatch(
      "company/cancelInvitation",
      selectedInvitation.value.id
    );
    await store.dispatch("company/fetchPendingInvitations");
    closeCancelModal();
  } catch (error) {
    console.error("Error cancelling invitation:", error);
  } finally {
    isCancelling.value = false;
  }
};

onMounted(() => {
  store.dispatch("company/fetchPendingInvitations");
});
</script>

<style scoped>
.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
