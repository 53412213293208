<template>
  <div class="p-6 bg-primary-bg min-h-screen">
    <h1
      class="text-3xl font-semibold text-text-primary mb-6 text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
    >
      Contracts
    </h1>
    <!-- Contract Statistics -->
    <div class="mb-8 bg-primary-card rounded-lg shadow-md p-6">
      <h2 class="text-xl font-semibold mb-4 text-text-primary">
        Contract Overview
      </h2>
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <StatCard
          title="Total Contracts"
          :value="totalContracts"
          icon="fa-file-contract"
          color="accent-primary"
        />
        <StatCard
          title="Active Contracts"
          :value="activeContracts"
          icon="fa-check-circle"
          color="accent-secondary"
        />
        <StatCard
          title="Expiring Soon"
          :value="expiringSoonContracts"
          icon="fa-exclamation-circle"
          color="warning"
        />
      </div>
    </div>
    <!-- Search and Filter Bar -->
    <div class="mb-6 flex flex-wrap gap-4 items-center">
      <div class="relative flex-grow">
        <div
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        >
          <i class="fas fa-search text-gray-400"></i>
        </div>
        <input
          v-model="searchQuery"
          type="text"
          placeholder="Search contracts..."
          class="w-full pl-10 py-3 rounded-lg bg-primary-lighter border border-primary-darker focus:outline-none focus:ring-2 focus:ring-accent-primary transition-all duration-300 ease-in-out"
        />
      </div>
      <button
        @click="showFilters = !showFilters"
        class="btn btn-primary flex items-center gap-2"
      >
        <i
          :class="showFilters ? 'fa-filter' : 'fa-filter-slash'"
          class="fas"
        ></i>
        Filter
      </button>
      <button @click="showAddContractModal = true" class="btn btn-primary">
        <i class="fas fa-plus mr-2"></i>Add New Contract
      </button>
    </div>
    <!-- Filter Section -->
    <div v-if="showFilters" class="mb-6">
      <div
        class="bg-primary-card rounded-lg shadow-md p-6 transition-all duration-300 ease-in-out hover:shadow-lg"
      >
        <h3 class="text-lg font-semibold text-text-primary mb-4">Filters</h3>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div class="flex flex-col">
            <label
              for="status-filter"
              class="text-sm font-medium text-text-secondary mb-1"
              >Status:</label
            >
            <select
              id="status-filter"
              v-model="statusFilter"
              class="py-2 px-3 rounded-md bg-primary-lighter border border-primary-darker focus:outline-none focus:ring-2 focus:ring-accent-primary"
            >
              <option value="">All</option>
              <option
                v-for="option in statusOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div class="flex flex-col">
            <label
              for="contract-status-filter"
              class="text-sm font-medium text-text-secondary mb-1"
              >Contract Status:</label
            >
            <select
              id="contract-status-filter"
              v-model="contractStatusFilter"
              class="py-2 px-3 rounded-md bg-primary-lighter border border-primary-darker focus:outline-none focus:ring-2 focus:ring-accent-primary"
            >
              <option value="">All</option>
              <option
                v-for="option in contractStatusOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>

          <div class="flex flex-col">
            <label
              for="contract-type-filter"
              class="text-sm font-medium text-text-secondary mb-1"
              >Contract Type:</label
            >
            <select
              id="contract-type-filter"
              v-model="contractTypeFilter"
              class="py-2 px-3 rounded-md bg-primary-lighter border border-primary-darker focus:outline-none focus:ring-2 focus:ring-accent-primary"
            >
              <option value="">All</option>
              <option
                v-for="option in contractTypeOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-4 gap-8">
      <!-- Contract Cards -->
      <div class="lg:col-span-4">
        <div
          v-if="filteredContracts.length"
          class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6"
        >
          <ContractCard
            v-for="contract in filteredContracts"
            :key="contract.id"
            :contract="contract"
            @view="viewContract"
            @edit="editContract"
            @delete="confirmDeleteContract"
          />
        </div>
        <div v-else class="text-center text-text-secondary py-8">
          No contracts found matching your criteria.
        </div>
      </div>
    </div>

    <!-- Modals -->
    <BaseModal :is-open="showAddContractModal" @close="closeAddModal" size="xl">
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          Add New Contract
        </h2>
      </template>
      <template #body>
        <DynamicForm
          :fields="contractFormFields"
          :initial-data="newContractData"
          @submit="addContract"
          @cancel="closeAddModal"
          @field-change="handleFieldChange"
          class="w-full"
        />
      </template>
    </BaseModal>

    <BaseModal :is-open="showEditModal" @close="closeEditModal" size="xl">
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          Edit Contract
        </h2>
      </template>
      <template #body>
        <DynamicForm
          v-if="editingContract"
          :fields="contractFormFields"
          :initial-data="editingContractData"
          @submit="updateContract"
          @cancel="closeEditModal"
          @field-change="handleFieldChange"
        />
      </template>
    </BaseModal>
    <!-- Contract Details Modal -->
    <BaseModal
      :is-open="!!selectedContract && !showEditModal"
      @close="selectedContract = null"
      size="2xl"
    >
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          Contract Details
        </h2>
      </template>
      <template #body>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div
            v-for="field in contractDetailsFields"
            :key="field.label"
            :class="{ 'lg:col-span-3': field.fullWidth }"
          >
            <p class="text-sm font-medium text-text-secondary">
              {{ field.label }}
            </p>
            <template v-if="field.isList">
              <ul class="list-disc list-inside">
                <li
                  v-for="item in field.value"
                  :key="item.id"
                  class="text-base text-text-primary"
                >
                  {{ item.title }}
                </li>
              </ul>
            </template>
            <p v-else class="text-base text-text-primary">{{ field.value }}</p>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end space-x-3">
          <button @click="openEditModal" class="btn btn-primary">
            <i class="fas fa-edit mr-2"></i>Edit
          </button>
          <button
            @click="confirmDeleteContract(selectedContract.id)"
            class="btn btn-error px-4 py-2 rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            <i class="fas fa-trash-alt mr-2"></i>Delete
          </button>
          <button
            @click="generateContractDOCX(selectedContract.id)"
            class="btn btn-accent-tertiary px-4 py-2 rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            <i class="fas fa-file-pdf mr-2"></i>Generate PDF
          </button>
        </div>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="showDeleteConfirmModal"
      @close="showDeleteConfirmModal = false"
      size="sm"
    >
      <template #header>
        <h3
          class="text-xl font-semibold text-text-primary text-gradient bg-gradient-to-r from-error to-error-light"
        >
          Confirm Delete
        </h3>
      </template>
      <template #body>
        <p class="text-text-secondary mb-4">
          Are you sure you want to delete this contract? This action cannot be
          undone.
        </p>
        <div class="flex justify-end space-x-3">
          <button
            @click="showDeleteConfirmModal = false"
            class="btn btn-secondary"
          >
            Cancel
          </button>
          <button
            @click="deleteContract"
            class="btn btn-error px-4 py-2 rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Delete
          </button>
        </div>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="isGeneratingPDF"
      @close="isGeneratingPDF = false"
      size="sm"
    >
      <template #header>
        <h3
          class="text-xl font-semibold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          Generating Contract PDF
        </h3>
      </template>
      <template #body>
        <div class="flex flex-col items-center justify-center py-4">
          <div class="spinner mb-4"></div>
          <p class="text-text-secondary">
            Please wait while we generate your contract PDF...
          </p>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import BaseModal from "@/components/BaseModal.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import ContractCard from "@/components/ContractCard.vue";
import StatCard from "@/components/StatCard.vue";
import api from "@/services/api";

export default {
  name: "ContractManagementView",
  components: {
    BaseModal,
    DynamicForm,
    ContractCard,
    StatCard,
  },

  setup() {
    const store = useStore();
    const contracts = ref([]);
    const totalContracts = ref(0);
    const activeContracts = ref(0);
    const expiringSoonContracts = ref(0);
    const showAddContractModal = ref(false);
    const showEditModal = ref(false);
    const showDeleteConfirmModal = ref(false);
    const selectedContract = ref(null);
    const editingContract = ref(null);
    const newContractData = ref({});
    const selectedArtistId = ref(null);
    const artistTracks = ref([]);
    const isGeneratingPDF = ref(false);
    const editingContractData = ref({});
    const searchQuery = ref("");
    const statusFilter = ref("");
    const contractStatusFilter = ref("");
    const contractTypeFilter = ref("");
    const showFilters = ref(false);

    const contractFormFields = computed(() => [
      {
        key: "artist_id",
        label: "Artist",
        type: "select",
        options: store.getters["artists/getArtistsList"].map((artist) => ({
          value: artist.id,
          label: artist.name,
        })),
        required: true,
      },
      {
        key: "organization_id",
        label: "Organization",
        type: "select",
        options: store.getters["organizations/getOrganizationsList"].map(
          (org) => ({
            value: org.id,
            label: org.name,
          })
        ),
        required: true,
      },
      {
        key: "contract_type",
        label: "Contract Type",
        type: "select",
        options: [
          { value: "Exclusive", label: "Exclusive" },
          { value: "Non-Exclusive", label: "Non-Exclusive" },
          { value: "Licensing", label: "Licensing" },
          { value: "Distribution", label: "Distribution" },
          { value: "Production", label: "Production" },
        ],
        required: true,
      },
      {
        key: "status",
        label: "Status",
        type: "select",
        options: [
          { value: "Signed", label: "Signed" },
          { value: "Unsigned", label: "Unsigned" },
          { value: "Counter-signed", label: "Counter-signed" },
        ],
        required: true,
      },
      {
        key: "contract_status",
        label: "Contract Status",
        type: "select",
        options: [
          { value: "Draft", label: "Draft" },
          { value: "Active", label: "Active" },
          { value: "Expired", label: "Expired" },
          { value: "Terminated", label: "Terminated" },
        ],
        required: true,
      },
      { key: "start_date", label: "Start Date", type: "date", required: true },
      { key: "end_date", label: "End Date", type: "date", required: true },
      { key: "territory", label: "Territory", type: "text", required: true },
      {
        key: "re_recordings_restriction",
        label: "Re-recordings Restriction (years)",
        type: "number",
        required: true,
      },
      {
        key: "royalty_rate",
        label: "Royalty Rate (%)",
        type: "number",
        step: 0.01,
        required: true,
      },
      {
        key: "physical_royalty",
        label: "Physical Royalty (%)",
        type: "number",
        step: 0.01,
        required: true,
      },
      {
        key: "accounting_period",
        label: "Accounting Period (days)",
        type: "number",
        required: true,
      },
      { key: "term", label: "Term (years)", type: "number", required: true },
      {
        key: "termination_notice_period",
        label: "Termination Notice Period (months)",
        type: "number",
        required: true,
      },
      {
        key: "signature_date",
        label: "Signature Date",
        type: "date",
        required: true,
      },
      {
        key: "track_ids",
        label: "Tracks",
        type: "multiselect",
        options: artistTracks.value,
        required: true,
      },
    ]);

    const contractDetailsFields = computed(() => {
      if (!selectedContract.value) return [];

      return [
        { label: "Artist", value: selectedContract.value.artist.name },
        {
          label: "Organization",
          value: selectedContract.value.organization.name,
        },
        { label: "Contract Type", value: selectedContract.value.contract_type },
        { label: "Status", value: selectedContract.value.status },
        {
          label: "Contract Status",
          value: selectedContract.value.contract_status,
        },
        {
          label: "Start Date",
          value: formatDate(selectedContract.value.start_date),
        },
        {
          label: "End Date",
          value: formatDate(selectedContract.value.end_date),
        },
        { label: "Territory", value: selectedContract.value.territory },
        {
          label: "Re-recordings Restriction (years)",
          value: selectedContract.value.re_recordings_restriction,
        },
        {
          label: "Royalty Rate (%)",
          value: selectedContract.value.royalty_rate,
        },
        {
          label: "Physical Royalty (%)",
          value: selectedContract.value.physical_royalty,
        },
        {
          label: "Accounting Period (days)",
          value: selectedContract.value.accounting_period,
        },
        { label: "Term (years)", value: selectedContract.value.term },
        {
          label: "Termination Notice Period (months)",
          value: selectedContract.value.termination_notice_period,
        },
        {
          label: "Signature Date",
          value: formatDate(selectedContract.value.signature_date),
        },
        {
          label: "Tracks",
          value: selectedContract.value.tracks,
          fullWidth: true,
          isList: true,
        },
      ];
    });

    const fetchTracks = async (artistId) => {
      try {
        const response = await api.getArtistTracks(artistId);

        // Check if response.data is null or undefined
        if (response.data) {
          artistTracks.value = response.data.map((track) => ({
            value: track.id,
            label: track.title,
          }));
        } else {
          artistTracks.value = []; // Set to empty array if no tracks
          console.warn(`No tracks found for artist ID: ${artistId}`);
        }

        // Update the track_ids field options for both new and editing contracts
        const updateTrackOptions = (fields) => {
          const trackField = fields.find((field) => field.key === "track_ids");
          if (trackField) {
            trackField.options = artistTracks.value;
          }
        };

        updateTrackOptions(contractFormFields.value);
        if (editingContract.value) {
          updateTrackOptions(contractFormFields.value);
          // Update the editingContractData with the new track options
          editingContractData.value.track_ids =
            editingContractData.value.track_ids.map((track) => ({
              value: track.value,
              label:
                artistTracks.value.find((t) => t.value === track.value)
                  ?.label || "Unknown Track",
            }));
        }
      } catch (error) {
        console.error("Error fetching tracks:", error);
        artistTracks.value = []; // Set to empty array on error
      }
    };

    // Add this watch
    watch(
      () => selectedArtistId.value,
      async (newArtistId) => {
        if (newArtistId) {
          await fetchTracks(newArtistId);
        }
      }
    );

    const handleFieldChange = async (event) => {
      if (event.key === "artist_id") {
        selectedArtistId.value = event.value;
        if (showAddContractModal.value) {
          newContractData.value.artist_id = event.value;
        } else if (showEditModal.value) {
          editingContractData.value.artist_id = event.value;
        }
        await fetchTracks(event.value);
      }
    };

    const closeAddModal = () => {
      showAddContractModal.value = false;
      newContractData.value = {};
      artistTracks.value = [];
    };

    const closeEditModal = () => {
      showEditModal.value = false;
      editingContract.value = null;
      editingContractData.value = {};
      artistTracks.value = [];
    };

    const fetchContracts = async () => {
      try {
        const response = await api.getContracts();
        contracts.value = response.data;
      } catch (error) {
        console.error("Error fetching contracts:", error);
      }
    };

    const fetchContractStats = async () => {
      try {
        const response = await api.getContractStats();
        totalContracts.value = response.data.total;
        activeContracts.value = response.data.active;
        expiringSoonContracts.value = response.data.expiringSoon;
      } catch (error) {
        console.error("Error fetching contract stats:", error);
      }
    };

    const addContract = async (formData) => {
      try {
        const contractData = {
          ...formData,
          track_ids: formData.track_ids.map((track) => ({
            value: track.value,
          })),
        };
        await api.createContract(contractData);
        closeAddModal();
        await fetchContracts();
        await fetchContractStats();
      } catch (error) {
        console.error("Error creating contract:", error);
      }
    };

    const updateContract = async (formData) => {
      try {
        const contractData = {
          ...formData,
          track_ids: formData.track_ids.map((track) => track.value),
        };
        // Remove any nested objects that might cause issues
        delete contractData.artist;
        delete contractData.organization;
        delete contractData.tracks;
        await api.updateContract(editingContract.value.id, contractData);
        closeEditModal();
        await fetchContracts();
        await fetchContractStats();
      } catch (error) {
        console.error("Error updating contract:", error);
        if (error.response) {
          console.error("Error response data:", error.response.data);
        }
      }
    };

    const editContract = async (contract) => {
      editingContract.value = { ...contract };
      editingContractData.value = { ...contract };
      selectedArtistId.value = contract.artist_id;
      await fetchTracks(contract.artist_id);

      // Convert track_ids to the format expected by vue-multiselect
      editingContractData.value.track_ids = Array.isArray(contract.track_ids)
        ? contract.track_ids.map((trackId) => ({
            value: trackId,
            label:
              artistTracks.value.find((track) => track.value === trackId)
                ?.label || "Unknown Track",
          }))
        : [];

      showEditModal.value = true;
      selectedContract.value = null; // Close the details modal
    };

    const fetchArtistTracks = async (artistId) => {
      if (!artistId || typeof artistId !== "number") {
        console.error("Invalid artist ID:", artistId);
        return;
      }

      try {
        const response = await api.getArtistTracks(artistId);
        artistTracks.value = response.data.map((track) => ({
          value: track.id,
          label: track.title,
        }));
        // Update the track_ids field options
        const trackField = contractFormFields.value.find(
          (field) => field.key === "track_ids"
        );
        if (trackField) {
          trackField.options = artistTracks.value;
        }
      } catch (error) {
        console.error("Error fetching artist tracks:", error);
      }
    };

    watch(
      () => {
        const artistField = contractFormFields.value.find(
          (f) => f.key === "artist_id"
        );
        return artistField ? artistField.value : null;
      },
      (newArtistId) => {
        if (newArtistId) {
          handleArtistChange(newArtistId);
        }
      }
    );

    const handleArtistChange = async (event) => {
      let artistId;
      if (typeof event === "object" && event.key === "artist_id") {
        artistId = event.value;
      } else if (typeof event === "object" && event.target) {
        artistId = event.target.value;
      } else {
        artistId = event;
      }

      if (artistId) {
        selectedArtistId.value = artistId;
        await fetchArtistTracks(artistId);
      }
    };

    watch(
      () => contractFormFields.value.find((f) => f.key === "artist_id")?.value,
      async (newArtistId) => {
        if (newArtistId) {
          await fetchTracks(newArtistId);
        }
      }
    );

    const deleteContract = async () => {
      try {
        await api.deleteContract(selectedContract.value.id);
        showDeleteConfirmModal.value = false;
        selectedContract.value = null;
        await fetchContracts();
        await fetchContractStats();
      } catch (error) {
        console.error("Error deleting contract:", error);
      }
    };

    const viewContract = (contract) => {
      selectedContract.value = contract;
      showEditModal.value = false; // Ensure edit modal is closed when viewing details
    };
    const openEditModal = () => {
      if (selectedContract.value) {
        editContract(selectedContract.value);
      }
    };

    const confirmDeleteContract = (contractId) => {
      selectedContract.value = contracts.value.find((c) => c.id === contractId);
      showDeleteConfirmModal.value = true;
    };

    const generateContractDOCX = async (contractId) => {
      isGeneratingPDF.value = true;
      try {
        const response = await api.generateContractDocx(contractId);

        // Check if the response is actually a PDF
        const contentType = response.headers["content-type"];
        if (contentType !== "application/pdf") {
          throw new Error(`Unexpected content type: ${contentType}`);
        }

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `contract_${contractId}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error generating contract PDF:", error);
        // Show an error message to the user
        alert(
          "Failed to generate PDF. Please try again later or contact support."
        );
      } finally {
        isGeneratingPDF.value = false;
      }
    };

    const getStatusClass = (status) => {
      const statusClasses = {
        Draft: "text-info",
        Active: "text-success",
        Expired: "text-error",
        Terminated: "text-warning",
      };
      return statusClasses[status] || "text-text-secondary";
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString();
    };

    const selectedContractDetails = computed(() => {
      if (!selectedContract.value) return {};
      const { artist, organization, ...details } = selectedContract.value;
      return {
        ...details,
        artist: artist.name,
        organization: organization.name,
      };
    });

    const formatLabel = (key) => {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const formatValue = (key, value) => {
      if (key.includes("date")) return formatDate(value);
      if (key.includes("royalty")) return `${value}%`;
      if (key === "re_recordings_restriction") return `${value} years`;
      if (key === "accounting_period") return `${value} days`;
      if (key === "term") return `${value} years`;
      if (key === "termination_notice_period") return `${value} months`;
      return value;
    };

    const statusOptions = computed(() => {
      const statuses = new Set(
        contracts.value.map((contract) => contract.status)
      );
      return [...statuses];
    });

    const contractStatusOptions = computed(() => {
      const statuses = new Set(
        contracts.value.map((contract) => contract.contract_status)
      );
      return [...statuses];
    });

    const contractTypeOptions = computed(() => {
      const types = new Set(
        contracts.value.map((contract) => contract.contract_type)
      );
      return [...types];
    });

    const filteredContracts = computed(() => {
      return contracts.value.filter((contract) => {
        const matchesSearch =
          contract.contract_type
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase()) ||
          contract.artist.name
            .toLowerCase()
            .includes(searchQuery.value.toLowerCase());
        const matchesStatus =
          statusFilter.value === "" || contract.status === statusFilter.value;
        const matchesContractStatus =
          contractStatusFilter.value === "" ||
          contract.contract_status === contractStatusFilter.value;
        const matchesContractType =
          contractTypeFilter.value === "" ||
          contract.contract_type === contractTypeFilter.value;
        return (
          matchesSearch &&
          matchesStatus &&
          matchesContractStatus &&
          matchesContractType
        );
      });
    });

    onMounted(async () => {
      await store.dispatch("artists/fetchArtistsList");
      await store.dispatch("organizations/fetchOrganizationsList");
      await fetchContracts();
      await fetchContractStats();
    });

    return {
      contracts,
      totalContracts,
      activeContracts,
      expiringSoonContracts,
      showAddContractModal,
      showEditModal,
      showDeleteConfirmModal,
      selectedContract,
      editingContract,
      contractFormFields,
      addContract,
      updateContract,
      deleteContract,
      viewContract,
      editContract,
      closeEditModal,
      confirmDeleteContract,
      generateContractDOCX,
      fetchTracks,
      handleArtistChange,
      isGeneratingPDF,
      handleFieldChange,
      newContractData,
      closeAddModal,
      editingContractData,
      searchQuery,
      statusFilter,
      filteredContracts,
      selectedContractDetails,
      formatLabel,
      formatValue,
      getStatusClass,
      contractDetailsFields,
      showFilters,
      contractStatusFilter,
      contractTypeFilter,
      statusOptions,
      contractStatusOptions,
      contractTypeOptions,
      openEditModal,
    };
  },
};
</script>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: var(--color-accent-primary);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
