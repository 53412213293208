import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { connectWebSocket, disconnectWebSocket } from "./services/websocket";
import "./assets/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "material-icons/iconfont/material-icons.css";
import "vue-multiselect/dist/vue-multiselect.css";

// Initialize color scheme before mounting the app
store.dispatch("colorScheme/initColorScheme");

const app = createApp(App);

app.use(store).use(router);

// Global error handler
app.config.errorHandler = (err, vm, info) => {
  console.error("Global error:", err, info);
  // You can add more error handling logic here, such as sending errors to a logging service
};

// Custom directive for focus
app.directive("focus", {
  mounted(el) {
    el.focus();
  },
});

// Global mixin for common methods
app.mixin({
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
});

// Initialize store before mounting the app
store.dispatch("auth/checkAuth").then(() => {
  // Mount the app
  app.mount("#app");

  // Connect WebSocket after the app is mounted
  const token = store.getters["auth/token"];
  connectWebSocket(token);
});

// Disconnect WebSocket when the window is about to unload
window.addEventListener("beforeunload", () => {
  disconnectWebSocket();
});

// Remove loading spinner when app is mounted
const appLoading = document.getElementById("app-loading");
if (appLoading) {
  appLoading.classList.add("fade-leave-active");
  setTimeout(() => {
    appLoading.remove();
  }, 500);
}

// Add a class to the body when the app is ready
document.body.classList.add("app-ready");

// Listen for network status changes
window.addEventListener("online", () => {
  store.dispatch("app/setOnline", true);
});
window.addEventListener("offline", () => {
  store.dispatch("app/setOnline", false);
});

// Preload essential assets
// const preloadAssets = ["/images/logo.png", "/fonts/inter-var-latin.woff2"];

// preloadAssets.forEach((asset) => {
//   const link = document.createElement("link");
//   link.rel = "preload";
//   link.as = asset.endsWith(".woff2") ? "font" : "image";
//   link.href = asset;
//   if (asset.endsWith(".woff2")) {
//     link.type = "font/woff2";
//     link.crossOrigin = "anonymous";
//   }
//   document.head.appendChild(link);
// });

// Add smooth scrolling behavior
document.documentElement.style.scrollBehavior = "smooth";

// Add transition for color scheme changes
document.documentElement.style.transition =
  "background-color 0.3s ease, color 0.3s ease";
