<template>
    <div class="task-item space-y-2">
      <div 
        class="flex items-center justify-between space-x-2 p-2 rounded-md transition-all duration-normal hover:bg-primary-lighter"
      >
        <div class="flex items-center space-x-2">
          <input
            type="checkbox"
            :checked="task.completed"
            class="form-checkbox h-5 w-5 text-accent-primary rounded border-gray-600 focus:ring-accent-primary focus:ring-offset-primary-bg"
            @change="handleTaskCompletion"
          />
          <span
            :class="{
              'line-through text-text-secondary': task.completed,
              'text-text-primary': !task.completed,
            }"
          >
            {{ task.title }}
          </span>
        </div>
        <div class="flex space-x-2">
          <button
            @click="toggleSubtasks"
            class="text-accent-primary hover:text-accent-primary-light transition-colors duration-normal"
            v-if="localSubtasks.length > 0"
          >
            <i :class="['fas', showSubtasks ? 'fa-chevron-down' : 'fa-chevron-right']"></i>
          </button>
          <button
            @click="toggleAddingSubtask"
            class="text-accent-secondary hover:text-accent-secondary-light transition-colors duration-normal"
          >
            <i class="fas fa-plus"></i>
          </button>
          <button
            @click="$emit('edit', task)"
            class="text-accent-secondary hover:text-accent-secondary-light transition-colors duration-normal"
          >
            <i class="fas fa-edit"></i>
          </button>
          <button
            @click="$emit('delete', task)"
            class="text-error hover:text-error-light transition-colors duration-normal"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
      
      <!-- Subtasks Section -->
      <div v-if="showSubtasks || isAddingSubtask" class="ml-8 space-y-2">
        <!-- Existing Subtasks -->
        <div
          v-for="subtask in localSubtasks"
          :key="subtask.id"
          class="flex items-center justify-between space-x-2 p-2 rounded-md transition-all duration-normal hover:bg-primary-lighter"
        >
          <div class="flex items-center space-x-2">
            <input
              type="checkbox"
              :checked="subtask.completed"
              class="form-checkbox h-4 w-4 text-accent-primary rounded border-gray-600 focus:ring-accent-primary focus:ring-offset-primary-bg"
              @change="handleSubtaskCompletion(subtask)"
            />
            <input
              v-if="editingSubtask && editingSubtask.id === subtask.id"
              v-model="editingSubtask.title"
              type="text"
              :id="`subtask-${subtask.id}`"
              class="flex-1 bg-primary-lighter border border-gray-600 rounded p-1 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
              @keyup.enter="submitEditedSubtask"
              @blur="cancelEditSubtask"
            />
            <span
              v-else
              :class="{
                'line-through text-text-secondary': subtask.completed,
                'text-text-primary': !subtask.completed,
              }"
            >
              {{ subtask.title }}
            </span>
          </div>
          <div class="flex space-x-2">
            <button
              v-if="!editingSubtask || editingSubtask.id !== subtask.id"
              @click="editSubtask(subtask)"
              class="text-accent-secondary hover:text-accent-secondary-light transition-colors duration-normal"
            >
              <i class="fas fa-edit"></i>
            </button>
            <button
              @click="deleteSubtask(subtask)"
              class="text-error hover:text-error-light transition-colors duration-normal"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
  
        <!-- New Subtask Input Row -->
        <div v-if="isAddingSubtask" class="flex items-center justify-between space-x-2 p-2">
          <div class="flex-1 flex items-center space-x-2">
            <input
              type="checkbox"
              disabled
              class="form-checkbox h-4 w4 text-accent-primary rounded border-gray-600"
            />
            <input
              v-model="newSubtaskTitle"
              type="text"
              placeholder="Enter subtask..."
              class="flex-1 bg-primary-lighter border border-gray-600 rounded p-1 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
              @keyup.enter="submitNewSubtask"
            />
          </div>
          <div class="flex space-x-2">
            <button
              @click="submitNewSubtask"
              class="text-success hover:text-success-light transition-colors duration-normal"
            >
              <i class="fas fa-check"></i>
            </button>
            <button
              @click="cancelAddSubtask"
              class="text-error hover:text-error-light transition-colors duration-normal"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    name: 'TaskItem',
    props: {
      task: {
        type: Object,
        required: true
      }
    },
    emits: ['update', 'edit', 'delete'],
    setup(props, { emit }) {
      const showSubtasks = ref(false);
      const isAddingSubtask = ref(false);
      const newSubtaskTitle = ref('');
      const localSubtasks = ref(props.task.subtasks || []);
      const editingSubtask = ref(null);
  
      const toggleSubtasks = () => {
        showSubtasks.value = !showSubtasks.value;
      };
  
      const toggleAddingSubtask = () => {
        isAddingSubtask.value = true;
        showSubtasks.value = true;
        setTimeout(() => {
          const input = document.querySelector('input[type="text"]');
          if (input) input.focus();
        }, 0);
      };
  
      const submitNewSubtask = () => {
        if (newSubtaskTitle.value.trim()) {
          const newSubtask = {
            id: Date.now().toString(),
            title: newSubtaskTitle.value.trim(),
            completed: false
          };
  
          localSubtasks.value.push(newSubtask);
  
          const updatedTask = {
            ...props.task,
            subtasks: localSubtasks.value
          };
          emit('update', updatedTask);
  
          newSubtaskTitle.value = '';
  
          setTimeout(() => {
            const input = document.querySelector('input[type="text"]');
            if (input) input.focus();
          }, 0);
  
          showSubtasks.value = true;
        }
      };
  
      const cancelAddSubtask = () => {
        isAddingSubtask.value = false;
        newSubtaskTitle.value = '';
        if (localSubtasks.value.length === 0) {
          showSubtasks.value = false;
        }
      };
  
      const handleTaskCompletion = (event) => {
        const updatedTask = {
          ...props.task,
          completed: event.target.checked
        };
        emit('update', updatedTask);
      };
  
      const handleSubtaskCompletion = (subtask) => {
        const updatedSubtasks = localSubtasks.value.map(s => 
          s.id === subtask.id ? { ...s, completed: !s.completed } : s
        );
        localSubtasks.value = updatedSubtasks;
        
        const updatedTask = {
          ...props.task,
          subtasks: updatedSubtasks
        };
        emit('update', updatedTask);
      };
  
      const editSubtask = (subtask) => {
        editingSubtask.value = subtask;
        setTimeout(() => {
          const input = document.querySelector(`#subtask-${subtask.id}`);
          if (input) input.focus();
        }, 0);
      };
  
      const submitEditedSubtask = () => {
        if (editingSubtask.value && editingSubtask.value.title.trim()) {
          const updatedSubtasks = localSubtasks.value.map(s =>
            s.id === editingSubtask.value.id
              ? { ...s, title: editingSubtask.value.title.trim() }
              : s
          );
          localSubtasks.value = updatedSubtasks;
  
          const updatedTask = {
            ...props.task,
            subtasks: updatedSubtasks,
          };
          emit('update', updatedTask);
  
          editingSubtask.value = null;
        }
      };
  
      const cancelEditSubtask = () => {
        editingSubtask.value = null;
      };
  
      const deleteSubtask = (subtask) => {
        if (window.confirm('Are you sure you want to delete this subtask?')) {
          const updatedSubtasks = localSubtasks.value.filter(s => s.id !== subtask.id);
          localSubtasks.value = updatedSubtasks;
          
          const updatedTask = {
            ...props.task,
            subtasks: updatedSubtasks
          };
          emit('update', updatedTask);
  
          if (updatedSubtasks.length === 0 && !isAddingSubtask.value) {
            showSubtasks.value = false;
          }
        }
      };
  
      return {
        showSubtasks,
        isAddingSubtask,
        newSubtaskTitle,
        localSubtasks,
        editingSubtask,
        toggleSubtasks,
        toggleAddingSubtask,
        submitNewSubtask,
        cancelAddSubtask,
        handleTaskCompletion,
        handleSubtaskCompletion,
        editSubtask,
        submitEditedSubtask,
        cancelEditSubtask,
        deleteSubtask
      };
    }
  };
  </script>
  