<template>
  <div class="container mx-auto px-4 py-8">
    <!-- Header with Tab Navigation -->
    <div class="mb-6">
      <div class="flex justify-between items-center mb-4">
        <h1 class="text-3xl font-bold text-text-primary">User Management</h1>
        <button
          @click="showInviteModal = true"
          class="bg-accent-primary text-white py-2 px-4 rounded-md hover:bg-accent-secondary transition duration-300 flex items-center gap-2"
        >
          <i class="fas fa-user-plus"></i>
          Invite New User
        </button>
      </div>

      <!-- Tab Navigation -->
      <div class="border-b border-gray-700">
        <nav class="flex space-x-8" aria-label="Tabs">
          <button
            v-for="tab in tabs"
            :key="tab.id"
            @click="activeTab = tab.id"
            :class="[
              'py-4 px-1 -mb-px text-sm font-medium border-b-2 whitespace-nowrap flex items-center gap-2',
              activeTab === tab.id
                ? 'border-accent-primary text-accent-primary'
                : 'border-transparent text-text-secondary hover:text-text-primary hover:border-gray-700',
            ]"
          >
            <i :class="tab.icon"></i>
            {{ tab.name }}
            <span
              v-if="tab.id === 'pending' && pendingCount > 0"
              class="ml-1 px-2 py-0.5 text-xs font-medium bg-accent-primary text-white rounded-full"
            >
              {{ pendingCount }}
            </span>
          </button>
        </nav>
      </div>
    </div>
    <!-- Content Sections -->
    <div class="min-h-[60vh]">
      <!-- Active Users Section -->
      <div v-show="activeTab === 'active'" class="space-y-6">
        <!-- Search and Filter Section -->
        <div class="mb-6 flex flex-col sm:flex-row gap-4">
          <div class="flex-1">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Search users..."
              class="w-full px-4 py-2 rounded-md bg-primary-lighter border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
            />
          </div>
          <div class="flex gap-4">
            <select
              v-model="roleFilter"
              class="px-4 py-2 rounded-md bg-primary-lighter border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
            >
              <option value="">All Roles</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
            <select
              v-model="statusFilter"
              class="px-4 py-2 rounded-md bg-primary-lighter border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
            >
              <option value="">All Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
        </div>

        <!-- Users Table -->
        <div class="bg-primary-card rounded-lg shadow-lg overflow-hidden">
          <div class="overflow-x-auto">
            <table class="w-full">
              <thead class="bg-primary-darkest">
                <tr>
                  <th
                    v-for="header in tableHeaders"
                    :key="header.key"
                    class="px-6 py-4 text-left text-sm font-semibold text-text-primary"
                  >
                    {{ header.label }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="user in filteredUsers"
                  :key="user.id"
                  class="border-b border-primary-darker hover:bg-primary-lighter transition-colors duration-200"
                >
                  <td class="px-6 py-4 text-text-primary">
                    <div class="flex items-center gap-3">
                      <div
                        class="w-8 h-8 rounded-full bg-accent-primary flex items-center justify-center text-white"
                      >
                        {{ getInitials(user.name || user.email) }}
                      </div>
                      <div>
                        <div class="font-medium">
                          {{ user.name || "Not Set" }}
                        </div>
                        <div class="text-sm text-text-secondary">
                          {{ user.email }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <span
                      :class="[
                        'px-2 py-1 rounded-full text-xs font-medium',
                        user.role === 'admin'
                          ? 'bg-purple-100 text-purple-800'
                          : 'bg-blue-100 text-blue-800',
                      ]"
                    >
                      {{ user.role }}
                    </span>
                  </td>
                  <td class="px-6 py-4">
                    <span
                      :class="[
                        'px-2 py-1 rounded-full text-xs font-medium',
                        user.is_active
                          ? 'bg-green-100 text-green-800'
                          : 'bg-red-100 text-red-800',
                      ]"
                    >
                      {{ user.is_active ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td class="px-6 py-4">
                    <div class="flex items-center gap-2">
                      <button
                        @click="() => showToggleStatusModal(user)"
                        class="flex items-center gap-2 px-3 py-1.5 rounded-md text-sm font-medium"
                        :class="[
                          user.is_active
                            ? 'bg-yellow-100 text-yellow-800 hover:bg-yellow-200'
                            : 'bg-green-100 text-green-800 hover:bg-green-200',
                        ]"
                      >
                        <i
                          :class="[
                            'fas',
                            user.is_active ? 'fa-ban' : 'fa-check-circle',
                          ]"
                        ></i>
                        {{ user.is_active ? "Deactivate" : "Activate" }}
                      </button>

                      <button
                        v-if="user.role !== 'admin'"
                        @click="() => showDeleteModal(user)"
                        class="flex items-center gap-2 px-3 py-1.5 bg-red-100 text-red-800 hover:bg-red-200 rounded-md text-sm font-medium"
                      >
                        <i class="fas fa-trash"></i>
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Invite User Modal -->
      <BaseModal
        :is-open="showInviteModal"
        @close="showInviteModal = false"
        size="md"
      >
        <template #header>Invite New User</template>
        <template #body>
          <form @submit.prevent="handleInvite" class="space-y-4">
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-text-secondary mb-2"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                v-model="inviteForm.email"
                required
                class="w-full px-3 py-2 border border-gray-700 rounded-md bg-primary-darker text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
                placeholder="Enter email address"
              />
            </div>
            <div>
              <label
                for="role"
                class="block text-sm font-medium text-text-secondary mb-2"
              >
                Role
              </label>
              <select
                id="role"
                v-model="inviteForm.role"
                required
                class="w-full px-3 py-2 border border-gray-700 rounded-md bg-primary-darker text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
          </form>
        </template>
        <template #footer>
          <button
            @click="showInviteModal = false"
            class="px-4 py-2 btn btn-secondary text-text-secondary hover:text-text-primary transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            @click="handleInvite"
            :disabled="inviteLoading"
            class="px-4 py-2 bg-accent-primary text-white rounded-md hover:bg-accent-secondary transition-colors duration-200 disabled:opacity-50"
          >
            {{ inviteLoading ? "Sending..." : "Send Invitation" }}
          </button>
        </template>
      </BaseModal>

      <!-- Confirm Status Toggle Modal -->
      <BaseModal
        :is-open="showStatusModal"
        @close="showStatusModal = false"
        size="sm"
      >
        <template #header>Confirm Status Change</template>
        <template #body>
          <p class="text-text-primary">
            Are you sure you want to
            {{ selectedUser?.is_active ? "deactivate" : "activate" }} user
            <span class="font-semibold">{{
              selectedUser?.name || selectedUser?.email
            }}</span
            >?
          </p>
        </template>
        <template #footer>
          <button
            @click="showStatusModal = false"
            class="px-4 py-2 btn btn-secondary text-text-secondary hover:text-text-primary transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            @click="handleToggleStatus"
            :disabled="statusLoading"
            class="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors duration-200 disabled:opacity-50"
          >
            {{ statusLoading ? "Processing..." : "Confirm" }}
          </button>
        </template>
      </BaseModal>

      <!-- Confirm Delete Modal -->
      <BaseModal
        :is-open="showConfirmDelete"
        @close="showConfirmDelete = false"
        size="sm"
      >
        <template #header>Confirm Removal</template>
        <template #body>
          <p class="text-text-primary">
            Are you sure you want to remove user
            <span class="font-semibold">{{
              selectedUser?.name || selectedUser?.email
            }}</span
            >? This action cannot be undone.
          </p>
        </template>
        <template #footer>
          <button
            @click="showConfirmDelete = false"
            class="px-4 py-2 btn btn-secondary text-text-secondary hover:text-text-primary transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            @click="handleDelete"
            :disabled="deleteLoading"
            class="px-4 py-2 bg-error text-white rounded-md hover:bg-red-600 transition-colors duration-200 disabled:opacity-50"
          >
            {{ deleteLoading ? "Processing..." : "Remove User" }}
          </button>
        </template>
      </BaseModal>
      <PendingInvitations
        v-show="activeTab === 'pending'"
        @invitation-sent="handleInvitationSent"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import BaseModal from "@/components/BaseModal.vue";
import PendingInvitations from "@/components/PendingInvitations.vue";

const store = useStore();
const activeTab = ref("active");

const tabs = [
  {
    id: "active",
    name: "Active Users",
    icon: "fas fa-users",
  },
  {
    id: "pending",
    name: "Pending Invitations",
    icon: "fas fa-user-clock",
  },
];

// State
const searchQuery = ref("");
const roleFilter = ref("");
const statusFilter = ref("");
const showInviteModal = ref(false);
const showStatusModal = ref(false);
const showConfirmDelete = ref(false);
const inviteLoading = ref(false);
const statusLoading = ref(false);
const deleteLoading = ref(false);
const selectedUser = ref(null);
const inviteForm = ref({
  email: "",
  role: "user",
});
const pendingCount = computed(
  () => store.state.company.pendingInvitations.length
);

const handleInvitationSent = () => {
  // Refresh both active users and pending invitations
  store.dispatch("company/fetchCompanyUsers");
  store.dispatch("company/fetchPendingInvitations");
};

// Table Headers
const tableHeaders = [
  { key: "user", label: "User" },
  { key: "role", label: "Role" },
  { key: "status", label: "Status" },
  { key: "actions", label: "Actions" },
];

// Computed
const companyUsers = computed(() => store.state.company.companyUsers);
const filteredUsers = computed(() => {
  return companyUsers.value
    .filter((user) => user.role !== "admin") // Filter out admin users
    .filter((user) => {
      const matchesSearch =
        user.name?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.value.toLowerCase());
      const matchesRole = !roleFilter.value || user.role === roleFilter.value;
      const matchesStatus =
        !statusFilter.value ||
        (statusFilter.value === "active"
          ? user.is_active
          : statusFilter.value === "inactive"
          ? !user.is_active
          : true);
      return matchesSearch && matchesRole && matchesStatus;
    });
});

// Methods
const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);
};

const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

const handleInvite = async () => {
  try {
    if (!validateEmail(inviteForm.value.email)) {
      throw new Error("Please enter a valid email address");
    }

    inviteLoading.value = true;
    await store.dispatch("company/inviteUser", inviteForm.value);
    showInviteModal.value = false;
    inviteForm.value = { email: "", role: "user" };
  } catch (error) {
    console.error("Error inviting user:", error);
  } finally {
    inviteLoading.value = false;
  }
};

const showToggleStatusModal = (user) => {
  selectedUser.value = user;
  showStatusModal.value = true;
};

const handleToggleStatus = async () => {
  try {
    statusLoading.value = true;
    await store.dispatch("company/toggleUserStatus", selectedUser.value.id);
    await store.dispatch("company/fetchCompanyUsers");
    showStatusModal.value = false;
  } catch (error) {
    console.error("Error toggling user status:", error);
  } finally {
    statusLoading.value = false;
  }
};

const showDeleteModal = (user) => {
  selectedUser.value = user;
  showConfirmDelete.value = true;
};

const handleDelete = async () => {
  try {
    deleteLoading.value = true;
    await store.dispatch("company/removeUser", selectedUser.value.id);
    showConfirmDelete.value = false;
  } catch (error) {
    console.error("Error removing user:", error);
  } finally {
    deleteLoading.value = false;
  }
};

// const resendInvitation = async (user) => {
//   try {
//     await store.dispatch("company/resendInvitation", user.id);
//   } catch (error) {
//     console.error("Error resending invitation:", error);
//   }
// };

// Fetch users on component mount
store.dispatch("company/fetchCompanyUsers");
</script>

<style scoped>
.btn-secondary {
  @apply bg-primary-lighter text-text-primary hover:bg-primary-card focus:ring-accent-secondary;
}
</style>
