<template>
  <div
    :class="[
      'h-screen overflow-hidden bg-gradient-to-br from-primary-bg to-primary-lighter text-text-primary font-sans transition-colors duration-300',
      { light: !isDarkMode, dark: isDarkMode },
    ]"
  >
    <AppLayout>
      <template v-if="isPublicRoute">
        <router-view></router-view>
      </template>
      <template v-else>
        <CompanyContext v-slot="{ loading, error }">
          <template v-if="loading">
            <div class="flex items-center justify-center h-screen">
              <div
                class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-accent-primary"
              ></div>
            </div>
          </template>
          <template v-else-if="error">
            <div class="flex items-center justify-center h-screen">
              <p class="text-error text-xl">{{ error }}</p>
            </div>
          </template>
          <template v-else>
            <router-view v-slot="{ Component }">
              <transition
                name="fade"
                mode="out-in"
                enter-active-class="transition-opacity duration-300 ease-out"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity duration-300 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <component :is="Component" :key="$route.fullPath" />
              </transition>
            </router-view>
          </template>
        </CompanyContext>
      </template>
      <AppFooter :isAuthenticated="isAuthenticated" />
    </AppLayout>
  </div>
</template>

<style>
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,
body {
  @apply h-full m-0 p-0 overflow-hidden;
}

#app {
  @apply h-full;
}

body {
  @apply bg-primary-bg text-text-primary font-sans;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.app-ready {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  @apply w-2 h-2;
}

::-webkit-scrollbar-track {
  @apply bg-primary-bg;
}

::-webkit-scrollbar-thumb {
  @apply bg-primary-lighter rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-accent-primary bg-opacity-50;
}

* {
  @apply transition-colors duration-300 ease-in-out;
}

.text-gradient {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-accent-primary to-accent-secondary;
}

.btn {
  @apply px-4 py-2 rounded-md font-medium transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-bg;
}

.btn-primary {
  @apply bg-accent-primary text-white hover:bg-accent-primary-dark focus:ring-accent-primary;
}

.btn-secondary {
  @apply bg-primary-lighter text-text-primary hover:bg-primary-card focus:ring-accent-secondary;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import AppLayout from "@/components/AppLayout.vue";
import CompanyContext from "@/components/CompanyContext.vue";
import AppFooter from "@/components/AppFooter.vue";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "App",
  components: {
    AppLayout,
    AppFooter,
    CompanyContext,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    onMounted(async () => {
      await store.dispatch("auth/checkAuth");
      store.dispatch("colorScheme/initColorScheme");
    });

    const isPublicRoute = computed(() => {
      return ["/", "/login", "/signup", "/company-signup"].includes(route.path);
    });

    return {
      isPublicRoute,
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("colorScheme", ["isDarkMode"]),
  },
  methods: {
    ...mapActions("colorScheme", ["initColorScheme"]),
  },
};
</script>
