<template>
  <div class="p-6 bg-primary-bg min-h-screen">
    <h1
      class="text-3xl font-semibold text-text-primary mb-6 text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
    >
      Contacts
    </h1>
    <ListView
      :items="contacts"
      :columns="columns"
      :form-fields="formFields"
      :has-more="hasMore"
      entity-name="Contact"
      @add="showAddContactModal = true"
      @update="openEditModal"
      @delete="confirmDelete"
      @show-details="showContactDetails"
      @load-more="loadMoreContacts"
    >
      <template #approval_status="{ item }">
        <span
          :class="[
            'px-2 py-1 text-xs font-medium rounded-full transition-colors duration-300',
            item.approval_status
              ? 'bg-success bg-opacity-20 text-text-primary hover:bg-opacity-30'
              : 'bg-error bg-opacity-20 text-text-primary hover:bg-opacity-30',
          ]"
        >
          {{ item.approval_status ? "Approved" : "Not Approved" }}
        </span>
      </template>
    </ListView>

    <!-- Add Contact Modal -->
    <BaseModal
      :is-open="showAddContactModal"
      @close="showAddContactModal = false"
      size="lg"
    >
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          Add Contact
        </h2>
      </template>
      <template #body>
        <DynamicForm
          :fields="formFields"
          @submit="addContact"
          @cancel="showAddContactModal = false"
        />
      </template>
    </BaseModal>

    <!-- Contact Details Modal -->
    <BaseModal
      :is-open="!!selectedContact"
      @close="selectedContact = null"
      size="lg"
    >
      <template #header>
        <h3
          class="text-2xl font-semibold text-center text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          Contact Details
        </h3>
      </template>
      <template #body>
        <div v-if="selectedContact" class="space-y-6">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div v-for="(value, key) in contactDetails" :key="key" class="mb-4">
              <h4 class="text-sm font-medium text-text-secondary uppercase">
                {{ formatLabel(key) }}
              </h4>
              <p class="mt-1 text-lg text-text-primary">
                {{ formatValue(key, value) }}
              </p>
            </div>
          </div>
          <div class="flex justify-end space-x-4 mt-6">
            <button
              @click="openEditModal(selectedContact)"
              class="btn btn-primary"
            >
              <i class="fas fa-edit mr-2"></i>Edit
            </button>
            <button
              @click="confirmDelete(selectedContact.id)"
              class="btn btn-danger"
            >
              <i class="fas fa-trash-alt mr-2"></i>Delete
            </button>
          </div>
        </div>
      </template>
    </BaseModal>

    <!-- Edit Contact Modal -->
    <BaseModal :is-open="showEditModal" @close="closeEditModal" size="lg">
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
        >
          Edit Contact
        </h2>
      </template>
      <template #body>
        <DynamicForm
          v-if="editingContact"
          :fields="formFields"
          :initial-data="editingContact"
          @submit="updateContact"
          @cancel="closeEditModal"
        />
      </template>
    </BaseModal>

    <!-- Delete Confirmation Modal -->
    <BaseModal
      :is-open="showDeleteConfirmModal"
      @close="showDeleteConfirmModal = false"
      size="sm"
    >
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-error to-error-light"
        >
          Confirm Delete
        </h2>
      </template>
      <template #body>
        <p class="text-text-primary mb-4">
          Are you sure you want to delete this contact?
        </p>
        <div class="mt-4 flex justify-end space-x-2">
          <button
            @click="showDeleteConfirmModal = false"
            class="btn btn-secondary"
          >
            Cancel
          </button>
          <button @click="deleteContact" class="btn btn-danger">Delete</button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.btn {
  @apply px-4 py-2 rounded-md font-medium transition-colors duration-normal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-bg;
}
.btn-primary {
  @apply bg-accent-primary text-white hover:bg-accent-primary-dark focus:ring-accent-primary;
}

.btn-secondary {
  @apply bg-primary-lighter text-text-primary hover:bg-primary-card focus:ring-accent-secondary;
}

.btn-danger {
  @apply bg-error text-white hover:bg-error-light focus:ring-error;
}
</style>

<script>
import { ref, computed, onMounted } from "vue";
import ListView from "@/components/ListView.vue";
import BaseModal from "@/components/BaseModal.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import api from "@/services/api";
import { useStore } from "vuex";

export default {
  name: "ContactsView",
  components: {
    ListView,
    BaseModal,
    DynamicForm,
  },
  setup() {
    const store = useStore();
    const contacts = ref([]);
    const page = ref(1);
    const hasMore = ref(true);
    const isLoading = ref(false);
    const selectedContact = ref(null);
    const showAddContactModal = ref(false);
    const editingContact = ref(null);
    const showEditModal = ref(false);
    const showDeleteConfirmModal = ref(false);

    const columns = ref([
      { key: "full_name", label: "Name", visible: true, filterable: true },
      { key: "email", label: "Email", visible: true, filterable: true },
      { key: "phone", label: "Phone", visible: true, filterable: true },
      { key: "artist.name", label: "Artist", visible: true, filterable: true },
      {
        key: "organization.name",
        label: "Organization",
        visible: true,
        filterable: true,
      },
      {
        key: "approval_status",
        label: "Approval Status",
        visible: true,
        filterable: true,
      },
    ]);

    const formFields = ref([
      { key: "full_name", label: "Full Name", type: "text", required: true },
      { key: "email", label: "Email", type: "email", required: true },
      { key: "phone", label: "Phone", type: "text", required: true },
      { key: "address", label: "Address", type: "text" },
      { key: "city", label: "City", type: "text" },
      { key: "state", label: "State", type: "text" },
      { key: "country", label: "Country", type: "text" },
      { key: "zipcode", label: "Zipcode", type: "text" },
      {
        key: "artist_id",
        label: "Artist",
        type: "select",
        options: computed(() =>
          store.getters["artists/getArtistsList"].map((a) => ({
            value: a.id,
            label: a.name,
          }))
        ),
        required: true,
      },
      {
        key: "organization_id",
        label: "Organization",
        type: "select",
        options: computed(() =>
          store.getters["organizations/getOrganizationsList"].map((o) => ({
            value: o.id,
            label: o.name,
          }))
        ),
        required: true,
      },
      { key: "approval_status", label: "Approval Status", type: "checkbox" },
    ]);

    const contactDetails = computed(() => {
      if (!selectedContact.value) return {};
      const {
        full_name,
        email,
        phone,
        address,
        city,
        state,
        country,
        zipcode,
        artist,
        organization,
        approval_status,
      } = selectedContact.value;
      return {
        full_name,
        email,
        phone,
        address,
        city,
        state,
        country,
        zipcode,
        artist: artist?.name,
        organization: organization?.name,
        approval_status, // Change this line
      };
    });

    const fetchContacts = async () => {
      if (isLoading.value || !hasMore.value) return;

      isLoading.value = true;
      try {
        const response = await api.getContacts({
          page: page.value,
          per_page: 20,
        });
        contacts.value = [...contacts.value, ...response.data.contacts];
        hasMore.value = response.data.has_more;
        page.value++;
      } catch (error) {
        console.error("Error fetching contacts:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const loadMoreContacts = () => {
      if (hasMore.value && !isLoading.value) {
        fetchContacts();
      }
    };

    const fetchArtistsAndOrganizations = async () => {
      await Promise.all([
        store.dispatch("artists/fetchArtistsList"),
        store.dispatch("organizations/fetchOrganizationsList"),
      ]);
    };

    const addContact = async (formData) => {
      if (isLoading.value) return; // Prevent double submission
      isLoading.value = true;
      try {
        const response = await api.createContact(formData);
        contacts.value.unshift(response.data); // Add to the beginning of the array
        showAddContactModal.value = false;
      } catch (error) {
        console.error("Error creating contact:", error);
        alert("Error creating contact. Please try again.");
      } finally {
        isLoading.value = false;
      }
    };

    const updateContact = async (formData) => {
      try {
        // Remove nested objects before sending
        const cleanedData = { ...formData };
        delete cleanedData.artist;
        delete cleanedData.organization;

        const response = await api.updateContact(
          editingContact.value.id,
          cleanedData
        );

        // Check if the response data has an id
        if (response.data && response.data.id) {
          const index = contacts.value.findIndex(
            (c) => c.id === response.data.id
          );
          if (index !== -1) {
            contacts.value[index] = response.data;
          } else {
            // If the contact is not found in the array, add it
            contacts.value.push(response.data);
          }
          selectedContact.value = response.data;
        } else {
          console.error(
            "Updated contact data is missing or invalid:",
            response.data
          );
        }

        closeEditModal();
      } catch (error) {
        console.error("Error updating contact:", error);
        alert("Error updating contact. Please try again.");
      }
    };
    const confirmDelete = (id) => {
      selectedContact.value = contacts.value.find((c) => c.id === id);
      showDeleteConfirmModal.value = true;
    };

    const deleteContact = async () => {
      try {
        await api.deleteContact(selectedContact.value.id);
        contacts.value = contacts.value.filter(
          (c) => c.id !== selectedContact.value.id
        );
        selectedContact.value = null;
        showDeleteConfirmModal.value = false;
      } catch (error) {
        console.error("Error deleting contact:", error);
      }
    };

    const showContactDetails = (contact) => {
      selectedContact.value = contact;
    };

    const openEditModal = (contact) => {
      editingContact.value = { ...contact };
      showEditModal.value = true;
    };

    const closeEditModal = () => {
      showEditModal.value = false;
      editingContact.value = null;
    };

    const formatLabel = (key) => {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const formatValue = (key, value) => {
      if (key === "approval_status") {
        return value === true ? "Approved" : "Not Approved";
      }
      return value || "N/A";
    };

    onMounted(() => {
      fetchContacts();
      fetchArtistsAndOrganizations();
    });

    return {
      contacts,
      columns,
      formFields,
      hasMore,
      selectedContact,
      showAddContactModal,
      editingContact,
      showEditModal,
      showDeleteConfirmModal,
      contactDetails,
      addContact,
      updateContact,
      confirmDelete,
      deleteContact,
      showContactDetails,
      openEditModal,
      closeEditModal,
      formatLabel,
      formatValue,
      loadMoreContacts,
    };
  },
};
</script>
