<template>
  <slot :company="company" :loading="loading" :error="error"></slot>
</template>

<script>
import { ref, onMounted, provide, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "CompanyContext",
  setup() {
    const store = useStore();
    const router = useRouter();
    const company = ref(null);
    const loading = ref(true);
    const error = ref(null);

    const fetchCompanyData = async () => {
      try {
        loading.value = true;
        error.value = null;
        await store.dispatch("auth/checkAuth");
        if (store.getters["auth/isAuthenticated"]) {
          await store.dispatch("company/fetchCurrentCompany");
          company.value = store.state.company.currentCompany;
        } else {
          company.value = null;
        }
      } catch (err) {
        console.error("Error fetching company data:", err);
        error.value = "Failed to load company data";
        if (err.response && err.response.status === 401) {
          router.push("/login");
        }
      } finally {
        loading.value = false;
      }
    };

    onMounted(fetchCompanyData);

    watch(
      () => store.getters["auth/isAuthenticated"],
      (newValue) => {
        if (newValue) {
          fetchCompanyData();
        } else {
          company.value = null;
        }
      }
    );

    provide("companyContext", {
      company,
      loading,
      error,
      refreshCompany: fetchCompanyData,
    });

    return {
      company,
      loading,
      error,
    };
  },
};
</script>
