import { io } from "socket.io-client";

let socket = null;

export const connectWebSocket = (token) => {
  if (!socket) {
    socket = io(process.env.VUE_APP_API_URL, {
      transports: ["websocket"],
      upgrade: false,
      auth: {
        token: token,
      },
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    socket.on("connect", () => {
      console.log("Connected to WebSocket");
    });

    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    socket.on("disconnect", (reason) => {
      console.log("Disconnected:", reason);
      socket = null;
    });

    socket.on("error", (error) => {
      console.error("Socket error:", error);
      socket = null;
    });
  }
  return socket;
};

export const disconnectWebSocket = () => {
  if (socket) {
    console.log("Disconnecting WebSocket");
    socket.disconnect();
    socket = null;
  }
};

export const getSocket = () => socket;
