import api from "@/services/api";

export default {
  namespaced: true,
  state: {
    artistsList: [],
  },
  mutations: {
    SET_ARTISTS_LIST(state, artists) {
      state.artistsList = artists;
    },
  },
  actions: {
    async fetchArtistsList({ commit }) {
      try {
        const response = await api.getArtistsList();
        commit("SET_ARTISTS_LIST", response.data);
      } catch (error) {
        console.error("Error fetching artists list:", error);
      }
    },
  },
  getters: {
    getArtistsList: (state) => state.artistsList,
  },
};
