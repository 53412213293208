<template>
  <div
    class="min-h-screen flex items-center justify-center bg-primary-bg py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8">
      <div
        class="bg-primary-card p-8 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl"
      >
        <div class="text-center">
          <h2
            class="mt-6 text-3xl font-extrabold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
          >
            Register Your Company
          </h2>
          <p class="mt-2 text-sm text-text-secondary">
            Create your company account and get started with Arthead
          </p>
        </div>
        <form class="mt-8 space-y-6" @submit.prevent="handleSubmit">
          <div class="space-y-4">
            <div>
              <label
                for="companyName"
                class="block text-sm font-medium text-text-secondary"
                >Company Name</label
              >
              <input
                id="companyName"
                name="companyName"
                type="text"
                v-model="companyName"
                required
                :class="{
                  'border-error': companyNameError,
                }"
                class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary focus:z-10 sm:text-sm transition-all duration-300"
                placeholder="Company Name"
              />
              <div v-if="companyNameError" class="text-error text-xs mt-1">
                {{ companyNameError }}
              </div>
            </div>

            <div>
              <label
                for="industry"
                class="block text-sm font-medium text-text-secondary"
                >Industry</label
              >
              <input
                id="industry"
                name="industry"
                type="text"
                v-model="industry"
                required
                class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary focus:z-10 sm:text-sm transition-all duration-300"
                placeholder="Industry"
              />
            </div>
            <div>
              <label
                for="adminName"
                class="block text-sm font-medium text-text-secondary"
                >Admin Name</label
              >
              <input
                id="adminName"
                name="adminName"
                type="text"
                v-model="adminName"
                required
                class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary focus:z-10 sm:text-sm transition-all duration-300"
                placeholder="Admin Name"
              />
            </div>
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-text-secondary"
                >Email address</label
              >
              <div class="relative">
                <input
                  id="email"
                  name="email"
                  type="email"
                  v-model="email"
                  required
                  :class="{
                    'border-error': !isEmailAvailable && email,
                    'border-success': isEmailAvailable && email,
                  }"
                  class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary focus:z-10 sm:text-sm transition-all duration-300"
                  placeholder="Email address"
                  @blur="checkEmail"
                  @input="resetEmailAvailability"
                />
              </div>
              <div
                v-if="!isEmailAvailable && email"
                class="text-error text-xs mt-1"
              >
                This email is already registered.
              </div>
              <div
                v-else-if="isEmailAvailable && email"
                class="text-success text-xs mt-1"
              >
                Email is available.
              </div>
            </div>
            <div class="relative">
              <label
                for="password"
                class="block text-sm font-medium text-text-secondary"
                >Password</label
              >
              <div class="relative">
                <input
                  id="password"
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  required
                  :class="{
                    'border-error': passwordError,
                  }"
                  class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 pr-10 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary sm:text-sm transition-all duration-300"
                  placeholder="Password"
                  @input="validatePassword"
                />
                <button
                  type="button"
                  @click="togglePassword('password')"
                  class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  tabindex="-1"
                >
                  <i
                    :class="[
                      'fas',
                      showPassword ? 'fa-eye-slash' : 'fa-eye',
                      'text-text-secondary hover:text-text-primary transition-colors duration-200',
                    ]"
                  ></i>
                </button>
              </div>
              <div v-if="passwordError" class="text-error text-xs mt-1">
                {{ passwordError }}
              </div>
              <!-- Password requirements hint -->
              <!-- <div class="text-text-secondary text-xs mt-1">
                Password must contain at least 8 characters, one uppercase
                letter, one lowercase letter, and one number
              </div> -->
            </div>

            <div class="relative">
              <label
                for="confirmPassword"
                class="block text-sm font-medium text-text-secondary"
                >Confirm Password</label
              >
              <div class="relative">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  v-model="confirmPassword"
                  required
                  class="mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 pr-10 border border-gray-700 placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary sm:text-sm transition-all duration-300"
                  placeholder="Confirm Password"
                />
                <button
                  type="button"
                  @click="togglePassword('confirmPassword')"
                  class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  tabindex="-1"
                >
                  <i
                    :class="[
                      'fas',
                      showConfirmPassword ? 'fa-eye-slash' : 'fa-eye',
                      'text-text-secondary hover:text-text-primary transition-colors duration-200',
                    ]"
                  ></i>
                </button>
              </div>
              <div
                v-if="!passwordsMatch && confirmPassword"
                class="text-error text-xs mt-1"
              >
                Passwords do not match
              </div>
            </div>
          </div>
          <div
            v-if="generalError"
            class="bg-error/10 border border-error rounded-lg p-3 mt-4"
          >
            <p class="text-error text-sm">{{ generalError }}</p>
          </div>

          <div>
            <button
              type="submit"
              :disabled="isSubmitting"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-accent-primary hover:bg-accent-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-primary transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <span v-if="isSubmitting">
                <i class="fas fa-spinner fa-spin mr-2"></i>
                Registering...
              </span>
              <span v-else> Register Company </span>
            </button>
          </div>
        </form>
      </div>
      <div class="text-center mt-4">
        <p class="text-sm text-text-secondary">
          Already have an account?
          <router-link
            to="/login"
            class="font-medium text-accent-primary hover:text-accent-secondary transition-colors duration-200"
            >Sign in</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "CompanySignup",
  setup() {
    const store = useStore();
    const router = useRouter();

    const companyName = ref("");
    const industry = ref("");
    const adminName = ref("");
    const email = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);
    const isEmailAvailable = ref(true);
    const isSubmitting = ref(false);

    const passwordError = ref("");
    const companyNameError = ref("");
    const generalError = ref("");

    const passwordsMatch = computed(() => {
      return !confirmPassword.value || password.value === confirmPassword.value;
    });

    const checkEmail = async () => {
      if (email.value) {
        try {
          isEmailAvailable.value = await store.dispatch(
            "auth/checkEmailAvailability",
            email.value
          );
        } catch (error) {
          console.error("Error checking email availability:", error);
          isEmailAvailable.value = false;
        }
      }
    };

    const resetEmailAvailability = () => {
      isEmailAvailable.value = true;
    };

    const togglePassword = (field) => {
      if (field === "password") {
        showPassword.value = !showPassword.value;
      } else if (field === "confirmPassword") {
        showConfirmPassword.value = !showConfirmPassword.value;
      }
    };

    const validatePassword = () => {
      const value = password.value;
      if (value.length < 8) {
        passwordError.value = "Password must be at least 8 characters long";
      } else if (!/[A-Z]/.test(value)) {
        passwordError.value =
          "Password must contain at least one uppercase letter";
      } else if (!/[a-z]/.test(value)) {
        passwordError.value =
          "Password must contain at least one lowercase letter";
      } else if (!/\d/.test(value)) {
        passwordError.value = "Password must contain at least one number";
      } else {
        passwordError.value = "";
      }
    };

    const clearErrors = () => {
      passwordError.value = "";
      companyNameError.value = "";
      generalError.value = "";
    };

    const handleSubmit = async () => {
      clearErrors();

      if (passwordError.value || !passwordsMatch.value) {
        return;
      }

      isSubmitting.value = true;

      try {
        await store.dispatch("auth/registerCompany", {
          companyName: companyName.value,
          industry: industry.value,
          adminName: adminName.value,
          email: email.value,
          password: password.value,
        });
        router.push("/dashboard");
      } catch (error) {
        console.error("Company registration failed:", error);

        // Handle specific error messages from backend
        if (error.response?.data?.msg) {
          switch (error.response.data.msg) {
            case "Company already registered":
              companyNameError.value =
                "This company name is already registered";
              break;
            case "Email already registered":
              // Email availability is already handled by the existing code
              break;
            default:
              generalError.value =
                error.response.data.error || error.response.data.msg;
              "Registration failed. Please try again.";
          }
        } else {
          generalError.value =
            "An unexpected error occurred. Please try again later.";
        }
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      companyName,
      industry,
      adminName,
      email,
      password,
      confirmPassword,
      showPassword,
      showConfirmPassword,
      isEmailAvailable,
      passwordsMatch,
      handleSubmit,
      togglePassword,
      checkEmail,
      resetEmailAvailability,
      passwordError,
      companyNameError,
      generalError,
      validatePassword,
      isSubmitting,
    };
  },
};
</script>
<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.bg-primary-bg {
  background-color: var(--color-primary-bg);
}

.bg-primary-card {
  background-color: var(--color-primary-card);
}

.bg-primary-lighter {
  background-color: var(--color-primary-lighter);
}

.text-text-primary {
  color: var(--color-text-primary);
}

.text-text-secondary {
  color: var(--color-text-secondary);
}

.text-accent-primary {
  color: var(--color-accent-primary);
}

.text-accent-secondary {
  color: var(--color-accent-secondary);
}

.bg-accent-primary {
  background-color: var(--color-accent-primary);
}

.hover\:bg-accent-secondary:hover {
  background-color: var(--color-accent-secondary);
}

.focus\:ring-accent-primary:focus {
  --tw-ring-color: var(--color-accent-primary);
}

.text-error {
  color: var(--color-error);
}

.border-error {
  border-color: var(--color-error);
}

/* Dark mode styles */
.dark .bg-primary-bg {
  background-color: var(--color-primary-darkest);
}

.dark .bg-primary-card {
  background-color: var(--color-primary-darker);
}

.dark .bg-primary-lighter {
  background-color: var(--color-primary-card);
}
</style>
