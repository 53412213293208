import { createRouter, createWebHistory } from "vue-router";
import api from "@/services/api";
import Dashboard from "@/views/Dashboard.vue";
import Artists from "@/views/Artists.vue";
import Releases from "@/views/Releases.vue";
import Contacts from "@/views/Contacts.vue";
import Organizations from "@/views/Organizations.vue";
import Login from "@/views/Login.vue";
import Signup from "@/views/Signup.vue";
import ReleasesCalendar from "@/views/ReleasesCalendar.vue";
import ArtHead from "@/views/ArtHead.vue";
import store from "@/store";
import Contracts from "@/views/Contracts.vue";
import CompanySignup from "@/views/CompanySignup.vue";
import UserManagement from "@/views/UserManagement.vue";
import AcceptInvitation from "@/views/AcceptInvitation.vue";
import UserProfile from "@/views/UserProfile.vue";

const routes = [
  {
    path: "/",
    name: "arthead",
    component: ArtHead,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/artists",
    name: "Artists",
    component: Artists,
    meta: { requiresAuth: true },
  },
  {
    path: "/releases",
    name: "Releases",
    component: Releases,
    meta: { requiresAuth: true },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: { requiresAuth: true },
  },
  {
    path: "/organizations",
    name: "Organizations",
    component: Organizations,
    meta: { requiresAuth: true },
  },
  {
    path: "/releases-calendar",
    name: "ReleasesCalendar",
    component: ReleasesCalendar,
    meta: { requiresAuth: true },
  },
  {
    path: "/contracts",
    name: "Contracts",
    component: Contracts,
    meta: { requiresAuth: true },
  },
  {
    path: "/company-signup",
    name: "CompanySignup",
    component: CompanySignup,
  },
  {
    path: "/user-management",
    name: "UserManagement",
    component: UserManagement,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/accept-invitation",
    name: "AcceptInvitation",
    component: AcceptInvitation,
    props: (route) => ({ token: route.query.token }),
    beforeEnter: async (to, from, next) => {
      const token = to.query.token;

      if (!token) {
        next("/login");
        return;
      }

      try {
        // Validate token with backend
        await api.validateInvitationToken(token);
        next();
      } catch (error) {
        next("/login");
      }
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserProfile,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    "/",
    "/login",
    "/signup",
    "/company-signup",
    "/accept-invitation",
  ];
  const authRequired = !publicPages.includes(to.path);

  await store.dispatch("auth/checkAuth");

  if (authRequired && !store.getters["auth/isAuthenticated"]) {
    return next("/login");
  }

  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!store.getters["company/isAdmin"]) {
      return next("/dashboard");
    }
  }

  next();
});

export default router;
