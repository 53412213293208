<template>
  <div
    class="bg-primary-lighter rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-300 flex items-center"
  >
    <div :class="`text-${color} mr-4`">
      <i :class="`fas ${icon} text-3xl`"></i>
    </div>
    <div>
      <h3 class="text-sm font-medium text-text-secondary mb-1">{{ title }}</h3>
      <p :class="`text-2xl font-bold text-${color}`">{{ value }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatCard",
  props: {
    title: String,
    value: [Number, String],
    icon: String,
    color: {
      type: String,
      default: "text-primary",
    },
  },
};
</script>
