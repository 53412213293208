import api from "@/services/api";

export default {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    async fetchProfile({ commit }) {
      try {
        const response = await api.getProfile();
        commit("auth/SET_USER", response.data, { root: true });
      } catch (error) {
        console.error("Error fetching profile:", error);
        throw error;
      }
    },

    async updateProfile({ dispatch }, profileData) {
      try {
        await api.updateProfile(profileData);
        await dispatch("fetchProfile");
      } catch (error) {
        console.error("Error Updating profile", error);
        throw error;
      }
    },

    async updateProfileImage({ dispatch }, formData) {
      try {
        await api.updateProfileImage(formData);
        await dispatch("fetchProfile");
      } catch (error) {
        console.error("Error updating profile image:", error);
        throw error;
      }
    },
    async changePassword({ dispatch }, { current_password, new_password }) {
      try {
        await api.changePassword({ current_password, new_password });
        await dispatch("fetchProfile");
      } catch (error) {
        console.error("Error changing password:", error);
        throw error;
      }
    },
  },
};
