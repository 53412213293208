import { createStore } from "vuex";
import auth from "./modules/auth";
import organizations from "./modules/organizations";
import artists from "./modules/artists";
import colorScheme from "./modules/colorScheme";
import company from "./modules/company";
import profile from "./modules/profile";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    organizations,
    artists,
    colorScheme,
    company,
    profile,
  },
});
