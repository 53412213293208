<template>
  <div class="dashboard-container">
    <div
      class="p-6 bg-gradient-to-br from-primary-bg to-primary-lighter min-h-screen"
    >
      <h1
        class="text-4xl font-bold mb-8 text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
      >
        Dashboard
      </h1>

      <!-- Overview Statistics -->
      <div class="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div
          v-for="stat in overviewStats"
          :key="stat.title"
          class="bg-primary-card p-6 rounded-lg shadow-md transform transition-all duration-normal hover:scale-105 hover:shadow-lg"
        >
          <h3 class="text-lg font-semibold text-text-primary">
            {{ stat.title }}
          </h3>
          <p class="text-3xl font-bold text-accent-primary">{{ stat.value }}</p>
          <p class="text-sm text-text-secondary">
            <span
              :class="
                stat.change.startsWith('-') ? 'text-error' : 'text-success'
              "
            >
              {{ stat.change }}
            </span>
            from last month
          </p>
        </div>
      </div>

      <!-- Main Dashboard Content -->
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <!-- Left Column -->
        <div class="lg:col-span-2 space-y-8">
          <!-- Artist Performance Chart -->
          <div class="bg-primary-card p-6 rounded-lg shadow-md">
            <h2 class="text-2xl font-bold mb-4 text-text-primary">
              Artist Performance
            </h2>
            <ArtistPerformanceChart :chartData="artistPerformanceData" />
          </div>
          <!-- Calendar Card -->
          <div class="bg-primary-card p-6 rounded-lg shadow-md h-[500px]">
            <h2 class="text-2xl font-bold mb-4 text-text-primary">
              Release Calendar
            </h2>
            <div class="h-[calc(100%-2rem)]">
              <CalendarCard />
            </div>
          </div>
          <!-- Task Management Widget -->
          <!-- Task Management Widget -->
  <div class="bg-primary-card p-6 rounded-lg shadow-md">
    <h2 class="text-2xl font-bold mb-4 text-text-primary">Tasks</h2>
    <ul class="space-y-2 mb-4">
      <li v-for="task in tasks" :key="task.id">
        <TaskItem
          :task="task"
          @update="handleTaskUpdate"
          @edit="openEditModal"
          @delete="openDeleteModal"
          @refresh="fetchTasks"
        />
      </li>
    </ul>
    <form @submit.prevent="addTask" class="flex">
      <input
        v-model="newTask"
        type="text"
        placeholder="New task..."
        class="w-full px-4 py-2 bg-primary-lighter border border-gray-600 rounded-l-md focus:outline-none focus:ring-2 focus:ring-accent-primary text-text-primary placeholder-text-secondary transition-all duration-normal"
      />
      <button
        type="submit"
        class="px-4 py-2 bg-accent-primary text-white rounded-r-md hover:bg-accent-primary-dark focus:outline-none focus:ring-2 focus:ring-accent-primary transition-all duration-normal"
      >
        Add
      </button>
    </form>
  </div>


          <!-- Edit Task Modal -->
          <BaseModal :is-open="isEditModalOpen" @close="closeEditModal">
            <template #header>
              <h3
                class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
              >
                Edit Task
              </h3>
            </template>
            <template #body>
              <DynamicForm
                :fields="taskFormFields"
                :initial-data="editingTask"
                @submit="handleEditTask"
                @cancel="closeEditModal"
              />
            </template>
          </BaseModal>

          <!-- Delete Task Modal -->
          <BaseModal :is-open="isDeleteModalOpen" @close="closeDeleteModal">
            <template #header>
              <h3
                class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-error to-error-light"
              >
                Delete Task
              </h3>
            </template>
            <template #body>
              <p class="text-text-primary mb-4">
                Are you sure you want to delete this task?
              </p>
              <div class="flex justify-end space-x-2">
                <button
                  @click="closeDeleteModal"
                  class="px-4 py-2 bg-primary-lighter text-text-primary rounded-md hover:bg-primary-card focus:outline-none focus:ring-2 focus:ring-accent-primary transition-all duration-normal"
                >
                  Cancel
                </button>
                <button
                  @click="confirmDeleteTask"
                  class="px-4 py-2 bg-error text-white rounded-md hover:bg-error-light focus:outline-none focus:ring-2 focus:ring-error transition-all duration-normal"
                >
                  Delete
                </button>
              </div>
            </template>
          </BaseModal>
        </div>

        <!-- Right Column -->
        <div class="space-y-8">
          <!-- Recent Activity Feed -->
          <div class="bg-primary-card p-6 rounded-lg shadow-md">
            <h2 class="text-2xl font-bold mb-4 text-text-primary">
              Recent Activity
            </h2>
            <ul class="space-y-4">
              <li
                v-for="activity in recentActivities"
                :key="activity.id"
                class="flex items-center space-x-3 transition-all duration-normal hover:bg-primary-lighter p-2 rounded-md"
              >
                <span
                  :class="activityIconClass(activity.type)"
                  class="p-2 rounded-full"
                >
                  <i :class="['fas', activityIcon(activity.type)]"></i>
                </span>
                <div>
                  <p class="text-sm font-medium text-text-primary">
                    {{ activity.description }}
                  </p>
                  <p class="text-xs text-text-secondary">
                    {{ formatDate(activity.timestamp) }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <!-- Upcoming Releases -->
          <div class="bg-primary-card p-6 rounded-lg shadow-md">
            <h2 class="text-2xl font-bold mb-4 text-text-primary">
              Upcoming Releases
            </h2>
            <ul class="space-y-4">
              <li
                v-for="release in upcomingReleases.slice(0, 10)"
                :key="release.id"
                class="flex items-center space-x-3 transition-all duration-normal hover:bg-primary-lighter p-2 rounded-md"
              >
                <span
                  class="p-2 rounded-full bg-primary-lighter bg-opacity-20 text-text-primary"
                >
                  <i class="fas fa-music"></i>
                </span>
                <div>
                  <p class="text-sm font-medium text-text-primary">
                    {{ release.name }} by {{ release.artist }}
                  </p>
                  <p class="text-xs text-text-secondary">
                    {{ formatDate(release.release_date) }}
                  </p>
                </div>
              </li>
            </ul>
            <router-link
              to="/releases-calendar"
              class="mt-4 inline-block text-accent-secondary hover:text-accent-secondary-light transition-colors duration-normal"
            >
              View full calendar
            </router-link>
          </div>

          <!-- Quick Action Buttons -->
          <div class="bg-primary-card p-6 rounded-lg shadow-md">
            <h2 class="text-2xl font-bold mb-4 text-text-primary">
              Quick Actions
            </h2>
            <div class="grid grid-cols-2 gap-4">
              <button
                v-for="action in quickActions"
                :key="action.title"
                @click="action.handler"
                class="p-3 bg-accent-primary bg-opacity-10 text-text-primary rounded-md hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-accent-primary transition-all duration-normal transform hover:scale-105"
              >
                <i :class="['fas', action.icon, 'mr-2']"></i>
                {{ action.title }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Notifications Center -->
    <div class="fixed bottom-4 right-4">
      <button
        @click="showNotifications = !showNotifications"
        class="p-3 bg-accent-primary text-white rounded-full aspect-square w-12 hover:bg-accent-primary-dark focus:outline-none focus:ring-2 focus:ring-accent-primary transition-all duration-normal transform hover:scale-110"
      >
        <i class="fas fa-bell"></i>
      </button>
      <transition
        enter-active-class="transition ease-out duration-300"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-200"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <div
          v-if="showNotifications"
          class="absolute bottom-16 right-0 w-80 bg-primary-card rounded-lg shadow-xl p-4"
        >
          <h3 class="text-lg font-semibold mb-2 text-text-primary">
            Notifications
          </h3>
          <ul class="space-y-2">
            <li
              v-for="notification in notifications"
              :key="notification.id"
              class="text-sm p-2 hover:bg-primary-lighter rounded-md transition-colors duration-normal text-text-secondary"
            >
              {{ notification.message }}
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.dashboard-container {
  @apply bg-primary-bg text-text-primary;
}

.bg-gradient-to-br {
  @apply from-primary-bg to-primary-lighter;
}

.form-checkbox {
  @apply rounded border-gray-600 text-accent-primary focus:ring-accent-primary;
}

.hover\:scale-105:hover {
  @apply transform scale-105;
}

.hover\:scale-110:hover {
  @apply transform scale-110;
}
</style>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import api from "@/services/api";
import BaseModal from "@/components/BaseModal.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import ArtistPerformanceChart from "@/components/ArtistPerformanceChart.vue";
import CalendarCard from "@/components/CalendarCard.vue";
import { useRouter } from "vue-router";
import { connectWebSocket, getSocket } from "@/services/websocket";
import TaskItem from "@/components/TaskItem.vue";
export default {
  name: "DashboardView",
  components: {
    BaseModal,
    DynamicForm,
    ArtistPerformanceChart,
    CalendarCard,
    TaskItem,
  },
  setup() {
    const router = useRouter();
    const overviewStats = ref([]);
    const artistPerformanceData = ref({});
    const upcomingReleases = ref([]);
    const recentActivities = ref([]);
    const tasks = ref([]);
    const newTask = ref("");
    const showNotifications = ref(false);
    const notifications = ref([]);
    const isEditModalOpen = ref(false);
    const isDeleteModalOpen = ref(false);
    const editingTask = ref(null);
    const deletingTask = ref(null);

    const taskFormFields = [
      {
        key: "title",
        label: "Task Title",
        type: "text",
        required: true,
      },
    ];

    const fetchTasks = async () => {
      try {
        const response = await api.getTasks();
        tasks.value = response.data;
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    const addTask = async () => {
      if (newTask.value.trim()) {
        try {
          const response = await api.createTask({
            title: newTask.value,
          });
          tasks.value.unshift(response.data);
          newTask.value = "";
        } catch (error) {
          console.error("Error adding task:", error);
        }
      }
    };
    const handleTaskUpdate = async (updatedTask) => {
  try {
    const index = tasks.value.findIndex(t => t.id === updatedTask.id);
    if (index !== -1) {
      tasks.value = [
        ...tasks.value.slice(0, index),
        updatedTask,
        ...tasks.value.slice(index + 1)
      ];
    }
  } catch (error) {
    console.error('Error updating task:', error);
    await fetchTasks(); // Refresh the list if there's an error
  }
};


    const updateTask = async (task) => {
      try {
        await api.updateTask(task.id, { completed: task.completed });
      } catch (error) {
        console.error("Error updating task:", error);
      }
    };

    const openEditModal = (task) => {
      if (task && task.id) {
        editingTask.value = { ...task };
        isEditModalOpen.value = true;
      } else {
        console.error("Attempted to edit an invalid task");
      }
    };
    const closeEditModal = () => {
      editingTask.value = null;
      isEditModalOpen.value = false;
    };

    const handleEditTask = async (updatedTask) => {
      try {
        if (!editingTask.value || !editingTask.value.id) {
          console.error("No task selected for editing");
          closeEditModal();
          return;
        }

        const response = await api.updateTask(
          editingTask.value.id,
          updatedTask
        );

        const index = tasks.value.findIndex(
          (t) => t.id === editingTask.value.id
        );

        if (index !== -1) {
          tasks.value[index] = response.data;
        } else {
          console.warn(
            "Edited task not found in the list. Refreshing task list."
          );
          await fetchTasks();
        }

        closeEditModal();
      } catch (error) {
        console.error("Error editing task:", error);
        // Optionally, you can show an error message to the user here
      }
    };

    const openDeleteModal = (task) => {
      deletingTask.value = task;
      isDeleteModalOpen.value = true;
    };

    const closeDeleteModal = () => {
      deletingTask.value = null;
      isDeleteModalOpen.value = false;
    };

    const confirmDeleteTask = async () => {
      if (deletingTask.value) {
        try {
          await api.deleteTask(deletingTask.value.id);
          tasks.value = tasks.value.filter(
            (t) => t.id !== deletingTask.value.id
          );
          closeDeleteModal();
        } catch (error) {
          console.error("Error deleting task:", error);
        }
      }
    };

    const quickActions = [
      {
        title: "Artist",
        handler: () => router.push("/artists"),
      },
      {
        title: "Release",
        handler: () => router.push("/releases"),
      },
      {
        title: "Organization",
        handler: () => router.push("/organizations"),
      },
      {
        title: "Contacts",
        handler: () => router.push("/contacts"),
      },
    ];

    const fetchDashboardData = async () => {
      try {
        const [
          statsResponse,
          activitiesResponse,
          tasksResponse,
          notificationsResponse,
          artistPerformanceResponse,
          releasesResponse,
        ] = await Promise.all([
          api.getDashboardStats(),
          api.getRecentActivities(),
          api.getTasks(),
          api.getNotifications(),
          api.getArtistPerformance(),
          api.getUpcomingReleases(),
        ]);

        overviewStats.value = statsResponse.data;
        recentActivities.value = activitiesResponse.data;
        tasks.value = tasksResponse.data;
        notifications.value = notificationsResponse.data;
        artistPerformanceData.value = processArtistPerformanceData(
          artistPerformanceResponse.data
        );
        upcomingReleases.value = releasesResponse.data;
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    const processArtistPerformanceData = (data) => {
      return {
        labels: data.map((item) => item.name),
        datasets: [
          {
            label: "Performance Score",
            data: data.map((item) => item.score),
            backgroundColor: "rgba(138, 43, 226, 0.2)",
            borderColor: "rgba(138, 43, 226, 1)",
            borderWidth: 1,
          },
        ],
      };
    };

    const activityIconClass = (type) => {
      const classes = {
        artist: "bg-accent-purple bg-opacity-20 text-accent-purple",
        release: "bg-accent-teal bg-opacity-20 text-accent-teal",
        contract: "bg-warning bg-opacity-20 text-warning",
        other: "bg-gray-500 bg-opacity-20 text-gray-500",
      };
      return classes[type] || classes.other;
    };

    const activityIcon = (type) => {
      const icons = {
        artist: "fa-user",
        release: "fa-music",
        contract: "fa-file-signature",
        other: "fa-info-circle",
      };
      return icons[type] || icons.other;
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    };

    onMounted(async () => {
      await fetchDashboardData();
      fetchTasks();
      const token = localStorage.getItem("token");
      connectWebSocket(token);
      const socket = getSocket();
      if (socket) {
        socket.on("notification", (data) => {
          notifications.value.unshift(data);
        });
      }
    });

    onUnmounted(() => {
      const socket = getSocket();
      if (socket) {
        socket.off("notification");
      }
    });

    return {
      overviewStats,
      artistPerformanceData,
      upcomingReleases,
      recentActivities,
      tasks,
      newTask,
      quickActions,
      showNotifications,
      notifications,
      updateTask,
      addTask,
      activityIconClass,
      activityIcon,
      formatDate,
      isEditModalOpen,
      isDeleteModalOpen,
      editingTask,
      taskFormFields,
      openEditModal,
      closeEditModal,
      handleEditTask,
      openDeleteModal,
      closeDeleteModal,
      confirmDeleteTask,
      handleTaskUpdate
    };
  },
};
</script>
