<template>
  <div class="min-h-screen bg-primary-bg py-8">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Profile Header -->
      <div class="relative mb-8">
        <!-- Cover Image -->
        <div
          class="h-48 w-full rounded-xl bg-gradient-to-r from-accent-primary to-accent-secondary"
        ></div>

        <!-- Profile Info -->
        <div
          class="absolute bottom-0 transform translate-y-1/2 left-8 flex items-end space-x-6"
        >
          <!-- Profile Image -->
          <div class="relative group">
            <div
              class="w-32 h-32 rounded-full ring-4 ring-primary-bg overflow-hidden bg-primary-lighter"
            >
              <img
                v-if="profile.profile_image"
                :src="getProfileImageUrl(profile.profile_image)"
                :alt="profile.name"
                class="w-full h-full object-cover"
              />
              <div
                v-else
                class="w-full h-full flex items-center justify-center"
              >
                <span class="text-4xl text-text-secondary">
                  {{ getInitials(profile.name || profile.email) }}
                </span>
              </div>
            </div>
            <div
              class="absolute inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
              @click="triggerImageUpload"
            >
              <i class="fas fa-camera text-white text-xl"></i>
            </div>
            <input
              type="file"
              ref="imageInput"
              class="hidden"
              accept="image/*"
              @change="handleImageUpload"
            />
          </div>

          <div class="pb-4">
            <h1 class="text-2xl font-bold text-text-primary">
              {{ profile.name || profile.email }}
            </h1>
            <p class="text-text-secondary">{{ profile.position }}</p>
          </div>
        </div>

        <!-- Actions -->
        <div class="absolute bottom-0 transform translate-y-1/2 right-8">
          <button
            @click="toggleEditMode"
            class="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium shadow-sm"
            :class="[
              isEditing
                ? 'bg-gray-700 text-white hover:bg-gray-600'
                : 'bg-accent-primary text-white hover:bg-accent-secondary',
            ]"
          >
            <i :class="['fas', isEditing ? 'fa-times' : 'fa-edit', 'mr-2']"></i>
            {{ isEditing ? "Cancel Editing" : "Edit Profile" }}
          </button>
        </div>
      </div>

      <!-- Main Content -->
      <div class="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-3">
        <!-- Left Sidebar -->
        <div class="lg:col-span-1 space-y-6">
          <!-- Quick Info Card -->
          <div class="bg-primary-card rounded-xl shadow-lg p-6 mt-4">
            <h2 class="text-lg font-semibold text-text-primary mb-4">
              Contact Information
            </h2>
            <div class="space-y-4">
              <div class="flex items-center">
                <i class="fas fa-envelope w-5 text-accent-primary"></i>
                <span class="ml-3 text-text-primary">{{ profile.email }}</span>
              </div>
              <div class="flex items-center">
                <i class="fas fa-phone w-5 text-accent-primary"></i>
                <span class="ml-3 text-text-primary">
                  {{ profile.phone || "No phone number added" }}
                </span>
              </div>
              <div class="flex items-center">
                <i class="fas fa-globe w-5 text-accent-primary"></i>
                <span class="ml-3 text-text-primary">
                  {{ profile.timezone || "No timezone set" }}
                </span>
              </div>
            </div>
          </div>

          <!-- Social Links -->
          <div class="bg-primary-card rounded-xl shadow-lg p-6">
            <h2 class="text-lg font-semibold text-text-primary mb-4">
              Social Links
            </h2>
            <div class="space-y-4">
              <template
                v-if="Object.keys(profile.social_links || {}).length > 0"
              >
                <a
                  v-for="(link, platform) in profile.social_links"
                  :key="platform"
                  :href="link"
                  target="_blank"
                  class="flex items-center px-4 py-2 rounded-lg bg-primary-lighter hover:bg-primary-bg transition-colors duration-200"
                >
                  <i
                    :class="[getSocialIcon(platform), 'text-accent-primary']"
                  ></i>
                  <span class="ml-3 text-text-primary capitalize">{{
                    platform
                  }}</span>
                  <i
                    class="fas fa-external-link-alt ml-auto text-text-secondary"
                  ></i>
                </a>
              </template>
              <div v-else class="text-text-secondary text-center py-4">
                No social links added
              </div>
            </div>
          </div>

          <!-- Account Security -->
          <div class="bg-primary-card rounded-xl shadow-lg p-6">
            <h2 class="text-lg font-semibold text-text-primary mb-4">
              Account Security
            </h2>
            <button
              @click="showChangePasswordModal = true"
              class="w-full px-4 py-2 text-sm rounded-lg bg-primary-lighter hover:bg-primary-bg transition-colors duration-200 text-text-primary"
            >
              <i class="fas fa-lock mr-2 text-accent-primary"></i>
              Change Password
            </button>
          </div>
        </div>

        <!-- Main Content Area -->
        <div class="lg:col-span-2 space-y-6">
          <!-- Profile Form -->
          <form
            v-if="isEditing"
            @submit.prevent="saveProfile"
            class="space-y-6 mt-4"
          >
            <div class="bg-primary-card rounded-xl shadow-lg p-6">
              <h2 class="text-lg font-semibold text-text-primary mb-4">
                Basic Information
              </h2>

              <!-- Name Input -->
              <div class="mb-4">
                <label
                  class="block text-sm font-medium text-text-secondary mb-2"
                >
                  Full Name
                </label>
                <input
                  v-model="editForm.name"
                  type="text"
                  class="w-full px-4 py-2 rounded-lg bg-primary-lighter border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
                  :class="{ 'border-red-500': errors.name }"
                  placeholder="Enter your full name"
                />
                <p v-if="errors.name" class="mt-1 text-sm text-red-500">
                  {{ errors.name }}
                </p>
              </div>

              <!-- Position Input -->
              <div class="mb-4">
                <label
                  class="block text-sm font-medium text-text-secondary mb-2"
                >
                  Position
                </label>
                <input
                  v-model="editForm.position"
                  type="text"
                  class="w-full px-4 py-2 rounded-lg bg-primary-lighter border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
                  :class="{ 'border-red-500': errors.position }"
                  placeholder="Enter your position"
                />
                <p v-if="errors.position" class="mt-1 text-sm text-red-500">
                  {{ errors.position }}
                </p>
              </div>

              <!-- Phone Input -->
              <div class="mb-4">
                <label
                  class="block text-sm font-medium text-text-secondary mb-2"
                >
                  Phone Number
                </label>
                <input
                  v-model="editForm.phone"
                  type="tel"
                  class="w-full px-4 py-2 rounded-lg bg-primary-lighter border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
                  :class="{ 'border-red-500': errors.phone }"
                  placeholder="Enter your phone number"
                />
                <p v-if="errors.phone" class="mt-1 text-sm text-red-500">
                  {{ errors.phone }}
                </p>
              </div>

              <!-- Timezone Select -->
              <div class="mb-4">
                <label
                  class="block text-sm font-medium text-text-secondary mb-2"
                >
                  Timezone
                </label>
                <select
                  v-model="editForm.timezone"
                  class="w-full px-4 py-2 rounded-lg bg-primary-lighter border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
                >
                  <option value="">Select timezone</option>
                  <option v-for="tz in timezones" :key="tz" :value="tz">
                    {{ tz }}
                  </option>
                </select>
              </div>

              <!-- Bio Input -->
              <div>
                <label
                  class="block text-sm font-medium text-text-secondary mb-2"
                >
                  Bio
                </label>
                <textarea
                  v-model="editForm.bio"
                  rows="4"
                  class="w-full px-4 py-2 rounded-lg bg-primary-lighter border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
                  placeholder="Tell us about yourself"
                ></textarea>
              </div>
            </div>

            <!-- Social Links -->
            <div class="bg-primary-card rounded-xl shadow-lg p-6">
              <h2 class="text-lg font-semibold text-text-primary mb-4">
                Social Links
              </h2>
              <div class="space-y-4">
                <div
                  v-for="platform in socialPlatforms"
                  :key="platform.id"
                  class="flex items-center gap-4"
                >
                  <div class="w-8 text-center">
                    <i :class="[platform.icon, 'text-accent-primary']"></i>
                  </div>
                  <input
                    v-model="editForm.social_links[platform.id]"
                    type="url"
                    class="flex-1 px-4 py-2 rounded-lg bg-primary-lighter border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
                    :placeholder="`${platform.label} URL`"
                  />
                </div>
              </div>
            </div>

            <!-- Save Button -->
            <div class="flex justify-end">
              <button
                type="submit"
                :disabled="isSaving"
                class="px-6 py-2 bg-accent-primary text-white rounded-lg hover:bg-accent-secondary transition-colors duration-200 disabled:opacity-50 flex items-center"
              >
                <i class="fas fa-save mr-2"></i>
                {{ isSaving ? "Saving..." : "Save Changes" }}
              </button>
            </div>
          </form>

          <!-- Profile View -->
          <template v-else>
            <!-- About Section -->
            <div class="bg-primary-card rounded-xl shadow-lg p-6 mt-4">
              <h2 class="text-lg font-semibold text-text-primary mb-4">
                About
              </h2>
              <p class="text-text-secondary whitespace-pre-line">
                {{ profile.bio || "No bio added yet." }}
              </p>
            </div>

            <!-- Activity Section -->
            <div class="bg-primary-card rounded-xl shadow-lg p-6">
              <h2 class="text-lg font-semibold text-text-primary mb-4">
                Recent Activity
              </h2>
              <div class="space-y-4">
                <div class="flex items-center text-text-secondary">
                  <i class="fas fa-clock w-5"></i>
                  <span class="ml-3"
                    >Last login: {{ formatDate(profile.last_login) }}</span
                  >
                </div>
                <div class="flex items-center text-text-secondary">
                  <i class="fas fa-calendar w-5"></i>
                  <span class="ml-3"
                    >Member since: {{ formatDate(profile.created_at) }}</span
                  >
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Change Password Modal -->
    <BaseModal
      :is-open="showChangePasswordModal"
      @close="closeChangePasswordModal"
      size="sm"
    >
      <template #header>Change Password</template>
      <template #body>
        <form @submit.prevent="handleChangePassword" class="space-y-4">
          <!-- Current Password -->
          <div>
            <label class="block text-sm font-medium text-text-secondary mb-2">
              Current Password
            </label>
            <div class="relative">
              <input
                v-model="passwordForm.current_password"
                :type="showCurrentPassword ? 'text' : 'password'"
                class="w-full px-4 py-2 pr-10 rounded-lg bg-primary-darker border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
                :class="{ 'border-red-500': passwordErrors.current_password }"
                placeholder="Enter current password"
              />
              <button
                type="button"
                @click="showCurrentPassword = !showCurrentPassword"
                class="absolute inset-y-0 right-0 pr-3 flex items-center"
                tabindex="-1"
              >
                <i
                  :class="[
                    'fas',
                    showCurrentPassword ? 'fa-eye-slash' : 'fa-eye',
                    'text-text-secondary hover:text-text-primary',
                  ]"
                ></i>
              </button>
            </div>
            <p
              v-if="passwordErrors.current_password"
              class="mt-1 text-sm text-red-500"
            >
              {{ passwordErrors.current_password }}
            </p>
          </div>

          <!-- New Password -->
          <div>
            <label class="block text-sm font-medium text-text-secondary mb-2">
              New Password
            </label>
            <div class="relative">
              <input
                v-model="passwordForm.new_password"
                :type="showNewPassword ? 'text' : 'password'"
                class="w-full px-4 py-2 pr-10 rounded-lg bg-primary-darker border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
                :class="{ 'border-red-500': passwordErrors.new_password }"
                placeholder="Enter new password"
              />
              <button
                type="button"
                @click="showNewPassword = !showNewPassword"
                class="absolute inset-y-0 right-0 pr-3 flex items-center"
                tabindex="-1"
              >
                <i
                  :class="[
                    'fas',
                    showNewPassword ? 'fa-eye-slash' : 'fa-eye',
                    'text-text-secondary hover:text-text-primary',
                  ]"
                ></i>
              </button>
            </div>
            <p
              v-if="passwordErrors.new_password"
              class="mt-1 text-sm text-red-500"
            >
              {{ passwordErrors.new_password }}
            </p>
          </div>

          <!-- Confirm New Password -->
          <div>
            <label class="block text-sm font-medium text-text-secondary mb-2">
              Confirm New Password
            </label>
            <div class="relative">
              <input
                v-model="passwordForm.confirm_password"
                :type="showConfirmPassword ? 'text' : 'password'"
                class="w-full px-4 py-2 pr-10 rounded-lg bg-primary-darker border border-gray-700 text-text-primary focus:outline-none focus:ring-2 focus:ring-accent-primary"
                :class="{ 'border-red-500': passwordErrors.confirm_password }"
                placeholder="Confirm new password"
              />
              <button
                type="button"
                @click="showConfirmPassword = !showConfirmPassword"
                class="absolute inset-y-0 right-0 pr-3 flex items-center"
                tabindex="-1"
              >
                <i
                  :class="[
                    'fas',
                    showConfirmPassword ? 'fa-eye-slash' : 'fa-eye',
                    'text-text-secondary hover:text-text-primary',
                  ]"
                ></i>
              </button>
            </div>
            <p
              v-if="passwordErrors.confirm_password"
              class="mt-1 text-sm text-red-500"
            >
              {{ passwordErrors.confirm_password }}
            </p>
          </div>
        </form>
      </template>
      <template #footer>
        <button
          @click="closeChangePasswordModal"
          class="px-4 py-2 btn btn-secondary text-text-primary hover:text-text-primary transition-colors duration-200"
        >
          Cancel
        </button>
        <button
          @click="handleChangePassword"
          :disabled="isChangingPassword"
          class="px-4 py-2 bg-accent-primary text-white rounded-lg hover:bg-accent-secondary transition-colors duration-200 disabled:opacity-50 flex items-center"
        >
          <i
            :class="[
              'fas',
              isChangingPassword ? 'fa-spinner fa-spin' : 'fa-key',
              'mr-2',
            ]"
          ></i>
          {{ isChangingPassword ? "Changing..." : "Change Password" }}
        </button>
      </template>
    </BaseModal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { format } from "date-fns";
import BaseModal from "@/components/BaseModal.vue";

const store = useStore();
const imageInput = ref(null);
const isEditing = ref(false);
const isSaving = ref(false);
const showChangePasswordModal = ref(false);
const isChangingPassword = ref(false);
const showCurrentPassword = ref(false);
const showNewPassword = ref(false);
const showConfirmPassword = ref(false);

const profile = computed(() => store.state.auth.user || {});

const errors = ref({});
const passwordErrors = ref({});

const editForm = ref({
  name: "",
  position: "",
  phone: "",
  bio: "",
  social_links: {},
  timezone: "",
});

const passwordForm = ref({
  current_password: "",
  new_password: "",
  confirm_password: "",
});

const socialPlatforms = [
  { id: "twitter", label: "Twitter", icon: "fab fa-twitter" },
  { id: "instagram", label: "Instagram", icon: "fab fa-instagram" },
  { id: "facebook", label: "Facebook", icon: "fab fa-facebook" },
  { id: "linkedin", label: "LinkedIn", icon: "fab fa-linkedin" },
  { id: "spotify", label: "Spotify", icon: "fab fa-spotify" },
  { id: "soundcloud", label: "SoundCloud", icon: "fab fa-soundcloud" },
  { id: "website", label: "Website", icon: "fas fa-globe" },
];

const timezones = [
  "America/New_York",
  "America/Chicago",
  "America/Denver",
  "America/Los_Angeles",
  "Europe/London",
  "Europe/Paris",
  "Asia/Tokyo",
  "Asia/Singapore",
  "Australia/Sydney",
  "Pacific/Auckland",
];

const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);
};

const formatDate = (date) => {
  if (!date) return "";
  return format(new Date(date), "MMM dd, yyyy");
};

const getProfileImageUrl = (image) => {
  return `${process.env.VUE_APP_API_URL}/uploads/${image}`;
};

const getSocialIcon = (platform) => {
  const platformConfig = socialPlatforms.find((p) => p.id === platform);
  return platformConfig ? platformConfig.icon : "fas fa-link";
};

const triggerImageUpload = () => {
  imageInput.value.click();
};

const handleImageUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  const formData = new FormData();
  formData.append("image", file);

  try {
    await store.dispatch("profile/updateProfileImage", formData);
  } catch (error) {
    console.error("Error uploading image:", error);
  }
};

// const startEditing = () => {
//   editForm.value = {
//     name: profile.value.name || "",
//     position: profile.value.position || "",
//     phone: profile.value.phone || "",
//     bio: profile.value.bio || "",
//     social_links: { ...profile.value.social_links } || {},
//     timezone: profile.value.timezone || "",
//   };
//   showEditModal.value = true;
// };

// const cancelEdit = () => {
//   showEditModal.value = false;
// };

const toggleEditMode = () => {
  if (isEditing.value) {
    isEditing.value = false;
    errors.value = {};
  } else {
    editForm.value = {
      name: profile.value.name || "",
      position: profile.value.position || "",
      phone: profile.value.phone || "",
      bio: profile.value.bio || "",
      social_links: { ...profile.value.social_links } || {},
      timezone: profile.value.timezone || "",
    };
    isEditing.value = true;
  }
};

const validateProfileForm = () => {
  const newErrors = {};

  if (!editForm.value.name?.trim()) {
    newErrors.name = "Name is required";
  } else if (editForm.value.name.trim().length < 2) {
    newErrors.name = "Name must be at least 2 characters";
  }

  if (editForm.value.phone) {
    const phonePattern = /^\+?1?\d{9,15}$/;
    if (!phonePattern.test(editForm.value.phone)) {
      newErrors.phone = "Invalid phone number format";
    }
  }

  errors.value = newErrors;
  return Object.keys(newErrors).length === 0;
};

const validatePasswordForm = () => {
  const newErrors = {};

  if (!passwordForm.value.current_password) {
    newErrors.current_password = "Current password is required";
  }

  if (!passwordForm.value.new_password) {
    newErrors.new_password = "New password is required";
  } else if (passwordForm.value.new_password.length < 8) {
    newErrors.new_password = "Password must be at least 8 characters";
  }

  if (passwordForm.value.new_password !== passwordForm.value.confirm_password) {
    newErrors.confirm_password = "Passwords do not match";
  }

  passwordErrors.value = newErrors;
  return Object.keys(newErrors).length === 0;
};

const saveProfile = async () => {
  if (!validateProfileForm()) return;

  try {
    isSaving.value = true;
    await store.dispatch("profile/updateProfile", editForm.value);
    isEditing.value = false;
    errors.value = {};
  } catch (error) {
    const errorMessage = error.response?.data?.error;
    if (errorMessage) {
      errors.value = { api: errorMessage };
    }
  } finally {
    isSaving.value = false;
  }
};

const handleChangePassword = async () => {
  if (!validatePasswordForm()) return;

  try {
    isChangingPassword.value = true;
    await store.dispatch("profile/changePassword", {
      current_password: passwordForm.value.current_password,
      new_password: passwordForm.value.new_password,
    });
    closeChangePasswordModal();
  } catch (error) {
    const errorMessage = error.response?.data?.error;
    if (errorMessage) {
      passwordErrors.value = { current_password: errorMessage };
    }
  } finally {
    isChangingPassword.value = false;
  }
};

const closeChangePasswordModal = () => {
  showChangePasswordModal.value = false;
  passwordForm.value = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };
  passwordErrors.value = {};
  showCurrentPassword.value = false;
  showNewPassword.value = false;
  showConfirmPassword.value = false;
};

onMounted(() => {
  store.dispatch("profile/fetchProfile");
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
