<template>
  <div class="bg-primary-card rounded-lg shadow-lg p-6">
    <!-- <h3 class="text-xl font-semibold text-text-primary mb-4">
      Artist Performance
    </h3> -->
    <div class="chart-controls flex justify-between items-center">
      <div class="relatvie">
        <select
          v-model="chartType"
          @change="updateChartType"
          class="appearance-none bg-primary-lighter border border-primary-darker text-text-primary py-2 px-3 pr-8 rounded-md focus:outline-none focus:ring-2 focus:ring-accent-primary focus:border-accent-primary text-sm"
        >
          <option value="bar">Bar Chart</option>
          <option value="line">Line Chart</option>
          <option value="pie">Pie Chart</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-text-secondary"
        >
          <!-- <i class="fas fa-chevron-down text-xs"></i> -->
        </div>
      </div>
      <div class="relative">
        <button
          @click="downloadChart"
          class="btn btn-secondary text-sm px-3 py-1 flex items-center"
        >
          <i class="fas fa-download mr-2"></i>
          Download Chart
        </button>
      </div>
    </div>
    <div class="chart-container">
      <canvas ref="chartRef"></canvas>
      <div v-if="loading" class="loading-overlay">
        <span class="text-text-secondary">Loading...</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.chart-container {
  position: relative;
  height: 400px;
  width: 100%;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(var(--color-primary-bg-rgb), 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}
</style>
<script>
import { onMounted, ref, watch, nextTick } from "vue";
import Chart from "chart.js/auto";

export default {
  name: "ArtistPerformanceChart",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const chartInstance = ref(null);
    const chartRef = ref(null);
    const chartType = ref("bar");

    const createChart = () => {
      if (chartRef.value) {
        const ctx = chartRef.value.getContext("2d");
        if (chartInstance.value) {
          chartInstance.value.destroy();
        }
        chartInstance.value = new Chart(ctx, {
          type: chartType.value,
          data: props.chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                labels: {
                  color: "var(--color-text-primary)",
                  font: {
                    family: "Inter, sans-serif",
                  },
                },
              },
              tooltip: {
                borderWidth: 1,
                cornerRadius: 4,
                padding: 10,
                titleFont: {
                  family: "Inter, sans-serif",
                  size: 14,
                  weight: "bold",
                },
                bodyFont: {
                  family: "Inter, sans-serif",
                  size: 12,
                },
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  color: "var(--color-primary-lighter)",
                },
                ticks: {
                  color: "var(--color-text-secondary)",
                  font: {
                    family: "Inter, sans-serif",
                  },
                },
                title: {
                  display: true,
                  text: "Performance Score",
                  color: "var(--color-text-primary)",
                  font: {
                    family: "Inter, sans-serif",
                    size: 14,
                    weight: "bold",
                  },
                },
              },
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  color: "var(--color-text-secondary)",
                  font: {
                    family: "Inter, sans-serif",
                  },
                },
                title: {
                  display: true,
                  text: "Artist",
                  color: "var(--color-text-primary)",
                  font: {
                    family: "Inter, sans-serif",
                    size: 14,
                    weight: "bold",
                  },
                },
              },
            },
            animation: {
              duration: 1000,
              easing: "easeInOutQuart",
            },
          },
        });
      }
    };

    const updateChartType = () => {
      createChart();
    };

    const downloadChart = () => {
      const link = document.createElement("a");
      link.href = chartRef.value.toDataURL("image/png");
      link.download = "artist_performance_chart.png";
      link.click();
    };

    onMounted(() => {
      nextTick(() => {
        createChart();
      });
    });

    watch(
      () => props.chartData,
      () => {
        nextTick(() => {
          createChart();
        });
      },
      { deep: true }
    );

    watch(
      () => props.loading,
      (newValue) => {
        emit("loading-change", newValue);
      }
    );

    return { chartRef, chartType, updateChartType, downloadChart };
  },
};
</script>
