import api from "@/services/api";

export default {
  namespaced: true,
  state: {
    user: null,
    token: localStorage.getItem("token") || null,
    company: null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    SET_COMPANY(state, company) {
      state.company = company;
    },
    CLEAR_AUTH(state) {
      state.user = null;
      state.token = null;
      state.company = null;
      localStorage.removeItem("token");
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
  },

  actions: {
    async checkAuth({ commit, dispatch }) {
      if (localStorage.getItem("token")) {
        api.setAuthHeader(localStorage.getItem("token"));
        try {
          const user = await dispatch("fetchCurrentUser");
          if (user) {
            commit("SET_USER", user);
            await dispatch("fetchCurrentCompany");
          } else {
            commit("CLEAR_AUTH");
            api.removeAuthHeader();
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          commit("CLEAR_AUTH");
          api.removeAuthHeader();
        }
      } else {
        commit("CLEAR_AUTH");
        api.removeAuthHeader();
      }
    },

    async fetchCurrentUser({ commit }) {
      try {
        const response = await api.getCurrentUser();
        const user = response.data;
        commit("SET_USER", user);
        return user;
      } catch (error) {
        console.error("Error fetching current user:", error);
        throw error;
      }
    },

    async fetchCurrentCompany({ commit }) {
      try {
        const response = await api.getCurrentCompany();
        const company = response.data;
        commit("SET_COMPANY", company);
        return company;
      } catch (error) {
        console.error("Error fetching current company:", error);
        throw error;
      }
    },

    async login({ commit, dispatch }, credentials) {
      try {
        const response = await api.login(credentials);
        const { access_token, user } = response.data;
        commit("SET_USER", user);
        commit("SET_TOKEN", access_token);
        api.setAuthHeader(access_token);
        await dispatch("fetchCurrentCompany");
        commit("CLEAR_ERROR");
        return user;
      } catch (error) {
        commit("SET_ERROR", error.response?.data?.message || "Login failed");
        throw error;
      }
    },

    async register({ commit, dispatch }, userData) {
      try {
        const response = await api.signup(userData);
        const { access_token, user } = response.data;
        commit("SET_USER", user);
        commit("SET_TOKEN", access_token);
        api.setAuthHeader(access_token);
        await dispatch("fetchCurrentCompany");
        commit("CLEAR_ERROR");
        return user;
      } catch (error) {
        commit(
          "SET_ERROR",
          error.response?.data?.message || "Registration failed"
        );
        throw error;
      }
    },

    async checkEmailAvailability({ commit }, email) {
      try {
        const response = await api.checkEmailAvailability(email);
        return response.data.available;
      } catch (error) {
        console.error("Error checking email availability:", error);
        commit("SET_ERROR", "Error checking email availability");
        throw error;
      }
    },
    async registerCompany({ commit }, companyData) {
      try {
        const response = await api.registerCompany(companyData);
        const { access_token, user, company } = response.data;
        commit("SET_USER", user);
        commit("SET_TOKEN", access_token);
        commit("SET_COMPANY", company);
        api.setAuthHeader(access_token);
        return user;
      } catch (error) {
        console.error("Company registration failed:", error);
        throw error;
      }
    },

    async logout({ commit, state }) {
      try {
        if (state.token) {
          await api.logout(state.token);
        }
        commit("CLEAR_AUTH");
        api.removeAuthHeader();
      } catch (error) {
        console.error("Logout failed:", error);
        commit("CLEAR_AUTH");
        api.removeAuthHeader();
      }
    },

    clearError({ commit }) {
      commit("CLEAR_ERROR");
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    currentUser: (state) => state.user,
    currentCompany: (state) => state.company,
    error: (state) => state.error,
  },
};
