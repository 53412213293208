<template>
  <div
    class="min-h-screen flex items-center justify-center bg-primary-bg py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8">
      <div
        class="bg-primary-card p-8 rounded-xl shadow-lg transition-all duration-300 hover:shadow-xl"
      >
        <div class="text-center">
          <h2
            class="mt-6 text-3xl font-extrabold text-text-primary text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
          >
            Accept Invitation
          </h2>
          <p class="mt-2 text-sm text-text-secondary" v-if="email">
            Complete your account setup for {{ email }}
          </p>
        </div>

        <!-- Show error if token validation fails -->
        <div
          v-if="tokenError"
          class="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg"
        >
          {{ tokenError }}
        </div>

        <form
          v-if="!tokenError"
          class="mt-8 space-y-6"
          @submit.prevent="handleSubmit"
        >
          <div class="space-y-4">
            <div>
              <label
                for="name"
                class="block text-sm font-medium text-text-secondary"
              >
                Full Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                v-model="name"
                required
                :class="[
                  'mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 border placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary focus:z-10 sm:text-sm transition-all duration-300',
                  errors.name
                    ? 'border-red-500 focus:border-red-500'
                    : 'border-gray-700 focus:border-accent-primary',
                ]"
                placeholder="Full Name"
              />
              <p v-if="errors.name" class="mt-1 text-sm text-red-500">
                {{ errors.name }}
              </p>
            </div>

            <div>
              <label
                for="password"
                class="block text-sm font-medium text-text-secondary"
              >
                Password
              </label>
              <div class="relative">
                <input
                  id="password"
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  required
                  :class="[
                    'mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 pr-10 border placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary  sm:text-sm transition-all duration-300',
                    errors.password
                      ? 'border-red-500 focus:border-red-500'
                      : 'border-gray-700 focus:border-accent-primary',
                  ]"
                  placeholder="Password"
                />
                <button
                  type="button"
                  @click="togglePassword('password')"
                  class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  tabindex="-1"
                >
                  <i
                    :class="[
                      'fas',
                      showPassword ? 'fa-eye-slash' : 'fa-eye',
                      'text-text-secondary hover:text-text-primary transition-colors duration-200',
                    ]"
                  ></i>
                </button>
              </div>
              <p v-if="errors.password" class="mt-1 text-sm text-red-500">
                {{ errors.password }}
              </p>
            </div>

            <div>
              <label
                for="confirmPassword"
                class="block text-sm font-medium text-text-secondary"
              >
                Confirm Password
              </label>
              <div class="relative">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  v-model="confirmPassword"
                  required
                  :class="[
                    'mt-1 appearance-none rounded-lg relative block w-full px-3 py-2 pr-10 border placeholder-text-secondary text-text-primary bg-primary-lighter focus:outline-none focus:ring-2 focus:ring-accent-primary sm:text-sm transition-all duration-300',
                    errors.confirmPassword
                      ? 'border-red-500 focus:border-red-500'
                      : 'border-gray-700 focus:border-accent-primary',
                  ]"
                  placeholder="Confirm Password"
                />
                <button
                  type="button"
                  @click="togglePassword('confirmPassword')"
                  class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  tabindex="-1"
                >
                  <i
                    :class="[
                      'fas',
                      showConfirmPassword ? 'fa-eye-slash' : 'fa-eye',
                      'text-text-secondary hover:text-text-primary transition-colors duration-200',
                    ]"
                  ></i>
                </button>
              </div>
              <p
                v-if="errors.confirmPassword"
                class="mt-1 text-sm text-red-500"
              >
                {{ errors.confirmPassword }}
              </p>
            </div>
          </div>

          <div>
            <button
              type="submit"
              :disabled="loading"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-accent-primary hover:bg-accent-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-primary transition-all duration-300 disabled:opacity-50"
            >
              {{ loading ? "Processing..." : "Accept Invitation" }}
            </button>
          </div>

          <!-- General API error message -->
          <p v-if="apiError" class="mt-2 text-sm text-center text-red-500">
            {{ apiError }}
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import api from "@/services/api";

const store = useStore();
const router = useRouter();
const route = useRoute();

const name = ref("");
const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const loading = ref(false);
const tokenError = ref(""); // Separate error for token validation
const apiError = ref(""); // Separate error for API calls
const errors = ref({
  name: "",
  password: "",
  confirmPassword: "",
});
const token = route.query.token;
const showPassword = ref(false);
const showConfirmPassword = ref(false);

const validateToken = async () => {
  if (!token) {
    tokenError.value = "No invitation token provided";
    router.push("/login");
    return false;
  }

  try {
    loading.value = true;
    const response = await api.validateInvitationToken(token);
    email.value = response.data.email;
    return true;
  } catch (err) {
    tokenError.value = err.response?.data?.error || "Invalid invitation";
    router.push("/login");
    return false;
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  const isValid = await validateToken();
  if (!isValid) {
    return;
  }
});

const validatePassword = (password) => {
  if (password.length < 8) return "Password must be at least 8 characters long";
  if (!/[A-Z]/.test(password))
    return "Password must contain at least one uppercase letter";
  if (!/[a-z]/.test(password))
    return "Password must contain at least one lowercase letter";
  if (!/\d/.test(password)) return "Password must contain at least one number";
  return null;
};
const togglePassword = (field) => {
  if (field === "password") {
    showPassword.value = !showPassword.value;
  } else if (field === "confirmPassword") {
    showConfirmPassword.value = !showConfirmPassword.value;
  }
};

const clearErrors = () => {
  errors.value = {
    name: "",
    password: "",
    confirmPassword: "",
  };
  apiError.value = "";
};

const handleSubmit = async () => {
  try {
    loading.value = true;
    clearErrors();

    // Validate name
    if (name.value.trim().length < 2) {
      errors.value.name = "Name must be at least 2 characters long";
      return;
    }

    // Validate password
    const passwordError = validatePassword(password.value);
    if (passwordError) {
      errors.value.password = passwordError;
      return;
    }

    // Check password match
    if (password.value !== confirmPassword.value) {
      errors.value.confirmPassword = "Passwords do not match";
      return;
    }

    // Use API service to accept invitation
    const response = await api.acceptInvitation({
      token,
      name: name.value.trim(),
      password: password.value,
    });

    // Store the token and user data
    await store.dispatch("auth/setToken", response.data.access_token);
    await store.dispatch("auth/setUser", response.data.user);

    router.push("/dashboard");
  } catch (err) {
    // Handle API errors
    apiError.value =
      err.response?.data?.error ||
      "Failed to accept invitation. Please try again.";
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.bg-primary-bg {
  background-color: var(--color-primary-bg);
}

.bg-primary-card {
  background-color: var(--color-primary-card);
}

.bg-primary-lighter {
  background-color: var(--color-primary-lighter);
}

.text-text-primary {
  color: var(--color-text-primary);
}

.text-text-secondary {
  color: var(--color-text-secondary);
}

.text-accent-primary {
  color: var(--color-accent-primary);
}

.text-accent-secondary {
  color: var(--color-accent-secondary);
}

.bg-accent-primary {
  background-color: var(--color-accent-primary);
}

.hover\:bg-accent-secondary:hover {
  background-color: var(--color-accent-secondary);
}

.focus\:ring-accent-primary:focus {
  --tw-ring-color: var(--color-accent-primary);
}

.text-error {
  color: var(--color-error);
}

.border-error {
  border-color: var(--color-error);
}

/* Dark mode styles */
.dark .bg-primary-bg {
  background-color: var(--color-primary-darkest);
}

.dark .bg-primary-card {
  background-color: var(--color-primary-darker);
}

.dark .bg-primary-lighter {
  background-color: var(--color-primary-card);
}
</style>
