<template>
  <div
    class="bg-primary-card rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden transform hover:-translate-y-1 hover:scale-102 cursor-pointer"
    @click="$emit('view', contract)"
  >
    <div class="p-4 border-b border-primary-lighter">
      <h3 class="text-lg font-semibold text-text-primary-primary truncate">
        {{ contract.artist.name }}
      </h3>
      <div class="flex items-center justify-between space-x-2">
        <p class="text-sm text-text-secondary truncate">
          {{ contract.contract_type }}
        </p>
        <span
          :class="[
            'text-sm font-medium px-2 py-1 rounded-full  text-text-primary',
            getContractStatusClass(contract.contract_status),
          ]"
        >
          {{ contract.contract_status }}
        </span>
      </div>
    </div>
    <div class="p-4 space-y-2">
      <div class="flex justify-between items-center">
        <span class="text-sm text-text-secondary">Start Date</span>
        <span class="text-sm font-medium">{{
          formatDate(contract.start_date)
        }}</span>
      </div>
      <div class="flex justify-between items-center">
        <span class="text-sm text-text-secondary">End Date</span>
        <span class="text-sm font-medium">{{
          formatDate(contract.end_date)
        }}</span>
      </div>
      <div class="flex justify-between items-center">
        <span class="text-sm text-text-secondary">Status</span>
        <span
          :class="[
            'text-sm font-medium px-2 py-1 rounded-full  text-text-primary',
            getStatusClass(contract.status),
          ]"
        >
          {{ contract.status }}
        </span>
      </div>
    </div>
    <div class="bg-primary-lighter p-4 flex justify-end space-x-2">
      <button
        @click.stop="$emit('edit', contract)"
        class="btn btn-sm btn-accent-tertiary"
        title="Edit Contract"
      >
        <i class="fas fa-edit"></i>
      </button>
      <button
        @click.stop="$emit('delete', contract.id)"
        class="btn btn-sm btn-error"
        title="Delete Contract"
      >
        <i class="fas fa-trash-alt"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractCard",
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  emits: ["view", "edit", "delete"],
  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },
    getStatusClass(status) {
      const statusClasses = {
        Signed: "bg-green-400 text-white",
        Unsigned: "bg-warning text-white",
        "Counter-signed": "bg-info text-white",
      };
      return statusClasses[status] || "bg-primary-card text-text-primary";
    },
    getContractStatusClass(status) {
      const statusClasses = {
        Draft: "bg-info text-white",
        Active: "bg-green-400 text-white",
        Expired: "bg-error text-white",
        Terminated: "bg-warning text-white",
      };
      return statusClasses[status] || "bg-primary-lighter text-text-secondary";
    },
  },
};
</script>
