<template>
  <div class="h-full bg-primary-lighter rounded-lg shadow-md overflow-hidden">
    <FullCalendar :options="calendarOptions" class="h-full" />
  </div>
</template>

<style scoped>
:deep(.fc) {
  --fc-border-color: theme("colors.primary.darker");
  --fc-button-bg-color: theme("colors.accent.primary.DEFAULT");
  --fc-button-border-color: theme("colors.accent.primary.DEFAULT");
  --fc-button-hover-bg-color: theme("colors.accent.primary.600");
  --fc-button-hover-border-color: theme("colors.accent.primary.700");
  --fc-button-active-bg-color: theme("colors.accent.primary.700");
  --fc-button-active-border-color: theme("colors.accent.primary.800");
  --fc-event-bg-color: theme("colors.accent.secondary.DEFAULT");
  --fc-event-border-color: theme("colors.accent.secondary.600");
  --fc-event-text-color: theme("colors.white");
  --fc-today-bg-color: theme("colors.accent.secondary.100");
}

:deep(.fc-view) {
  background-color: theme("colors.primary.lighter");
}

:deep(.fc-highlight) {
  background-color: theme("colors.accent.secondary.DEFAULT");
  opacity: 0.3;
}
:deep(.fc-theme-standard) {
  background-color: theme("colors.primary.card");
}
:deep(.fc-popover) {
  background-color: theme("colors.primary.card");
}
:deep(.fc-toolbar-title) {
  color: theme("colors.text.primary");
}

:deep(.fc-col-header-cell) {
  background-color: theme("colors.primary.lighter");
  color: theme("colors.text.secondary");
}

:deep(.fc-daygrid-day-number),
:deep(.fc-daygrid-day-top) {
  color: theme("colors.text.primary");
}

:deep(.fc-event-title),
:deep(.fc-event-time) {
  color: theme("colors.text.primary");
}

:deep(.fc-day-today) {
  background-color: theme("colors.primary.lighter") !important;
}

:deep(.fc-button) {
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-primary;
}

:deep(.fc-button-primary:not(:disabled):active),
:deep(.fc-button-primary:not(:disabled).fc-button-active) {
  @apply shadow-inner;
}

:deep(.fc-daygrid-event) {
  @apply rounded-md shadow-sm transition-transform duration-200 ease-in-out;
}

:deep(.fc-daygrid-event:hover) {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

:deep(.fc-scroller) {
  height: auto !important;
}

:deep(.fc-day-grid-container) {
  height: auto !important;
}

:deep(.fc-row) {
  min-height: 2em;
}

:deep(.fc-timegrid-slot-label) {
  color: theme("colors.text.secondary");
}

:deep(.fc-list-day-cushion) {
  background-color: theme("colors.primary.lighter");
}

:deep(.fc-list-event-time),
:deep(.fc-list-event-title) {
  color: theme("colors.text.primary");
}

:deep(.fc-list-day-side-text),
:deep(.fc-list-day-text) {
  color: theme("colors.text.secondary");
}

:deep(.fc-list-event:hover td) {
  background-color: rgba(
    theme("colors.accent.primary.DEFAULT"),
    0.1
  ) !important;
}

:deep(.fc-list-event-title a),
:deep(.fc-list-event-time) {
  color: theme("colors.text.primary") !important;
}

:deep(.fc-list-event:hover .fc-list-event-title a),
:deep(.fc-list-event:hover .fc-list-event-time) {
  color: theme("colors.text.secondary") !important;
}
:deep(.fc-timegrid-axis) {
  background-color: theme("colors.primary.lighter");
}
</style>

<script>
import { ref, computed, onMounted } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import api from "@/services/api";

export default {
  name: "CalendarCard",
  components: {
    FullCalendar,
  },
  setup() {
    const releases = ref([]);

    const fetchReleases = async () => {
      try {
        const response = await api.getCalendarReleases();
        releases.value = response.data;
      } catch (error) {
        console.error("Error fetching releases:", error);
      }
    };

    const calendarOptions = computed(() => ({
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      initialView: "timeGridDay",
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      },
      events: releases.value.map((release) => ({
        id: release.id,
        title: release.name,
        start: release.release_date,
        allDay: true,
        backgroundColor: getEventColor(release.status),
        borderColor: getEventColor(release.status),
        textColor: release.status === "Planned" ? "#000000" : "#FFFFFF",
        extendedProps: { ...release },
      })),
      eventDidMount: (info) => {
        info.el.title = `${info.event.title} - ${info.event.extendedProps.artist.name}`;
      },
      height: "100%",
      aspectRatio: 1,
      expandRows: true,
      stickyHeaderDates: true,
      dayMaxEvents: true,
    }));

    const getEventColor = (status) => {
      switch (status) {
        case "In Production":
          return "#3B82F6"; // Blue
        case "Planned":
          return "#F59E0B"; // Light golden
        case "Released":
          return "#10B981"; // Green
        default:
          return "#6B7280"; // Gray
      }
    };

    onMounted(fetchReleases);

    return {
      calendarOptions,
    };
  },
};
</script>
