<template>
  <div class="flex h-screen overflow-hidden bg-primary-bg">
    <!-- Sidebar -->
    <nav
      v-if="isAuthenticated"
      :class="[
        'fixed inset-y-0 left-0 z-20 transition-all duration-300 ease-in-out',
        'bg-primary-bg backdrop-blur-md border-r border-gray-700/30',
        'w-64',
        isPinned
          ? 'transform-none'
          : !isSidebarOpen
          ? '-translate-x-full'
          : 'translate-x-0',
      ]"
      @mouseleave="handleMouseLeave"
      ref="sidebar"
    >
      <div class="h-full flex flex-col">
        <div
          class="flex items-center justify-between h-16 px-4 border-b border-gray-700/30"
        >
          <router-link to="/" class="flex items-center space-x-2">
            <img src="@/assets/logo.png" alt="Logo" class="h-8 w-auto" />
            <span class="text-xl font-bold text-gradient">Arthead</span>
          </router-link>
          <button
            @click="togglePin"
            class="group relative p-2 rounded-md hover:bg-gray-700/20 transition-colors duration-200"
          >
            <i
              :class="[
                'fas fa-thumbtack',
                isPinned ? 'rotate-45' : '',
                'text-text-secondary group-hover:text-text-primary transition-colors duration-200',
              ]"
            ></i>
            <!-- Tooltip -->
            <span
              class="absolute right-0 top-full mt-2 px-2 py-1 text-xs font-medium text-white bg-gray-900/90 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap"
            >
              {{ isPinned ? "Unpin sidebar" : "Pin sidebar" }}
            </span>
          </button>
        </div>

        <div class="flex-1 overflow-y-auto py-4 px-2">
          <nav class="space-y-1">
            <router-link
              v-for="item in filteredNavItems"
              :key="item.name"
              :to="item.href"
              class="group flex items-center px-3 py-2 text-sm font-medium rounded-md transition-all duration-200 ease-in-out"
              :class="[
                $route.path === item.href
                  ? 'bg-accent-primary/20 text-text-primary'
                  : 'text-text-secondary hover:bg-gray-700/20 hover:text-text-primary',
              ]"
            >
              <i
                :class="[
                  item.icon,
                  'mr-3 text-lg transition-colors duration-200',
                  $route.path === item.href
                    ? 'text-text-primary'
                    : 'text-text-secondary group-hover:text-text-primary',
                ]"
              ></i>
              {{ item.name }}
            </router-link>
          </nav>
        </div>

        <div class="p-4 border-t border-gray-700/30">
          <button
            @click="handleLogout"
            class="w-full flex items-center justify-center px-4 py-2 rounded-md text-sm font-medium text-white bg-accent-primary hover:bg-accent-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-secondary transition-all duration-200"
          >
            <i class="fas fa-sign-out-alt mr-2"></i>
            Log Out
          </button>
        </div>
      </div>
    </nav>

    <!-- Main content -->
    <div
      class="flex-1 flex flex-col transition-all duration-300 ease-in-out"
      :class="[isAuthenticated && isPinned ? 'ml-64' : 'ml-0']"
    >
      <!-- Top navigation -->
      <header class="bg-primary-bg shadow-md z-10 border-b border-gray-700/30">
        <div class="flex justify-between items-center h-16 px-10">
          <div class="flex items-center space-x-4">
            <div
              class="flex items-center space-x-2 cursor-pointer"
              @mouseenter="showSidebar"
              @click="toggleSidebar"
              v-if="!isPinned"
            >
              <img src="@/assets/logo.png" alt="Logo" class="h-8 w-auto" />
              <span class="text-xl font-bold text-gradient">Arthead</span>
            </div>
            <nav v-if="isAuthenticated" class="hidden md:flex space-x-4">
              <router-link
                to="/dashboard"
                class="nav-link"
                active-class="nav-link-active"
              >
                Dashboard
              </router-link>
              <router-link
                to="/releases-calendar"
                class="nav-link"
                active-class="nav-link-active"
              >
                Calendar
              </router-link>
            </nav>
          </div>
          <div class="flex items-center space-x-4">
            <nav v-if="!isAuthenticated" class="hidden md:flex space-x-4">
              <router-link
                to="/login"
                class="nav-link"
                active-class="nav-link-active"
              >
                Sign In
              </router-link>
              <router-link
                to="/company-signup"
                class="nav-link"
                active-class="nav-link-active"
              >
                Company Signup
              </router-link>
            </nav>
            <!-- Color scheme toggle -->
            <button
              @click="toggleColorScheme"
              class="bg-primary-card p-2 rounded-full w-10 text-text-secondary hover:text-text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-primary transition-colors duration-200"
              :aria-label="
                isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'
              "
            >
              <i :class="['fas', isDarkMode ? 'fa-sun' : 'fa-moon']"></i>
            </button>
            <!-- User Profile -->
            <div v-if="isAuthenticated" class="relative ml-3">
              <router-link
                to="/profile"
                class="group flex rounded-full focus:outline-none"
              >
                <div class="relative h-10 w-10">
                  <!-- Border container -->
                  <div
                    class="absolute inset-0 rounded-full bg-gradient-to-tr from-accent-primary to-accent-secondary opacity-70 group-hover:opacity-100 transition-opacity duration-300"
                  ></div>

                  <!-- Profile content container -->
                  <div
                    class="absolute inset-[2px] rounded-full overflow-hidden"
                  >
                    <div class="h-full w-full">
                      <img
                        v-if="currentUser?.profile_image"
                        :src="getProfileImageUrl(currentUser.profile_image)"
                        :alt="currentUser?.name || 'Profile'"
                        class="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                      />
                      <div
                        v-else
                        class="h-full w-full bg-primary-lighter flex items-center justify-center shadow-inner"
                      >
                        <span class="text-sm font-medium text-text-secondary">
                          {{
                            getInitials(
                              currentUser?.name || currentUser?.email || ""
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </header>

      <!-- Page Content -->
      <main class="flex-1 overflow-y-auto">
        <slot></slot>
      </main>
    </div>

    <!-- Sidebar Overlay -->
    <div
      v-if="isAuthenticated && isSidebarOpen && !isPinned"
      class="fixed inset-0 bg-black/30 z-10"
      @click="hideSidebar"
    ></div>
  </div>
</template>

<style scoped>
.nav-link {
  @apply text-text-secondary hover:text-text-primary transition-colors duration-200 px-3 py-2 rounded-md text-sm font-medium;
}

.nav-link-active {
  @apply bg-accent-primary bg-opacity-20 text-text-primary;
}

header {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
</style>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "AppLayout",

  setup() {
    const router = useRouter();
    const store = useStore();
    const isSidebarOpen = ref(false);
    const sidebar = ref(null);
    const isDarkMode = computed(() => store.getters["colorScheme/isDarkMode"]);
    const isAuthenticated = computed(
      () => store.getters["auth/isAuthenticated"]
    );
    const currentUser = computed(() => store.getters["auth/currentUser"]);
    const currentCompany = computed(() => store.getters["auth/currentCompany"]);
    const isPinned = ref(true);
    const toggleColorScheme = () => {
      store.dispatch("colorScheme/toggleColorScheme");
    };

    const applyColorScheme = () => {
      if (isDarkMode.value) {
        document.documentElement.classList.remove("light");
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
        document.documentElement.classList.add("light");
      }
    };

    const navItems = [
      { name: "Dashboard", href: "/dashboard", icon: "fas fa-home" },
      { name: "Artists", href: "/artists", icon: "fas fa-users" },
      { name: "Releases", href: "/releases", icon: "fas fa-music" },
      { name: "Contacts", href: "/contacts", icon: "fas fa-phone" },
      {
        name: "Organizations",
        href: "/organizations",
        icon: "far fa-building",
      },
      { name: "Calendar", href: "/releases-calendar", icon: "far fa-calendar" },
      { name: "Contracts", href: "/contracts", icon: "fas fa-file-contract" },
      {
        name: "User Management",
        href: "/user-management",
        icon: "fas fa-users-cog",
        adminOnly: true,
      },
    ];

    const isAdmin = computed(
      () => store.getters["auth/currentUser"]?.role === "admin"
    );

    const filteredNavItems = computed(() =>
      navItems.filter(
        (item) => !item.adminOnly || (item.adminOnly && isAdmin.value)
      )
    );

    const showSidebar = () => {
      if (isAuthenticated.value && !isPinned.value) {
        isSidebarOpen.value = true;
      }
    };
    const togglePin = () => {
      isPinned.value = !isPinned.value;
      if (!isPinned.value) {
        isSidebarOpen.value = false;
      }
    };

    const handleMouseLeave = () => {
      if (!isPinned.value) {
        hideSidebar();
      }
    };

    const hideSidebar = () => {
      if (!isPinned.value) {
        isSidebarOpen.value = false;
      }
    };
    const handleFocusOut = (event) => {
      if (!sidebar.value.contains(event.relatedTarget)) {
        hideSidebar();
      }
    };

    const handleClickOutside = (event) => {
      if (
        sidebar.value &&
        !sidebar.value.contains(event.target) &&
        isSidebarOpen.value
      ) {
        hideSidebar();
      }
    };

    const toggleSidebar = () => {
      if (isAuthenticated.value) {
        isSidebarOpen.value = !isSidebarOpen.value;
      } else {
        router.push("/");
      }
    };

    const handleLogout = async () => {
      await store.dispatch("auth/logout");
      router.push("/");
    };

    const getInitials = (name) => {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase()
        .slice(0, 2);
    };
    const getProfileImageUrl = (image) => {
      return `${process.env.VUE_APP_API_URL}/uploads/${image}`;
    };

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
      applyColorScheme();
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    watch(isDarkMode, () => {
      applyColorScheme();
    });

    return {
      isSidebarOpen,
      isAuthenticated,
      currentUser,
      currentCompany,
      navItems,
      showSidebar,
      hideSidebar,
      toggleSidebar,
      handleLogout,
      sidebar,
      handleFocusOut,
      isDarkMode,
      toggleColorScheme,
      filteredNavItems,
      getInitials,
      getProfileImageUrl,
      isPinned,
      togglePin,
      handleMouseLeave,
    };
  },
};
</script>
