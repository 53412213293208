<template>
  <div class="p-6 bg-primary-bg min-h-screen">
    <h1
      class="text-3xl font-semibold text-text-primary mb-6 text-gradient bg-gradient-to-r from-accent-primary to-accent-secondary"
    >
      Organizations
    </h1>
    <ListView
      :items="organizations"
      :columns="columns"
      :form-fields="formFields"
      :has-more="hasMore"
      entity-name="Organization"
      @add="showAddOrganizationModal = true"
      @update="openEditModal"
      @delete="confirmDelete"
      @show-details="showOrganizationDetails"
      @load-more="loadMoreOrganizations"
    >
      <template #name="{ item }">
        <div class="flex items-center">
          <img
            :src="getImageUrl(item.image_url)"
            :alt="item.name"
            class="w-12 h-12 rounded-full mr-3 object-cover border-2 border-accent-secondary transition-transform duration-300 hover:scale-110"
          />
          <span class="text-text-primary">{{ item.name }}</span>
        </div>
      </template>
    </ListView>

    <!-- Add Organization Modal -->
    <BaseModal
      :is-open="showAddOrganizationModal"
      @close="showAddOrganizationModal = false"
      size="lg"
    >
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-secondary to-accent-tertiary"
        >
          Add Organization
        </h2>
      </template>
      <template #body>
        <div class="mb-6 flex justify-center">
          <div class="w-32 h-32 relative">
            <img
              :src="
                newOrganizationImagePreview || 'https://via.placeholder.com/128'
              "
              alt="New Organization"
              class="w-full h-full rounded-full object-cover border-4 border-accent-secondary transition-transform duration-300 hover:scale-105"
            />
            <label
              for="newOrganizationImageUpload"
              class="absolute bottom-0 right-0 bg-accent-primary text-white p-2 rounded-full cursor-pointer hover:bg-accent-primary-dark transition-colors duration-200 w-10 h-10 flex items-center justify-center"
            >
              <i class="fas fa-camera"></i>
            </label>
            <input
              id="newOrganizationImageUpload"
              type="file"
              accept="image/*"
              class="hidden"
              @change="handleNewOrganizationImageUpload"
            />
          </div>
        </div>
        <DynamicForm
          :fields="addOrganizationFormFields"
          @submit="addOrganization"
          @cancel="showAddOrganizationModal = false"
        />
      </template>
    </BaseModal>

    <!-- Organization Details Modal -->
    <BaseModal
      :is-open="!!selectedOrganization"
      @close="selectedOrganization = null"
      size="lg"
    >
      <template #header>
        <h3
          class="text-2xl font-semibold text-center text-text-primary text-gradient bg-gradient-to-r from-accent-secondary to-accent-tertiary"
        >
          Organization Details
        </h3>
      </template>
      <template #body>
        <div
          v-if="selectedOrganization"
          class="flex flex-col items-center space-y-6"
        >
          <!-- Centered Organization Image -->
          <div class="flex justify-center">
            <img
              :src="getImageUrl(selectedOrganization.image_url)"
              :alt="selectedOrganization.name"
              class="h-28 w-28 rounded-full object-cover shadow-lg border-4 border-accent-secondary transition-transform duration-300 hover:scale-110"
            />
          </div>
          <h2 class="text-2xl font-semibold text-text-primary">
            {{ selectedOrganization.name }}
          </h2>

          <!-- Center-aligned two-column layout for details -->
          <div class="w-full max-w-3xl mx-auto">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4">
              <div
                v-for="(value, key) in organizationDetails"
                :key="key"
                class="flex flex-col items-center text-center"
              >
                <h4 class="text-sm font-medium text-text-secondary uppercase">
                  {{ formatLabel(key) }}
                </h4>
                <p class="mt-1 text-base text-text-primary">
                  <template v-if="key === 'social_media'">
                    <span v-if="value && Object.keys(value).length > 0">
                      <div
                        v-for="(url, platform) in value"
                        :key="platform"
                        class="flex items-center justify-center"
                      >
                        <span class="font-medium mr-2 text-text-secondary"
                          >{{ platform }}:</span
                        >
                        <a
                          :href="url"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="text-accent-secondary hover:text-accent-secondary-light transition-colors duration-200"
                          >{{ url }}</a
                        >
                      </div>
                    </span>
                    <span v-else class="text-text-secondary italic"
                      >No social media links provided</span
                    >
                  </template>
                  <template v-else-if="key === 'website'">
                    <a
                      :href="value"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="text-accent-secondary hover:text-accent-secondary-light transition-colors duration-200"
                      >{{ value }}</a
                    >
                  </template>
                  <template v-else>
                    {{ formatValue(key, value) }}
                  </template>
                </p>
              </div>
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-center space-x-4 mt-6">
            <button
              @click="openEditModal(selectedOrganization)"
              class="btn btn-primary"
            >
              <i class="fas fa-edit mr-2"></i>Edit
            </button>
            <button
              @click="confirmDelete(selectedOrganization.id)"
              class="btn btn-danger"
            >
              <i class="fas fa-trash-alt mr-2"></i>Delete
            </button>
          </div>
        </div>
      </template>
    </BaseModal>

    <!-- Edit Organization Modal -->
    <BaseModal :is-open="showEditModal" @close="closeEditModal" size="lg">
      <template #header>
        <h2
          class="text-2xl font-bold text-text-primary text-gradient bg-gradient-to-r from-accent-secondary to-accent-tertiary"
        >
          Edit Organization
        </h2>
      </template>
      <template #body>
        <div class="mb-6 flex flex-col items-center">
          <div class="relative">
            <img
              :src="getImagePreview(editingOrganization?.image_url)"
              alt="Organization Image"
              class="h-32 w-32 rounded-full object-cover border-4 border-accent-secondary transition-transform duration-300 hover:scale-110"
            />
            <label
              for="imageUpload"
              class="absolute bottom-0 right-0 bg-accent-primary text-white p-2 rounded-full cursor-pointer hover:bg-accent-primary-dark transition-colors duration-200 w-10 h-10 flex items-center justify-center"
            >
              <i class="fas fa-camera"></i>
            </label>
            <input
              id="imageUpload"
              type="file"
              accept="image/*"
              class="hidden"
              @change="handleImageUpload"
            />
          </div>
        </div>
        <DynamicForm
          v-if="editingOrganization"
          :fields="editFormFields"
          :initial-data="editingOrganization"
          @submit="updateOrganization"
          @cancel="closeEditModal"
        />
      </template>
    </BaseModal>

    <!-- Delete Confirmation Modal -->
    <BaseModal
      :is-open="showDeleteConfirmModal"
      @close="showDeleteConfirmModal = false"
      size="sm"
    >
      <template #header>
        <h3
          class="text-xl font-semibold text-text-primary text-gradient bg-gradient-to-r from-error to-error-light"
        >
          Confirm Deletion
        </h3>
      </template>
      <template #body>
        <p class="text-text-secondary mb-4">
          Are you sure you want to delete this organization? This action cannot
          be undone.
        </p>
        <div class="flex justify-end space-x-3">
          <button
            @click="showDeleteConfirmModal = false"
            class="btn btn-secondary"
          >
            Cancel
          </button>
          <button @click="deleteOrganization" class="btn btn-danger">
            Delete
          </button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.btn {
  @apply px-4 py-2 rounded-md font-medium transition-colors duration-normal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-bg;
}
.btn-primary {
  @apply bg-accent-secondary text-white hover:bg-accent-secondary-dark focus:ring-accent-secondary;
}

.btn-secondary {
  @apply bg-primary-lighter text-text-primary hover:bg-primary-card focus:ring-accent-tertiary;
}

.btn-danger {
  @apply bg-error text-white hover:bg-error-light focus:ring-error;
}
</style>

<script>
import { ref, computed, onMounted } from "vue";
import ListView from "@/components/ListView.vue";
import BaseModal from "@/components/BaseModal.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import api from "@/services/api";

export default {
  name: "OrganizationsView",
  components: {
    ListView,
    BaseModal,
    DynamicForm,
  },
  setup() {
    const organizations = ref([]);
    const page = ref(1);
    const hasMore = ref(true);
    const isLoading = ref(false);
    const selectedOrganization = ref(null);
    const showAddOrganizationModal = ref(false);
    const editingOrganization = ref(null);
    const showEditModal = ref(false);
    const showDeleteConfirmModal = ref(false);
    const newOrganizationImagePreview = ref(null);

    const columns = ref([
      { key: "name", label: "Name", visible: true, filterable: true },
      { key: "type", label: "Type", visible: true, filterable: true },
      { key: "address", label: "Address", visible: true, filterable: true },
      { key: "website", label: "Website", visible: true, filterable: true },
    ]);

    const formFields = ref([
      { key: "name", label: "Name", type: "text", required: true },
      { key: "type", label: "Type", type: "text", required: true },
      { key: "address", label: "Address", type: "text", required: true },
      { key: "city", label: "City", type: "text", required: true },
      { key: "state", label: "State", type: "text", required: true },
      { key: "country", label: "Country", type: "text", required: true },
      { key: "zipcode", label: "Zipcode", type: "text", required: true },
      { key: "website", label: "Website", type: "url", required: true },
      // { key: "social_media", label: "Social Media", type: "json" },
      // { key: "notes", label: "Notes", type: "textarea" },
    ]);
    const addOrganizationFormFields = computed(() => [
      { key: "name", label: "Name", type: "text", required: true },
      { key: "type", label: "Type", type: "text", required: true },
      { key: "address", label: "Address", type: "text", required: true },
      { key: "city", label: "City", type: "text", required: true },
      { key: "state", label: "State", type: "text", required: true },
      { key: "country", label: "Country", type: "text", required: true },
      { key: "zipcode", label: "Zipcode", type: "text", required: true },
      { key: "website", label: "Website", type: "url", required: true },
      // {
      //   key: "social_media",
      //   label: "Social Media",
      //   type: "textarea",
      //   placeholder: 'Enter as JSON: {"platform": "url"}',
      // },
      // { key: "notes", label: "Notes", type: "textarea" },
    ]);
    const editFormFields = computed(() => [
      {
        key: "name",
        label: "Name",
        type: "text",
        required: true,
        fullWidth: false,
      },
      { key: "website", label: "Website", type: "url", fullWidth: false },
      {
        key: "type",
        label: "Type",
        type: "select",
        options: [
          { value: "Record Label", label: "Record Label" },
          { value: "Publishing Company", label: "Publishing Company" },
          { value: "Booking Agency", label: "Booking Agency" },
          { value: "Management Company", label: "Management Company" },
          { value: "Other", label: "Other" },
        ],
        required: true,
        fullWidth: false,
        value: editingOrganization.value?.type,
      },
      {
        key: "address",
        label: "Address",
        type: "text",
        required: true,
        fullWidth: false,
      },
      {
        key: "city",
        label: "City",
        type: "text",
        required: true,
        fullWidth: false,
      },
      {
        key: "state",
        label: "State",
        type: "text",
        required: true,
        fullWidth: false,
      },
      {
        key: "country",
        label: "Country",
        type: "text",
        required: true,
        fullWidth: false,
      },
      {
        key: "zipcode",
        label: "Zipcode",
        type: "text",
        required: true,
        fullWidth: false,
      },
      {
        key: "social_media",
        label: "Social Media",
        type: "textarea",
        fullWidth: true,
        placeholder: 'Enter as JSON: {"platform": "url"}',
        parse: (value) => {
          if (typeof value === "string") {
            try {
              return JSON.parse(value);
            } catch (error) {
              console.error("Error parsing social_media JSON:", error);
              return {};
            }
          }
          return value;
        },
        format: (value) => {
          if (typeof value === "object") {
            return JSON.stringify(value, null, 2);
          }
          return value;
        },
      },
      // {
      //   key: "notes",
      //   label: "Notes",
      //   type: "textarea",
      //   fullWidth: true,
      // },
      { key: "image", type: "hidden" },
    ]);

    // const editOrganizationFormFields = computed(() => [
    //   {
    //     key: "name",
    //     label: "Name",
    //     type: "text",
    //     required: true,
    //     fullWidth: false,
    //   },
    //   {
    //     key: "type",
    //     label: "Type",
    //     type: "text",
    //     required: true,
    //     fullWidth: false,
    //   },
    //   {
    //     key: "address",
    //     label: "Address",
    //     type: "text",
    //     required: true,
    //     fullWidth: true,
    //   },
    //   {
    //     key: "city",
    //     label: "City",
    //     type: "text",
    //     required: true,
    //     fullWidth: false,
    //   },
    //   {
    //     key: "state",
    //     label: "State",
    //     type: "text",
    //     required: true,
    //     fullWidth: false,
    //   },
    //   {
    //     key: "country",
    //     label: "Country",
    //     type: "text",
    //     required: true,
    //     fullWidth: false,
    //   },
    //   {
    //     key: "zipcode",
    //     label: "Zipcode",
    //     type: "text",
    //     required: true,
    //     fullWidth: false,
    //   },
    //   {
    //     key: "website",
    //     label: "Website",
    //     type: "url",
    //     required: true,
    //     fullWidth: false,
    //   },
    //   {
    //     key: "social_media",
    //     label: "Social Media",
    //     type: "textarea",
    //     placeholder: 'Enter as JSON: {"platform": "url"}',
    //     fullWidth: true,
    //   },
    //   { key: "notes", label: "Notes", type: "textarea", fullWidth: true },
    //   { key: "image", type: "hidden" },
    // ]);

    const organizationDetails = computed(() => {
      if (!selectedOrganization.value) return {};
      const {
        name,
        type,
        address,
        city,
        state,
        country,
        zipcode,
        website,
        // social_media,
      } = selectedOrganization.value;
      return {
        name,
        type,
        address,
        city,
        state,
        country,
        zipcode,
        website,
        // social_media,
      };
    });

    const fetchOrganizations = async () => {
      if (isLoading.value || !hasMore.value) return;

      isLoading.value = true;
      try {
        const response = await api.getOrganizations({
          page: page.value,
          per_page: 20,
        });
        organizations.value = [
          ...organizations.value,
          ...response.data.organizations,
        ];
        hasMore.value = response.data.has_more;
        page.value++;
      } catch (error) {
        console.error("Error fetching organizations:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const loadMoreOrganizations = () => {
      if (hasMore.value && !isLoading.value) {
        fetchOrganizations();
      }
    };

    const addOrganization = async (formData) => {
      try {
        const form = new FormData();
        for (const [key, value] of Object.entries(formData)) {
          if (key === "social_media" && typeof value === "object") {
            form.append(key, JSON.stringify(value));
          } else if (value !== null && value !== undefined && value !== "") {
            form.append(key, value);
          }
        }

        const imageInput = document.getElementById(
          "newOrganizationImageUpload"
        );
        if (imageInput && imageInput.files[0]) {
          form.append("image", imageInput.files[0]);
        }

        const response = await api.createOrganization(form);
        organizations.value.unshift(response.data);
        showAddOrganizationModal.value = false;
        newOrganizationImagePreview.value = null;
      } catch (error) {
        console.error("Error creating organization:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          console.log(
            `Error creating organization: ${error.response.data.error}`
          );
        } else {
          console.log("Error creating organization. Please try again.");
        }
      }
    };

    const updateOrganization = async (formData) => {
      try {
        const form = new FormData();
        for (const [key, value] of Object.entries(formData)) {
          if (key === "social_media") {
            form.append(key, JSON.stringify(value));
          } else if (value !== null && value !== undefined && value !== "") {
            form.append(key, value);
          }
        }

        if (editingOrganization.value.image instanceof File) {
          form.append("image", editingOrganization.value.image);
        }

        const response = await api.updateOrganization(
          editingOrganization.value.id,
          form
        );
        const index = organizations.value.findIndex(
          (o) => o.id === response.data.id
        );
        if (index !== -1) {
          organizations.value[index] = response.data;
        }
        selectedOrganization.value = response.data;
        closeEditModal();
      } catch (error) {
        console.error("Error updating organization:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          console.log(
            `Error updating organization: ${error.response.data.error}`
          );
        } else {
          console.log("Error updating organization. Please try again.");
        }
      }
    };

    const confirmDelete = (id) => {
      selectedOrganization.value = organizations.value.find((o) => o.id === id);
      showDeleteConfirmModal.value = true;
    };

    const deleteOrganization = async () => {
      try {
        await api.deleteOrganization(selectedOrganization.value.id);
        organizations.value = organizations.value.filter(
          (o) => o.id !== selectedOrganization.value.id
        );
        selectedOrganization.value = null;
        showDeleteConfirmModal.value = false;
      } catch (error) {
        console.error("Error deleting organization:", error);
        // You might want to show an error message to the user here
      }
    };

    const showOrganizationDetails = (organization) => {
      selectedOrganization.value = organization;
    };

    const openEditModal = (organization) => {
      editingOrganization.value = { ...organization };
      showEditModal.value = true;
    };

    const closeEditModal = () => {
      showEditModal.value = false;
      editingOrganization.value = null;
    };

    const formatLabel = (key) => {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const formatValue = (key, value) => {
      if (key === "social_media") {
        if (typeof value === "string") {
          try {
            value = JSON.parse(value);
          } catch (error) {
            console.error("Error parsing social_media JSON:", error);
            return value;
          }
        }
        if (typeof value === "object" && value !== null) {
          return Object.entries(value)
            .map(([platform, url]) => `${platform}: ${url}`)
            .join(", ");
        }
      }
      return value || "N/A";
    };

    const getImageUrl = (imageUrl) => {
      if (!imageUrl) return "https://via.placeholder.com/150";
      if (imageUrl.startsWith("http")) return imageUrl;
      return `${process.env.VUE_APP_API_URL}${imageUrl}`;
    };
    const handleNewOrganizationImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        newOrganizationImagePreview.value = URL.createObjectURL(file);
      }
    };

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        editingOrganization.value.image = file;
        editingOrganization.value.image_url = URL.createObjectURL(file);
      }
    };

    const getImagePreview = (imageUrl) => {
      if (imageUrl instanceof File) {
        return URL.createObjectURL(imageUrl);
      } else if (typeof imageUrl === "string" && imageUrl.startsWith("blob:")) {
        return imageUrl;
      }
      return getImageUrl(imageUrl);
    };

    onMounted(() => {
      fetchOrganizations();
    });

    return {
      organizations,
      columns,
      formFields,
      hasMore,
      selectedOrganization,
      showAddOrganizationModal,
      editingOrganization,
      showEditModal,
      showDeleteConfirmModal,
      organizationDetails,
      addOrganization,
      updateOrganization,
      confirmDelete,
      deleteOrganization,
      showOrganizationDetails,
      openEditModal,
      closeEditModal,
      formatLabel,
      formatValue,
      loadMoreOrganizations,
      getImageUrl,
      newOrganizationImagePreview,
      handleNewOrganizationImageUpload,
      handleImageUpload,
      getImagePreview,
      addOrganizationFormFields,
      editFormFields,
    };
  },
};
</script>
