<template>
  <div class="p-6 bg-primary-bg min-h-screen">
    <h1
      class="text-3xl font-semibold text-text-primary mb-6 text-gradient bg-gradient-to-r from-accent-quaternary to-accent-warm"
    >
      Releases Calendar
    </h1>
    <div
      class="bg-primary-card rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
    >
      <EventCalendar title="Releases Calendar" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import EventCalendar from "@/components/EventCalendar.vue";

export default defineComponent({
  name: "ReleasesCalendar",
  components: {
    EventCalendar,
  },
});
</script>

<style scoped>
.text-gradient {
  @apply bg-clip-text text-transparent;
}

.bg-primary-bg {
  background-color: var(--color-primary-bg);
}

.bg-primary-card {
  background-color: var(--color-primary-card);
}

.text-text-primary {
  color: var(--color-text-primary);
}

/* Custom styles for the calendar container */
.rounded-lg {
  @apply overflow-hidden;
}

/* Responsive design */
@screen sm {
  .p-6 {
    @apply p-4;
  }
}

@screen md {
  .p-6 {
    @apply p-8;
  }
}

/* Dark mode styles */
.dark .bg-primary-bg {
  background-color: var(--color-primary-darkest);
}

.dark .bg-primary-card {
  background-color: var(--color-primary-darker);
}
</style>
